import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import InputSelectCommon from "../../../components/CommonSelect/InputSelect";
import { GENDER } from "../../../Utils/Constants/select_options";
import {
  addModalInitialValuesHandlerStep1,
  ProfileSubmitHandlerStep1,
} from "../ProfileMethods/ProfileSubmitHandlers";
import { initialValuesStep1 } from "../ProfileMethods/profileInitialValues";
import { validationSchemaStep1 } from "../ProfileMethods/ProfileValidation";
import {
  getAustState,
  getSubrub,
  getSupportData,
} from "../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SelectComponent from "../../../components/ReactSelect/ReactSelect";
import DatePickerReact from "../../../components/DatePicker/DatePickerReact";
import SuccessCareSeekerModal from "../../../components/Modal/SucessCareSeekerModal";
import ConfirmModal from "./components/ConfirmModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Step1 = ({ setStep, Userdata, ListRefetch, UserLoading_data }: any) => {
  const [isCamera, setIsCamera] = useState(false);
  const [iamge_chnage, setImgageChange] = useState(false);
  const [profileImgPreview, setProfileImgPreview] = useState<any>(null);
  const navigate = useNavigate();
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep1);
  const [error_back, setErrorBack] = useState("");
  const [athorperson, setOther] = useState();
  const [serch_sate, setSearch] = useState("");

  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
    // navigate("/create-profile-caregiver");
  };

  useEffect(() => {
    if (iamge_chnage === false) {
      if (Userdata?.id) {
        addModalInitialValuesHandlerStep1(
          Userdata,
          setAddModalInitialValues,
          athorperson
        );
        setSearch(
          Userdata?.careseeker_details?.suburb?.locality
            ? Userdata?.careseeker_details?.suburb?.locality
            : ""
        );
      } else {
        setAddModalInitialValues(initialValuesStep1);
      }
    }
    if (Userdata?.careseeker_details?.supportFor?.id) {
      setCentered(true);
    }
  }, [
    Userdata,
    Userdata?.id,
    iamge_chnage,
    athorperson,
    Userdata?.careseeker_details?.suburb?.locality,
  ]);

  const handleImageChange = (e: any, setFieldValue: any) => {
    setImgageChange(true);
    const file = e.target.files[0];
    if (file) {
      setFieldValue("profile_img", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImgPreview(reader?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileClick = (option: "camera" | "gallery") => {
    const fileInput = document.getElementById(
      "profileImage"
    ) as HTMLInputElement;

    if (option === "camera") {
      setIsCamera(true);
      fileInput.setAttribute("capture", "user"); // Open camera for selfie
    } else {
      setIsCamera(false);
      fileInput.removeAttribute("capture"); // Remove camera access for gallery
    }

    fileInput.click(); // Programmatically click the file input
  };
  const {
    data: Supprtdata,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery([`Supprtdata`], getSupportData, {
    keepPreviousData: true,
  });

  const [inputValue, setInputValue] = useState(
    Userdata?.careseeker_details?.state?.id
      ? Userdata?.careseeker_details?.state?.id
      : []
  );

  const { data: state_aust } = useQuery([`state_aust`], getAustState, {
    keepPreviousData: true,
    staleTime: 1000,
  });

  const { data: surub_data, isLoading: subloading } = useQuery(
    [
      `surub_data`,
      inputValue || addModalInitialValues?.state || inputValue,
      serch_sate,
    ],
    () =>
      serch_sate || addModalInitialValues?.state || inputValue
        ? getSubrub(addModalInitialValues?.state || inputValue, serch_sate)
        : "",
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  console.log(addModalInitialValues?.state, "inputValue");
  return (
    <>
      <style>{`
        .parent {
          width: auto;
          margin: auto;
          padding: 2rem;
          background: #ffffff;
          border-radius: 25px;
          box-shadow: 7px 20px 20px rgb(210, 227, 244);
        }

        .file-upload {
          text-align: center;
          border: 3px dashed rgb(210, 227, 244);
          padding: 1.5rem;
          position: relative;
          cursor: pointer;
        }

        .file-upload p {
          font-size: 0.87rem;
          margin-top: 10px;
          color: #bbcada;
        }

        .file-upload input {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
          /* Default to showing web text and hide mobile text */
.web-text {
  display: block;
}
.mobile-text {
  display: none;
}

/* On mobile devices, show the mobile text and hide the web text */
@media only screen and (max-width: 768px) {
  .web-text {
    display: none;
  }
  .mobile-text {
    display: block;
  }
}

      `}</style>
      {UserLoading_data ? (
        <Skeleton count={5} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={addModalInitialValues}
          validationSchema={validationSchemaStep1}
          onSubmit={(values, actions) => {
            ProfileSubmitHandlerStep1(
              values,
              actions,
              setStep,
              navigate,
              ListRefetch,
              Userdata?.careseeker_details?.supportFor?.id,
              setErrorBack
            );
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            isSubmitting,
          }) => (
            <>
              <Form
                className="seeker-registration-form2 giver-register4"
                onSubmit={handleSubmit}
              >
                <div className="text-center mb-3 logo-div01">
                  <h3 className="block-subTitle text-center uss-text-info">
                    {/* <span className="uss-text-primary">Create</span> Account */}
                  </h3>
                </div>
                <FormGroup>
                  <Label>Support for </Label>
                  {athorperson === 1 ||
                  Userdata?.careseeker_details?.supportFor?.id === 1 ? (
                    <select
                      value={values.suppot_for_you || "1"} // Ensure the default value is set
                      className={`form-control col-12 ${
                        errors.suppot_for_you && touched.suppot_for_you
                          ? "is-invalid"
                          : "is-valid"
                      }`}
                      name="suppot_for_you"
                      onChange={(e) => {
                        const value = e.target.value; // Capture the selected value
                        setFieldValue("suppot_for_you", value);
                      }}
                      disabled={!!Userdata?.careseeker_details?.supportFor?.id}
                    >
                      <option value={1}>Myself</option>
                    </select>
                  ) : (
                    <>
                      {" "}
                      <InputSelectCommon
                        size={1}
                        data={
                          athorperson === 1
                            ? Supprtdata?.filter((item: any) => item.id === 1)
                            : athorperson === 2
                            ? Supprtdata?.filter((item: any) => item.id !== 1)
                            : Supprtdata?.filter((item: any) => item.id !== 1)
                        }
                        name="suppot_for_you"
                        selectClass={`form-control col-12 ${`${
                          errors.suppot_for_you ? "is-invalid" : "is-valid"
                        }`}`}
                        // disabled={
                        //   Userdata?.careseeker_details?.supportFor?.id
                        //     ? true
                        //     : false
                        // }
                      />
                      <ErrorMessage
                        name="suppot_for_you"
                        component="div"
                        className="text-danger"
                      />
                    </>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>First Name</Label>
                  <Field
                    type="text"
                    name="first_name"
                    className={`form-control ${`${
                      errors.first_name ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.first_name || ""}
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Field
                    type="text"
                    name="last_name"
                    className={`form-control ${`${
                      errors.last_name ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.last_name || ""}
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Mobile</Label>
                  {/* <Field
                    type="text"
                    name="mobile"
                    className={`form-control ${`${
                      errors.mobile ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.mobile || ""}
                    disabled={
                      athorperson === 1 ||
                      Userdata?.careseeker_details?.supportFor?.id === "1" ||
                      Userdata?.careseeker_details?.supportFor?.id === 1
                        ? true
                        : athorperson === 2
                        ? false
                        : ""
                    }
                  /> */}
                  <PhoneInput
                    country={"au"}
                    value={values?.mobile}
                    onChange={(phone) => {
                      setFieldValue("mobile", "+" + phone);
                    }}
                    onlyCountries={["au"]}
                    inputClass={` form-control ${
                      errors.mobile ? "is-invalid" : "is-valid"
                    }`}
                    disableDropdown
                    // inputStyle={{zIndex:"100px"}}
                    disabled={
                      athorperson === 1 ||
                      Userdata?.careseeker_details?.supportFor?.id === "1" ||
                      Userdata?.careseeker_details?.supportFor?.id === 1
                        ? true
                        : athorperson === 2
                        ? false
                        : Userdata?.careseeker_details?.supportFor?.id
                        ? true
                        : false
                    }
                    countryCodeEditable={false}
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                {values?.suppot_for_you === "1" ? (
                  ""
                ) : (
                  <>
                    {error_back && (
                      <>
                        <Alert color="danger" className="mt-30" id="g">
                          You are supporting or creating an account for someone
                          else. Please ensure the email is not the same as your
                          account email, and make sure to change it
                        </Alert>
                      </>
                    )}
                  </>
                )}
                <FormGroup>
                  <Label>Email</Label>
                  <Field
                    type="text"
                    name="email"
                    className={`form-control ${`${
                      errors.email ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.email || ""}
                    disabled={
                      athorperson === 1 ||
                      Userdata?.careseeker_details?.supportFor?.id === "1" ||
                      Userdata?.careseeker_details?.supportFor?.id === 1
                        ? true
                        : athorperson === 2
                        ? false
                        : Userdata?.careseeker_details?.supportFor?.id !== 1 ||
                          Userdata?.careseeker_details?.supportFor?.id !== "1"
                        ? false
                        : ""
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Date of Birth</Label>
                  <DatePickerReact
                    selected={values.dob ? new Date(values.dob) : null}
                    onChange={(date: any) => setFieldValue("dob", date)}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    placeholderText="DD/MM/YYYY"
                    className={`form-control ${
                      errors.dob ? "is-invalid" : "is-valid"
                    }`}
                    showIcon
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 18)
                      )
                    }
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 100)
                      )
                    }
                  />
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup className="mt-3">
                  <Label>Gender</Label>
                  <Row>
                    {GENDER?.map((item: any, index: number) => (
                      <div className="col-1" key={index}>
                        <Field
                          type="radio"
                          name="gender"
                          value={item.id || ""}
                          checked={values?.gender === item?.id?.toString()}
                        />
                        &nbsp;
                        <Label className="form-check-label">{item.name}</Label>
                      </div>
                    ))}
                  </Row>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <Card>
                  <CardBody>
                    <FormGroup>
                      <Label>Address</Label>
                      <hr />
                      <FormGroup>
                        <Label>Street Number</Label>
                        <input
                          type="text"
                          name="street_number"
                          className={`form-control ${
                            errors.street_number ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.street_number || ""}
                          onChange={handleChange}
                        />
                        {errors.street_number && (
                          <div className="text-danger">
                            {errors.street_number}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Address Line - 1</Label>
                        <input
                          type="text"
                          name="address_line_one"
                          className={`form-control ${
                            errors.address_line_one ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.address_line_one || ""}
                          onChange={handleChange}
                        />
                        {errors.address_line_one && (
                          <div className="text-danger">
                            {errors.address_line_one}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Address Line - 2</Label>
                        <input
                          type="text"
                          name="address_line_two"
                          className={`form-control ${
                            errors.address_line_two ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.address_line_two || ""}
                          onChange={handleChange}
                        />
                        {errors.address_line_two && (
                          <div className="text-danger">
                            {errors.address_line_two}
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label>State</Label>
                        <SelectComponent
                          loading={subloading}
                          options={state_aust}
                          value={state_aust?.find(
                            (option: any) => option.value === values.state
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue("state", selectedOption?.value);
                            setFieldValue("suburb", null);
                            setInputValue(selectedOption?.value);
                            setSearch("");
                          }}
                          error={errors?.state ? errors?.state : ""}
                          isClearable
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Suburb</Label>
                        <SelectComponent
                          loading={subloading}
                          options={surub_data ? surub_data : []}
                          value={
                            values?.suburb && surub_data
                              ? surub_data?.find(
                                  (option: any) =>
                                    option.value === values?.suburb
                                )
                              : ""
                          }
                          onChange={(selectedOption: any) => {
                            setFieldValue(
                              "suburb",
                              parseInt(selectedOption?.value)
                            );
                          }}
                          onInputChange={(e: any) => {
                            setSearch(e);
                          }}
                          error={errors?.suburb}
                          isClearable
                        />
                        <ErrorMessage
                          name="suburb"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label>Profile Picture</Label>
                      <div className="file-upload">
                        {profileImgPreview || values.profile_img_ ? (
                          <>
                            <img
                              src={profileImgPreview || values.profile_img_}
                              alt="Profile Preview"
                              width={150}
                              height={150}
                            />

                            <p className="mobile-text">
                              Click here to change image (
                              <span onClick={() => handleFileClick("camera")}>
                                <u>take selfie</u>
                              </span>{" "}
                              or{" "}
                              <span onClick={() => handleFileClick("gallery")}>
                                <u> choose from gallery</u>
                              </span>
                              )
                            </p>
                            <p
                              className="web-text"
                              onClick={() => handleFileClick("gallery")}
                            >
                              Click here to change image
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="mobile-text">
                              Click here to upload image (
                              <span onClick={() => handleFileClick("camera")}>
                                <u> take selfie</u>
                              </span>{" "}
                              or{" "}
                              <span onClick={() => handleFileClick("gallery")}>
                                <u> choose from gallery</u>
                              </span>
                              )
                            </p>
                            <p
                              className="web-text"
                              onClick={() => handleFileClick("gallery")}
                            >
                              Click here to upload image
                            </p>
                          </>
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e: any) => {
                            handleImageChange(e, setFieldValue);
                          }}
                          id="profileImage"
                          style={{ display: "none" }} // Hide the input
                        />
                      </div>
                      {errors.profile_img && (
                        <div className="text-danger">{errors.profile_img}</div>
                      )}
                    </FormGroup>
                  </CardBody>
                </Card>{" "}
                {Object.keys(errors)?.length === 1 ? (
                  <>
                    {" "}
                    <div>
                      <Alert color="danger" className="mt-30" id="g">
                        Please make sure all fields are filled correctly.
                      </Alert>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="text-center mt-5 d-float-center justify-content-center gap-15 mt-4">
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}

      {!centred && (
        <>
          <SuccessCareSeekerModal
            toggle={centeredToggle}
            isOpen={!centred}
            onClosed={onCloseModal}
            titl="Sucess"
            modalBodyClassName={""}
            children={
              <ConfirmModal setOther={setOther} onCloseModal={onCloseModal} />
            }
          />
        </>
      )}
    </>
  );
};

export default Step1;
