import * as Yup from "yup";
import { RATES } from "../../../Utils/Constants/select_options";
import moment from "moment";
import { australianPhoneNumberRegex } from "../../../Utils/helpers/helpers";

export const validationSchema = Yup.object().shape({
  profile_type: Yup.string().required("Service type is required"), // Adjust the allowed profile types as needed
  name: Yup.string().required("Full Name is required"),
  dob: Yup.date()
    .nullable()
    .required("Date of birth is required")
    .test("dob", "You must be 18 years or older", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    }),
  gender: Yup.number()
    .required("Gender is required")
    .oneOf([1, 2, 3], "Invalid gender option"), // Assuming 1 = Male, 2 = Female, 3 = Other
  residency_status: Yup.number()
    .required("Residency status is required")
    .oneOf([1, 2, 3], "Invalid residency status"), // Adjust the allowed statuses as needed
  address: Yup.string()
    .required("Address is required")
    .min(5, "Address must be at least 5 characters long")
    .max(100, "Address cannot be more than 100 characters long"),
  // profile_img: Yup.string().required("Field is required"),
  profile_img: Yup.mixed().required("Image is required"),

  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email must be 100 characters or less")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(
      australianPhoneNumberRegex,
      "Mobile number must be a valid Australian mobile number, either in the format +614XXXXXXXX or 04XXXXXXXX"
    )
    .required("Phone number is required"),
});
export const validationSchemaStep_2 = Yup.object().shape({
  // step: Yup.number().required(),
  suburb: Yup.string().required("Suburb is required"),
  service_radius: Yup.number()
    .required("Service radius is required")
    .positive("Service radius is required"),

  availability_details: Yup.array()
    .of(
      Yup.object({
        day: Yup.string().required("Day is required"),
        toggleSwitch: Yup.boolean().required("This switch must be toggled"),
        start_time: Yup.string().when("toggleSwitch", {
          is: true,
          then: (schema) => schema.required("Start time is required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        end_time: Yup.string()
          .when("toggleSwitch", {
            is: true,
            then: (schema) => schema.required("End time is required"),
            otherwise: (schema) => schema.notRequired(),
          })
          .test(
            "is-valid-time",
            "End Time must be after Start Time",
            function (value: any) {
              const { start_time } = this.parent;
              return !value || !start_time || value > start_time;
            }
          ),
      })
    )
    .required("At least one availability details must be provided")
    .test(
      "all-children-on",
      "At least one availability details must be provided",
      (availabilityDetails, context) => {
        const allChildrenAreOn = availabilityDetails.every(
          (detail) => detail.toggleSwitch === true
        );
        return context.parent.all_day_availability ? allChildrenAreOn : true; // Only check when master is ON
      }
    ),
  all_day_availability: Yup.boolean().test(
    "master-switch",
    "At least one availability details must be provided",
    (value, context) => {
      const availabilityDetails = context.parent.availability_details;
      const allChildrenAreOn = availabilityDetails.every(
        (detail: { toggleSwitch: boolean }) => detail.toggleSwitch === true
      );
      return allChildrenAreOn ? value === true : value === false;
    }
  ),
  start_time: Yup.string().when("all_day_availability", {
    is: true,
    then: (schema) => schema.required("Start time is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  end_time: Yup.string()
    .when("all_day_availability", {
      is: true,
      then: (schema) => schema.required("End time is required"),
      otherwise: (schema) => schema.notRequired(),
    })
    .test(
      "is-valid-time",
      "End Time must be after Start Time",
      function (value: any) {
        const { start_time } = this.parent;
        return !value || !start_time || value > start_time;
      }
    ),
});

const isFutureDate = (dateString: any) => {
  const dateString_ = moment(dateString).format("DD/MM/YYYY");
  const [day, month, year] = dateString_?.split("/").map(Number);
  const date = new Date(year, month - 1, day); // Month is 0-indexed in JS
  return date > new Date();
};

export const validationSchema_4 = Yup.object({
  // step: Yup.number().required("Step is required"),
  about_yourself: Yup.string()
    .test(
      "word-count",
      "About yourself must be 200 words or less",
      (value) => !value || value.split(/\s+/).filter(Boolean).length <= 200
    )
    .required("About yourself is required"),
  okay_with_pets: Yup.string().required("Okay with Pets is required"),
  work_experiences: Yup.array().of(
    Yup.object({
      company_name: Yup.string().required("Company name is required"),
      designation: Yup.string().required("Designation is required"),
      start_date: Yup.date()
        .required("Start date is required")
        .max(new Date(), "Start date cannot be in the future"), // Ensures start_date is not in the future
      end_date: Yup.date()
        .nullable()
        .when("is_current_job", {
          is: false,
          then: (schema) =>
            schema
              .required("End date is required")
              .min(
                Yup.ref("start_date"),
                "End date cannot be before the Start date"
              ) // Ensures end_date is after or on start_date
              .max(new Date(), "End date cannot be in the future"), // Ensures end_date is not in the future
          otherwise: (schema) => schema.notRequired(),
        }),
      description: Yup.string()

        .test(
          "word-count",
          "Description must be 200 words or less",
          (value) => !value || value.split(/\s+/).filter(Boolean).length <= 200
        )
        .required("Description is required"),
    })
  ),

  qualifications: Yup.array().of(
    Yup.object({
      course_name: Yup.string().required("Course name is required"),
      institution_name: Yup.string().required("Institution name is required"),
      start_date: Yup.date()
        .required("Start date is required")
        .max(new Date(), "Start date cannot be in the future"), // Ensures start_date is not in the future
      end_date: Yup.date()
        .nullable()
        .when("is_current_study", {
          is: false,
          then: (schema) =>
            schema
              .required("End date is required")
              .min(
                Yup.ref("start_date"),
                "End date cannot be before the Start Date"
              ) // Ensures end_date is after or on start_date
              .max(new Date(), "End date cannot be in the future"), // Ensures end_date is not in the future
          otherwise: (schema) => schema.notRequired(),
        }),
      is_current_study: Yup.boolean(),
      description: Yup.string()

        .test(
          "word-count",
          "Description must be 200 words or less",
          (value) => !value || value.split(/\s+/).filter(Boolean).length <= 200
        )
        .required("Description is required"),
    })
  ),
  languages_spokens: Yup.array()
    .min(1, "At least one language must be selected")
    .required("Language is required"),

  interests: Yup.array()
    .min(1, "At least one interest must be selected")
    .required("Interest is required"),
  care_tasks: Yup.array()
    .min(1, "At least one care task must be selected")
    .required("Care tasks is required"),
  willing_to_work: Yup.array()
    .min(1, "At least one willing to work option must be selected")
    .required("Willing to work  is required"),
  experience_with_aged_cares: Yup.array()
    .min(1, "At least one experience with aged care must be selected")
    .required("Experience with aged care is required"),
  experience_with_children: Yup.array()
    .min(1, "At least one experience with children must be selected")
    .required("Experience with children  is required"),
});

export const validationSchema_5 = Yup.object({
  iam_willing_to_drive: Yup.boolean().required(
    "Willing driving assistance field is required"
  ),
  valid_license: Yup.boolean().when("iam_willing_to_drive", {
    is: true,
    then: (schema: any) => schema.required("Valid license field  is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),
  comprehensive_car_insurance: Yup.boolean().when("iam_willing_to_drive", {
    is: true,
    then: (schema: any) =>
      schema.required("Comprehensive car insurance field is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),
  third_party_insurance: Yup.boolean().when("iam_willing_to_drive", {
    is: true,
    then: (schema: any) =>
      schema.required("Third party insurance field is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),

  cover_Kms_per_day: Yup.string().when("iam_willing_to_drive", {
    is: true,
    then: (schema: any) =>
      schema
        .matches(
          /^\d+(\.\d{1,2})?$/,
          "Must be a valid number with up to 2 decimal places"
        )
        .required("Cover Kms per day field is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),

  drive_overnight: Yup.boolean().when("iam_willing_to_drive", {
    is: true,
    then: (schema: any) => schema.required("Drive overnight field is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),

  charged_at_AUD: Yup.string().when("iam_willing_to_drive", {
    is: true,
    then: (schema: any) =>
      schema
        .matches(
          /^\d+(\.\d{1,2})?$/,
          "Must be a valid number with up to 2 decimal places"
        )
        .required("Charged at each KM field is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),
  driving_license_expire: Yup.string().when("valid_license", {
    is: true,
    then: (schema: any) =>
      schema
        .required("Driving license expiry date is required")
        .test(
          "is-future-date",
          "Driving license expiry date cannot be in the future",
          (value: any) => (value ? isFutureDate(value) : true)
        ),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),
  // driving_license_img: Yup.string().when("valid_license", {
  //   is: true,
  //   then: (schema: any) =>
  //     schema.required("Driving license Document is required"),
  //   // Ensures end_date is not in the future
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  driving_license_img: Yup.mixed().when("valid_license", {
    is: true,
    then: (schema) =>
      schema.test(
        "file-required",
        "Driving license Document is required",
        function (value: any) {
          // Allow the existing image from the backend
          if (this.parent.driving_license_img_) return true;

          // Validate new file upload
          return value instanceof File || value?.length > 0;
        }
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  driving_license_id: Yup.string().when("valid_license", {
    is: true,
    then: (schema: any) => schema.required("Driving license ID is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),
  vehicle_insurance_expire: Yup.string().when("comprehensive_car_insurance", {
    is: true,
    then: (schema: any) =>
      schema
        .required("Vehicle insurance expiry date is required")
        .test(
          "is-future-date",
          "Vehicle insurance expiry date cannot be in the future",
          (value: any) => (value ? isFutureDate(value) : true)
        ),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),
  // vehicle_insurance_img: Yup.string().when("comprehensive_car_insurance", {
  //   is: true,
  //   then: (schema: any) =>
  //     schema.required("Vehicle insurance Document field is required"),
  //   // Ensures end_date is not in the future
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  vehicle_insurance_img: Yup.mixed().when("comprehensive_car_insurance", {
    is: true,
    then: (schema) =>
      schema.test(
        "file-required",
        "Vehicle insurance Document field is required",
        function (value: any) {
          // Allow the existing image from the backend
          if (this.parent.vehicle_insurance_img_) return true;

          // Validate new file upload
          return value instanceof File || value?.length > 0;
        }
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  vehicle_insurance_id: Yup.string().when("comprehensive_car_insurance", {
    is: true,
    then: (schema: any) =>
      schema.required("Vehicle insurance ID field is required"),
    // Ensures end_date is not in the future
    otherwise: (schema) => schema.notRequired(),
  }),

  abn: Yup.string()
    .matches(/^\d{11}$/, "ABN must be exactly 11 digits")
    .required("ABN is required"),
  bank_name: Yup.string().required("Bank name is required"),
  company_name: Yup.string().required("Company name is required"),
  bank_account_number: Yup.string()
    .required("Bank account number is required")
    .matches(/^\d{6}$/, "Bank account number must be exactly 6 digits"),
  bank_branch_name: Yup.string()
    .matches(/^\d{3}-\d{3}$/, "BSB must be in the format XXX-XXX")
    .required("BSB is required"),
  rates: Yup.array().of(
    Yup.object({
      id: Yup.number().required(),
      min: Yup.string()
        .matches(
          /^\d+(\.\d{1,2})?$/,
          "Must be a valid number with up to 2 decimal places"
        )
        .required("Rate is required"),
    })
  ),
});

export const initialValuesStep_2 = {
  step: 2,
  suburb: "",
  service_radius: 0,
  all_day_availability: true,
  start_time: null,
  end_time: null,
  availability_details: [
    { id: 1, day: "1", start_time: null, end_time: null, toggleSwitch: true },
    { id: 2, day: "2", start_time: null, end_time: null, toggleSwitch: true },
    { id: 3, day: "3", start_time: null, end_time: null, toggleSwitch: true },
    { id: 4, day: "4", start_time: null, end_time: null, toggleSwitch: true },
    { id: 5, day: "5", start_time: null, end_time: null, toggleSwitch: true },
    { id: 6, day: "6", start_time: null, end_time: null, toggleSwitch: true },
    { id: 7, day: "7", start_time: null, end_time: null, toggleSwitch: true },
  ],
  address: "",
  state: "",
};

export const initialValues_3 = {
  document_id: "",
  document_id_expire: null,
  document_id_img: [], // Array to store multiple document images
  police_verification_id: "",
  police_verification_id_expire: null,
  police_verification_id_img: [], // Array to store multiple police verification images
  wwcc_id: "",
  wwcc_id_expire: null,
  wwcc_id_img: [], // Array to store multiple WWCC images
  first_aid_certificate_id: "",
  first_aid_certificate_expire: null,
  first_aid_certificate_img: [], // A
};
export const initialValues_4 = {
  step: 4,
  about_yourself: "",
  okay_with_pets: "",
  work_experiences: [
    {
      company_name: "",
      designation: "",
      start_date: null,
      end_date: null,
      is_current_job: false,
      description: "",
    },
  ],
  qualifications: [
    {
      course_name: "",
      institution_name: "",
      start_date: null,
      end_date: null,
      is_current_study: false,
      description: "",
    },
  ],
  languages_spokens: [],
  interests: [],
  care_tasks: [],
  willing_to_work: [],
  experience_with_aged_cares: [],
  experience_with_children: [],
};
export const initialValues_5 = {
  step: 5,
  extra_charge_after_hours: false,
  rates: RATES.map((rate: any) => ({
    id: rate.value,
    value: "",
    label: rate?.label,
    field: rate?.field,
    // max: "",
    min: "",
  })),
  abn: "",
  bank_name: "",
  bank_branch_name: "",
  bank_account_number: "",
  company_name: "",
  valid_license: false,
  comprehensive_car_insurance: false,
  third_party_insurance: false,
  cover_Kms_per_day: "",
  drive_overnight: false,
  charged_at_AUD: "",
  iam_willing_to_drive: false,
  driving_license_expire: "",
  driving_license_img: "",
  driving_license_img_: "",
  driving_license_id: "",
  vehicle_insurance_img: "",
  vehicle_insurance_img_: "",
  vehicle_insurance_id: "",
  vehicle_insurance_expire: "",
};
