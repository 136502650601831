/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import constants from "../../../../Utils/Constants/Constants";
import SingleNewsBanner from "./singleNewsbanner";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getNewsLimitList, getNewsSingle } from "../../../../Api/WebsiteApi";
import {
  capitalizeFirstLetterOfEachWord,
  dateConvertion,
} from "../../../../Utils/helpers/helpers";
import Loader from "../../../../components/Loader/Loader";
import Subscribe from "../../Home/Containers/Subscribe";

export default function SingleNews() {
  const { id }: any = useParams();

  const {
    data: ListItems,
    error: ListItemsError,
    isLoading: ListItemsLoading,
    refetch: ListItemsListRefetch,
  } = useQuery(["getNewsSingle", id], () => (id ? getNewsSingle(id) : ""), {
    keepPreviousData: true,
  });
  return (
    <>
      {ListItemsLoading ? (
        <Loader />
      ) : (
        <div className="news-event-single">
          <section className="section-box">
            <div>
              <img
                src={`${constants.IMAGE_PATH.IMAG_PATH}page/blog/img-single.png`}
              />
            </div>
          </section>
          <section className="section-box">
            <div className="archive-header pt-50 text-center">
              <div className="container">
                <div className="box-white">
                  <div className="max-width-single">
                    <a className="btn btn-tag" href="#">
                      {ListItems?.isNews === 1 ? "News" : "Event"}
                    </a>
                    <h2 className="mb-30 mt-20 text-center">
                      {capitalizeFirstLetterOfEachWord(ListItems?.title)}
                    </h2>
                    <div className="post-meta text-muted d-flex align-items-center mx-auto justify-content-center">
                      <div className="author d-flex align-items-center mr-30">
                        <img
                          alt="jobBox"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/user3.png`}
                        />
                        <span>USS</span>
                      </div>
                      <div className="date">
                        <span className="font-xs color-text-paragraph-2 mr-20 d-inline-block">
                          <img
                            className="img-middle mr-5"
                            src={`${constants.IMAGE_PATH.IMAG_PATH}page/blog/calendar.svg`}
                          />
                          {dateConvertion(ListItems?.publishDate)}
                        </span>
                        <span className="font-xs color-text-paragraph-2 d-inline-block">
                          <img
                            className="img-middle mr-5"
                            src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/time.svg`}
                          />
                          {ListItems?.readingTime} mins to read
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="post-loop-grid">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 mx-auto">
                  <div className="single-body">
                    <figure>
                      <img
                        src={
                          ListItems?.imagePath
                            ? ListItems?.imagePath
                            : `${constants.IMAGE_PATH.IMAG_PATH}page/blog/img-content.png`
                        }
                      />
                    </figure>
                    <div className="max-width-single">
                      <div className="content-single">
                        <p />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ListItems?.content,
                          }}
                        ></div>
                      </div>
                      <div className="single-apply-jobs mt-20">
                        <div className="row">
                          <div className="col-lg-7">
                            <a
                              className="btn btn-border-3 mr-10 hover-up"
                              href="#"
                            >
                              # Nature
                            </a>
                            <a
                              className="btn btn-border-3 mr-10 hover-up"
                              href="#"
                            >
                              # Beauty
                            </a>
                            <a className="btn btn-border-3 hover-up" href="#">
                              # Travel tips
                            </a>
                          </div>
                          <div className="col-md-5 text-lg-end social-share">
                            <h6 className="color-text-paragraph-2 d-inline-block d-baseline mr-20 mt-10">
                              Share
                            </h6>
                            <a
                              className="mr-20 d-inline-block d-middle hover-up"
                              href="#"
                            >
                              <img
                                alt="jobBox"
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/blog/fb.svg`}
                              />
                            </a>
                            <a
                              className="mr-20 d-inline-block d-middle hover-up"
                              href="#"
                            >
                              <img
                                alt="jobBox"
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/blog/tw.svg`}
                              />
                            </a>
                            <a
                              className="mr-0 d-inline-block d-middle hover-up"
                              href="#"
                            >
                              <img
                                alt="jobBox"
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/blog/pi.svg`}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <h3>Comments</h3>
                      <ul className="list-comments">
                        <li>
                          <div className="author-bio mt-40 bg-white">
                            <div className="author-image mb-15">
                              <a href="#">
                                <img
                                  className="avatar"
                                  src={`${constants.IMAGE_PATH.IMAG_PATH}page/candidates/user5.png`}
                                />
                              </a>
                              <div className="author-infor">
                                <h6 className="mt-0 mb-0">Robert Fox</h6>
                                <p className="mb-0 color-text-paragraph-2 font-sm">
                                  August 25, 2022
                                </p>
                              </div>
                            </div>
                            <div className="author-des">
                              <p className="font-md color-text-paragraph">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Sed ultricies interdum massa
                                nec fermentum. Phasellus interdum dignissim
                                rhoncus. Nam vitae semper magna. Donec massa
                                enim
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="author-bio mt-40 bg-white">
                            <div className="author-image mb-15">
                              <a href="#">
                                <img
                                  className="avatar"
                                  src={`${constants.IMAGE_PATH.IMAG_PATH}page/candidates/user1.png`}
                                />
                              </a>
                              <div className="author-infor">
                                <h6 className="mt-0 mb-0">Jenny Wilson</h6>
                                <p className="mb-0 color-text-paragraph-2 font-sm">
                                  August 25, 2022
                                </p>
                              </div>
                            </div>
                            <div className="author-des">
                              <p className="font-md color-text-paragraph">
                                White white dreamy drama tically place
                                everything although. Place out apartment
                                afternoon whimsical kinder, little romantic joy
                                we flowers handmade. Nullam vestibulum semper
                                ultrices.
                              </p>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <div className="author-bio mt-40 bg-white">
                                <div className="author-image mb-15">
                                  <a href="#">
                                    <img
                                      className="avatar"
                                      src={`${constants.IMAGE_PATH.IMAG_PATH}page/candidates/user3.png`}
                                    />
                                  </a>
                                  <div className="author-infor">
                                    <h6 className="mt-0 mb-0">Eleanor Pena</h6>
                                    <p className="mb-0 color-text-paragraph-2 font-sm">
                                      August 25, 2022
                                    </p>
                                  </div>
                                </div>
                                <div className="author-des">
                                  <p className="font-md color-text-paragraph">
                                    White white dreamy drama tically place
                                    everything although. Place out apartment
                                    afternoon whimsical kinder, little romantic
                                    joy we flowers handmade. Nullam vestibulum
                                    semper ultrices.
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div className="border-bottom mt-50 mb-50" />
                      <div className="mt-30">
                        <h3>Leave a comment</h3>
                        <div className="form-comment">
                          <form action="#">
                            <div className="form-group">
                              <textarea
                                className="input-comment"
                                placeholder="Write a comment"
                                defaultValue={""}
                              />
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-7 mb-30">
                                <label className="box-agree">
                                  <input
                                    className="chkbox-aggree"
                                    type="checkbox"
                                  />
                                  <span className="font-sm color-text-paragraph">
                                    Save my name, email, and website in this
                                    browser for the next time I comment.
                                  </span>
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-5 text-end">
                                <button className="btn btn-default font-bold">
                                  Post comment
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Subscribe />
        </div>
      )}
    </>
  );
}
