import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import constants from "../../../../Utils/Constants/Constants";
import axios from "axios";
import { api } from "../../../../Api/Api";
import Swal from "sweetalert2";
import { useState } from "react";
import SubscribeModal from "./SubscribeModal";

// Define Yup validation schema
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

export default function Subscribe() {
  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
  };
  function EmailSubmit(values: any, actions: any) {
    actions?.setSubmitting(true);
    api
      .post(
        `/user/subscribe_email`,
        { email: values?.email },

        true
      )
      .then((response: any) => {
        if (response) {
          centeredToggle();
          // toast.success("Detiles added successfully");
          actions?.setSubmitting(false);
          // actions.setFieldValue("email", "");
          // actions.se
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error!",
          text: err?.response?.data?.message,
          icon: "error",
        });
        actions?.setSubmitting(false);
      });
  }
  return (
    <>
      {" "}
      <section className="section-box mt-50 mb-20">
        <div className="container">
          <div className="box-newsletter">
            <div className="row">
              <div className="col-xl-3 col-12 text-center d-none d-xl-block">
                <img
                  src={`${constants.IMAGE_PATH.IMAG_PATH}template/newsletter-left.png`}
                  alt="joxBox"
                />
              </div>
              <div className="col-lg-12 col-xl-6 col-12">
                <h2 className="text-md-newsletter text-center">
                  New Things Will Always
                  <br /> Update Regularly
                </h2>
                <div className="box-form-newsletter mt-40">
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                      // Handle form submission
                      EmailSubmit(values, actions);
                      // actions.setFieldValue("email", "");
                      // console.log("Form submitted with values:", values);
                      actions.resetForm();
                    }}
                  >
                    {({
                      isSubmitting,
                      setFieldValue,
                      handleReset,
                      handleSubmit,
                    }) => (
                      <Form
                        className="form-newsletter"
                        id="newsletterForm"
                        onSubmit={handleSubmit}
                      >
                        <Field
                          className="input-newsletter"
                          type="text"
                          name="email"
                          placeholder="Enter your email here"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="text-danger"
                        />
                        <button
                          type="submit"
                          className="btn btn-default font-heading icon-send-letter"
                          disabled={isSubmitting}
                        >
                          Subscribe
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-xl-3 col-12 text-center d-none d-xl-block">
                <img
                  src={`${constants.IMAGE_PATH.IMAG_PATH}template/newsletter-right.png`}
                  alt="joxBox"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {centred && (
        <>
          <SubscribeModal
            toggle={centeredToggle}
            isOpen={centred}
            onClosed={onCloseModal}
            size={"xl"}
            titl="Sucess"
            modalBodyClassName={""}
          />
        </>
      )}
    </>
  );
}
