import moment from "moment";
import * as Yup from "yup";
import { australianPhoneNumberRegex } from "../../../Utils/helpers/helpers";

export const validationSchemaStep1 = Yup.object().shape({
  suppot_for_you: Yup.string().required("Field is required"),
  first_name: Yup.string()
    .matches(
      /^[a-zA-Z\s.]+$/,
      "First name can only contain letters, spaces, and periods"
    )
    .test("min-words", "First name must contain at least 1 word", (value) => {
      if (!value) return false; // Invalid if value is undefined, null, or empty
      return value.split(/\s+/).filter(Boolean).length >= 1; // Check for at least 1 word
    })
    .test(
      "max-words",
      "First name must not contain more than 5 words",
      (value) => {
        if (!value) return true; // Skip validation if value is undefined or null
        return value.split(/\s+/).filter(Boolean).length <= 5; // Count words and check
      }
    )
    .required("First name is required"),
  last_name: Yup.string()
    .matches(
      /^[a-zA-Z\s.]+$/,
      "Last name can only contain letters, spaces, and periods"
    )
    .test("min-words", "Last name must contain at least 1 word", (value) => {
      if (!value) return false; // Invalid if value is undefined, null, or empty
      return value.split(/\s+/).filter(Boolean).length >= 1; // Check for at least 1 word
    })
    .test(
      "max-words",
      "Last name must not contain more than 5 words",
      (value) => {
        if (!value) return true; // Skip validation if value is undefined or null
        return value.split(/\s+/).filter(Boolean).length <= 5; // Count words and check
      }
    )
    .required("Last name is required"),

  mobile: Yup.string()
    .matches(
      /^\+614\d{8}$/,
      "Mobile number must be in the format +614XXXXXXXX (e.g., +61412345678)"
    )
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email must be 100 characters or less")
    .required("Email is required"),

  dob: Yup.date()
    .nullable()
    .required("Date of birth is required")
    .test("dob", "You must be 18 years or older", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    }),
  gender: Yup.number()
    .required("Gender is required")
    .oneOf([1, 2, 3], "Invalid gender option"),

  street_number: Yup.string().required("Field is required"),

  address_line_two: Yup.string()
    .notRequired()
    .test("max-words", "Address line 2 must not exceed 30 words", (value) => {
      if (!value) return true; // If the field is empty, it's valid
      const wordCount = value.trim().split(/\s+/).length; // Count the words
      return wordCount <= 30; // Validate word count
    }),
  address_line_one: Yup.string()
    .required("Field is required")
    .test("max-words", "Address line 1 must not exceed 30 words", (value) => {
      if (!value) return true; // If the field is empty, it's valid
      const wordCount = value.trim().split(/\s+/).length; // Count the words
      return wordCount <= 30; // Validate word count
    }),

  state: Yup.string().required("Field is required"),
  suburb: Yup.string().required("Field is required"),
});

export const validationSchema = Yup.object().shape({
  step: Yup.number()
    .required("Step is required")
    .min(1, "Step must be at least 1")
    .max(10, "Step cannot be more than 10"), // Adjust the max value as per your requirement
  profile_type: Yup.number()
    .required("Profile type is required")
    .oneOf([1, 2, 3], "Invalid profile type"), // Adjust the allowed profile types as needed
  dob: Yup.date()
    .required("Date of birth is required")
    .max(new Date(), "Date of birth cannot be in the future"),
  gender: Yup.number()
    .required("Gender is required")
    .oneOf([1, 2, 3], "Invalid gender option"), // Assuming 1 = Male, 2 = Female, 3 = Other
  residency_status: Yup.number()
    .required("Residency status is required")
    .oneOf([1, 2, 3], "Invalid residency status"), // Adjust the allowed statuses as needed
  address: Yup.string()
    .required("Address is required")
    .min(5, "Address must be at least 5 characters long")
    .max(100, "Address cannot be more than 100 characters long"),
  profile_img: Yup.string()
    .transform((value, originalValue) => {
      // console.log(value, "originalValue");
      // Handle the transformation here if the value is an array
      if (value?.[0]?.name) {
        return value?.[0]?.name;
      } else {
        return "";
      }
    })
    .required("Field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email must be 100 characters or less")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
    .required("Mobile number is required"),
});
export const validationSchemaStep_2 = Yup.object().shape({
  step: Yup.number().required(),
  suburb: Yup.string().required("Suburb is required"),
  service_radius: Yup.number()
    .required("Service radius is required")
    .positive()
    .integer(),
  all_day_availability: Yup.boolean(),
  availability_details: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().when("toggleSwitch", {
        is: true,
        then: (schema) => schema.required("Day is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      start_time: Yup.string().when("toggleSwitch", {
        is: true,
        then: (schema) => schema.required("Start time is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      end_time: Yup.string().when("toggleSwitch", {
        is: true,
        then: (schema) => schema.required("End time is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      hourly_rate: Yup.number().when("toggleSwitch", {
        is: true,
        then: (schema) =>
          schema
            .required("Hourly rate is required")
            .positive("Hourly rate must be a positive number"),
        otherwise: (schema) => schema.notRequired(),
      }),
      toggleSwitch: Yup.boolean().required(),
    })
  ),
});
export const validationSchema_3 = Yup.object().shape({
  step: Yup.number().required("Step is required"),
  document_id: Yup.string().required("Document ID is required"),
  document_id_expire: Yup.date()
    .required("Document ID expiration date is required")
    .min(new Date(), "Expiration date must be in the future"),
  document_id_img: Yup.mixed()
    .required("Document ID image is required")
    .test("fileFormat", "Unsupported Format", (value: any) =>
      ["image/jpeg", "image/png", "application/pdf"].includes(value?.[0]?.type)
    ),
  // police_verification_id: Yup.string().required(
  //   "Police Verification ID is required"
  // ),
  police_verification_id_expire: Yup.date()
    .required("Police Verification ID expiration date is required")
    .min(new Date(), "Expiration date must be in the future"),
  police_verification_id_img: Yup.mixed()
    .required("Police Verification ID image is required")
    .test("fileFormat", "Unsupported Format", (value: any) =>
      ["image/jpeg", "image/png", "application/pdf"].includes(value?.[0]?.type)
    ),
  wwcc_id: Yup.string().required("WWCC ID is required"),
  wwcc_id_expire: Yup.date()
    .required("WWCC ID expiration date is required")
    .min(new Date(), "Expiration date must be in the future"),
  wwcc_id_img: Yup.mixed()
    .required("WWCC ID image is required")
    .test("fileFormat", "Unsupported Format", (value: any) =>
      ["image/jpeg", "image/png", "application/pdf"].includes(value?.[0]?.type)
    ),
  first_aid_certificate_id: Yup.string().required(
    "First Aid Certificate ID is required"
  ),
  first_aid_certificate_expire: Yup.date()
    .required("First Aid Certificate expiration date is required")
    .min(new Date(), "Expiration date must be in the future"),
  first_aid_certificate_img: Yup.mixed()
    .required("First Aid Certificate image is required")
    .test("fileFormat", "Unsupported Format", (value: any) =>
      ["image/jpeg", "image/png", "application/pdf"].includes(value?.[0]?.type)
    ),
});

export const validationSchema2 = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email must be 100 characters or less")
    .required("Email is required"),

  payment_mode: Yup.string().required("Field is required"),

  ndis: Yup.string().when("payment_mode", {
    is: (value: any) => value !== "2" && value !== "7",
    then: (schema) =>
      schema
        .matches(
          /^43\d{7}$/,
          'The NDIS number must be 9 digits long, start with "43", and have no spaces.'
        )
        .required("Field is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  plan_manager_name: Yup.string().when("payment_mode", {
    is: (value: any) => value !== "2" && value !== "7",
    then: (schema) =>
      schema
        .max(100, "Full name must be 100 characters or less")
        .required("Field is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  contact_name_ndis: Yup.string().when("payment_mode", {
    is: (value: any) => value !== "2" && value !== "7",
    then: (schema) =>
      schema
        .max(100, "Full name must be 100 characters or less")
        .required("Field is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  contact_number_dis: Yup.string().when("payment_mode", {
    is: (value: any) => value !== "2" && value !== "7",
    then: (schema) =>
      schema
        .matches(
          /^\+614\d{8}$/,
          "Mobile number must be in the format +614XXXXXXXX (e.g., +61412345678)"
        )
        .required("Mobile number is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  support_coordinator_name: Yup.string().when("payment_mode", {
    is: (value: any) => value !== "2" && value !== "7",
    then: (schema) =>
      schema
        .max(100, "Support coordinator name must be 100 characters or less")
        .required("Field is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  support_coordinator_email: Yup.string().when("payment_mode", {
    is: (value: any) => value !== "2" && value !== "7",
    then: (schema) =>
      schema.email("Invalid email address").required("Field is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  support_coordinator_contact_number: Yup.string().when("payment_mode", {
    is: (value: any) => value !== "2" && value !== "7",
    then: (schema) =>
      schema

        .matches(
          /^\+614\d{8}$/,
          "Mobile number must be in the format +614XXXXXXXX (e.g., +61412345678)"
        )

        .required("Field is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const validationSchem3 = Yup.object().shape({
  // summary: Yup.string()
  //   .test(
  //     "word-count",
  //     "Summary must be 200 words or less",
  //     (value) => !value || value.split(/\s+/).filter(Boolean).length <= 200
  //   )
  //   .required("Field is required"),
  // health_status: Yup.string()
  //   .test(
  //     "word-count",
  //     "Health status must be 200 words or less",
  //     (value) => !value || value.split(/\s+/).filter(Boolean).length <= 200
  //   )
  //   .required("Field is required"),
  // care_request_title: Yup.string()
  //   .test(
  //     "word-count",
  //     "Profile Title must be 10 words or less",
  //     (value) => !value || value.split(/\s+/).filter(Boolean).length <= 10
  //   )
  //   .required("Field is required"),
  summary: Yup.string()
    // .test(
    //   "word-count",
    //   "Summary must be 200 words or less",
    //   (value) => !value || value.split(/\s+/).filter(Boolean).length <= 200
    // )
    .max(250, "Summary must not exceed 250 characters")
    .required("Field is required"),
  health_status: Yup.string()
    // .test(
    //   "word-count",
    //   "Health status must be 200 words or less",
    //   (value) => !value || value.split(/\s+/).filter(Boolean).length <= 200
    // )
    .max(300, "Health status must not exceed 300 characters")
    .required("Field is required"),
  care_request_title: Yup.string()
    // .test(
    //   "word-count",
    //   "Profile title must be 10 words or less",
    //   (value) => !value || value.split(/\s+/).filter(Boolean).length <= 10
    // )
    .max(80, "Profile title must not exceed 80 characters")
    .required("Field is required"),
});
export const initialValues = {
  step: 1,
  profile_type: null,
  dob: "",
  gender: "",
  residency_status: null,
  address: "",
  profile_img: "",
  email: "",
  mobile: "",
};

export const initialValuesStep_2 = {
  step: 2,
  suburb: "",
  all_day_availability: false,
  availability_details: [
    {
      day: "",
      start_time: "",
      end_time: "",
      hourly_rate: "",
      toggleSwitch: false,
    },
  ],
};

export const initialValues_3 = {
  step: 1,
  document_id: "",
  document_id_expire: "",
  document_id_img: null, // Use null for FileBuffer as it will be a file object
  // police_verification_id: "",
  police_verification_id_expire: "",
  police_verification_id_img: null, // Use null for FileBuffer as it will be a file object
  wwcc_id: "",
  wwcc_id_expire: "",
  wwcc_id_img: null, // Use null for FileBuffer as it will be a file object
  first_aid_certificate_id: "",
  first_aid_certificate_expire: "",
  first_aid_certificate_img: null, // Use null for FileBuffer as it will be a file object
};
export const initialValues_4 = {
  step: 4,
  about_yourself: "",
  okay_with_pets: false,
  work_experiences: [
    {
      company_name: "",
      designation: "",
      start_date: "",
      end_date: "",
      is_current_job: false,
      description: "",
    },
  ],
  qualifications: [
    {
      course_name: "",
      institution_name: "",
      start_date: "",
      end_date: "",
      is_current_study: false,
      description: "",
    },
  ],
  languages_spokens: [],
  interests: [],
  care_tasks: [],
  willing_to_work: [],
  experience_with_aged_cares: [],
  experience_with_children: [],
};
export const initialValues_5 = {
  step: 5,
  extra_charge_after_hours: false,
  abn: "",
  bank_name: "",
  bank_branch_name: "",
  bank_account_number: "",
};
