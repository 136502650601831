import { Card, CardBody, Container, Modal } from "reactstrap";
import constants from "../../../../Utils/Constants/Constants";
import { Link } from "react-router-dom";

export default function SubscribeModal({
  toggle,
  isOpen,
  onClosed,
  title,
  modalBodyClassName,
  children,
  size,
}: any) {
  return (
    <>
      <Modal
        backdrop={onClosed}
        isOpen={isOpen}
        centered
        toggle={toggle}
        onClosed={onClosed}
        size={size}
      >
        <Container className="subscribed-container my-4">
          <Card className="subscribed-page">
            <CardBody>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <img
                      className="uss-logo01 subscribed-img"
                      src={`${constants.IMAGE_PATH.IMAG_PATH}template/yellow-star.png`}
                      alt="USS Logo"
                    />
                    <h3 className="mt-4 mb-3 ndis-title-head">
                      Thank you for Subscribing!
                    </h3>
                    <p className="subscribed-content mb-40">
                      You have successfully subscribed to our list. We will
                      contact you.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="block-signin text-center">
                      <Link
                        className="btn btn-default btn-shadow hover-up"
                        to=""
                        onClick={() => onClosed()}
                      >
                        Close
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </Modal>
    </>
  );
}
