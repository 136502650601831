import { Card, CardBody, Table } from "reactstrap";
import { FullCalendarIndex } from "../../../../components/FullCander/FullViewCalander";
import { convertTo12HourFormat } from "../../../../Utils/helpers/helpers";
import Nodata from "../../../../components/Nodata/NodataFound";
import NodataList from "../../../../components/Nodata/NodataFoundList";

export default function Availability({ data_path }: any) {
  return (
    <>
      {" "}
      <div className="tab-pane fade show active">
        {/* <h4>Availability</h4> */}
        <div id="calendar" className="available-calender mt-20">
          {/* <FullCalendarIndex
            availabilityDetails={data_path?.availability_details}
          /> */}
          <Card>
            <CardBody>
              {" "}
              <Table striped>
                {data_path?.availability_details?.length === 0 ? (
                  <>
                    <NodataList />
                  </>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data_path?.availability_details?.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td>{item?.day?.name}</td>
                              <td>
                                {convertTo12HourFormat(item?.startTime)} to{" "}
                                {convertTo12HourFormat(item?.endTime)}
                              </td>
                              <td>Available</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
