export default function TabIndex({ setTab, tab }: any) {
  return (
    <>
      {" "}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <a
            href="#"
            className={`nav-link icon-common m-0 ${tab === 1 ? "active" : ""}`}
            onClick={() => {
              setTab(1);
            }}
          >
            <i className="ri-user-fill me-2"></i>Short Bio
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#"
            className={`nav-link icon-common m-0 ${tab === 2 ? "active" : ""}`}
            onClick={() => {
              setTab(2);
            }}
          >
            <i className="ri-calendar-2-fill me-2"></i>Availability
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#"
            className={`nav-link icon-common m-0 ${tab === 3 ? "active" : ""}`}
            onClick={() => {
              setTab(3);
            }}
          >
            <i className="ri-money-dollar-circle-fill me-2"></i>Rates
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#"
            className={`nav-link icon-common m-0 ${tab === 4 ? "active" : ""}`}
            onClick={() => {
              setTab(4);
            }}
          >
            <i className="ri-star-fill me-2"></i>Reviews
          </a>
        </li>
      </ul>
    </>
  );
}
