export default function NodataList({ nodata }: any) {
  return (
    <>
      <div className="text-center">
        {" "}
        <div>
          {" "}
          <img
            style={{ height: "200px" }}
            src="https://www.ngo-openbook.be/images/img_nodatafound.svg"
          />
        </div>
        <h6>Sorry, no data found.</h6>
      </div>
    </>
  );
}
