import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";

import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { useQuery } from "react-query";
import { getLocationData_ } from "../../../Api/WebsiteApi";
import { daysOfWeek } from "../../../Utils/Constants/select_options";
import PrefixForm from "../../../components/RangeSlider/RangeSlider";
import {
  initialValuesStep_2,
  validationSchemaStep_2,
} from "../ProfileMethods/ProfileValidation";
import {
  addModalInitialValuesHandlerStep4,
  ProfileSubmitHandlerStep2,
} from "../ProfileMethods/ProfileSubmitHandlers";
import Autocomplete from "../../CareSeekerProfile/Steps/components/AutoComplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddAvalibility from "./SubSections/Step2/AddAvalibility";

const Step2 = ({ setStep, Userdata, UserListRefetch }: any) => {
  const [value, setValues] = useState<any>();

  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep_2);

  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep4(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValuesStep_2);
    }
    setValues(
      Userdata?.user_details?.serviceRadius
        ? Userdata?.user_details?.serviceRadius
        : 0
    );
  }, [Userdata, Userdata?.id, value]);

  const [inputValues, setInputValues] = useState<any>([]);
  const { data: location_ } = useQuery(
    [`data`, inputValues],
    () => getLocationData_(inputValues),
    { keepPreviousData: true, staleTime: 1000 }
  );
  console.log(addModalInitialValues, "addModalInitialValues");
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addModalInitialValues}
        validationSchema={validationSchemaStep_2}
        onSubmit={(values, actions) => {
          ProfileSubmitHandlerStep2(values, actions, setStep, UserListRefetch);
        }}
      >
        {({
          errors,
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setFieldTouched,
          touched,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const isAnyToggleSwitchTrue = values?.availability_details?.some(
              (detail) => detail.toggleSwitch
            );

            if (values.all_day_availability) {
              values.availability_details.forEach((detail, index) => {
                setFieldValue(
                  `availability_details.${index}.toggleSwitch`,
                  true // Set all individual toggles to true if "All Day" is available
                );
              });
            } else {
              if (!isAnyToggleSwitchTrue) {
                values?.availability_details?.forEach((detail, index) => {
                  setFieldValue(
                    `availability_details.${index}.toggleSwitch`,
                    false // Set all individual toggles to false if "All Day" is not available
                  );
                });
              }
            }
          }, [setFieldValue, values.all_day_availability]);

          const handleStartDateChange = (name: any, value: any) => {
            setFieldValue(name, value);
            if (name === "start_time") {
              values.availability_details.forEach((_, index) => {
                setFieldValue(
                  `availability_details.${index}.start_time`,
                  !values.all_day_availability ? null : value
                );
              });
            } else {
              values.availability_details.forEach((_, index) => {
                setFieldValue(
                  `availability_details.${index}.end_time`,
                  !values.all_day_availability ? null : value
                );
              });
            }
          };
          const handleSwitchChange = (index: number) => {
            // Access the toggleSwitch value of the specific day
            const currentState =
              values.availability_details[index]?.toggleSwitch;

            if (currentState !== undefined) {
              // Toggle the value for that specific day
              setFieldValue(
                `availability_details.${index}.toggleSwitch`,
                !currentState
              );
            } else {
              console.error("toggleSwitch is not defined for the given index");
            }
          };

          return (
            <>
              {/* <ScrollToFieldError /> */}
              <Form
                className="seeker-registration-form2 giver-register4"
                onSubmit={handleSubmit}
              >
                {/* <FormGroup>
                <Label>State</Label>
                <SelectComponent
                  loading={sutloading}
                  options={state_aust}
                  value={state_aust?.find(
                    (option: any) => option.value === values.state
                  )}
                  onChange={(selectedOption: any) => {
                    setFieldValue("state", selectedOption?.value);
                    setFieldValue("suburb", "");

                    setInputValue(selectedOption?.value);
                  }}
                  isClearable={true}
                  error={errors?.state ? errors.state : ""}

                  // isMulti={true}
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label>Suburb</Label>
                <SelectComponent
                  loading={subloading}
                  options={surub_data ? surub_data : []}
                  value={
                    values?.suburb
                      ? surub_data?.find(
                          (option: any) => option.value === values?.suburb
                        )
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    setFieldValue("suburb", parseInt(selectedOption?.value));
                  }}
                  isClearable={true}
                  error={errors?.suburb ? errors.suburb : ""}
                />
                {errors?.suburb && (
                  <div className="text-danger">{errors?.suburb}</div>
                )}
              </FormGroup> */}
                <FormGroup>
                  <Label>Suburb</Label>
                  <Autocomplete
                    setInputValue={setInputValues}
                    loaction_={location_}
                    setFieldValue={setFieldValue}
                    value_data={values?.suburb}
                    values={values}
                  />
                  <ErrorMessage
                    name="suburb"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup className="mt-30 mb-30 service-radius-formgroup">
                  <Label className="mb-0 " for="service_radius">
                    Service Radius
                  </Label>{" "}
                  <PrefixForm
                    rtl={false}
                    setFieldValue={setFieldValue}
                    name="service_radius"
                    value={values?.service_radius}
                  />
                  <div className="mt-0 text-center">
                    {values?.service_radius} km Radius
                  </div>
                  <ErrorMessage
                    name="service_radius"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <AddAvalibility
                  values={values}
                  setFieldValue={setFieldValue}
                  handleStartDateChange={handleStartDateChange}
                  daysOfWeek={daysOfWeek}
                  errors={errors}
                  touched={touched}
                />
                {errors.availability_details &&
                  typeof errors.availability_details === "string" && (
                    <div className="text-danger">
                      {errors.availability_details}
                    </div>
                  )}
                {Object.keys(errors).length === 1 ? (
                  <>
                    {" "}
                    <div>
                      <Alert color="danger" className="mt-30" id="g">
                        Please make sure all fields are filled correctly.
                      </Alert>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="text-center mt-5 d-float-center justify-content-center gap-15">
                  <Button
                    className="btn btn-brand-2"
                    type="submit"
                    onClick={() => [setStep(3)]}
                  >
                    <i className="ri-arrow-left-line"></i>&nbsp;BACK
                  </Button>
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Step2;
