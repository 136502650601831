import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import { Button, Input, Row, Col, Card } from "reactstrap";
import * as Yup from "yup";
import { OTPSubmitHandler } from "./SubmitHandlers";
import { capitalizeFirstLetterOfEachWord } from "../../../Utils/helpers/helpers";

export const OtpValidationSchema = Yup.object().shape({
  otp: Yup.array()
    .required("OTP is required")
    .length(4, "Please enter OTP")
    .test(
      "validate-digits",
      "Each OTP field must contain exactly 1 digit",
      function (value) {
        if (value && value.length === 4) {
          return value.every((val) => /^[0-9]$/.test(val));
        }
        return true; // Skip individual validation if length is not 5
      }
    ),
});
export default function NDISOTP({
  otp,
  setOTP,
  dataList,
  fetchOTP,
  id,
  setdataList,
  setOtp,
  setAlldata
}: any) {
  const [resendCount, setResendCount] = useState(0);
  const [timer, setTimer] = useState(60); // Countdown in seconds
  const [isTimerActive, setIsTimerActive] = useState(true);

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (isTimerActive && timer > 0) {
      countdown = setTimeout(() => setTimer((prev) => prev - 1), 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }
    return () => clearTimeout(countdown);
  }, [isTimerActive, timer]);

  const handleResendCode = () => {
    if (resendCount < 3) {
      localStorage.removeItem("OTP_KEY");
      setResendCount((prev) => prev + 1);
      setTimer(60); // Reset timer to 60 seconds
      setIsTimerActive(true);
      fetchOTP(id); // Function to resend OTP
    }
  };

  const inputRefs = useRef<any>([]);

  const handleChange = (
    e: { target: { value: any } },
    index: number,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean
      ): Promise<void | FormikErrors<{ otp: string[] }>>;
      (arg0: string, arg1: any): void;
    }
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      // Ensure only a single digit is entered
      setFieldValue(`otp[${index}]`, value);

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    e: { key: string; target: { value: string } },
    index: number,
    setFieldValue: any
  ) => {
    if (e.key === "Backspace") {
      if (e.target.value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        setFieldValue(`otp[${index}]`, "");
      }
    }
  };
  return (
    <>
      <style>
        {`
          .ndis-page.card {
            width: 70%;
            margin: auto;
          }
          .ndis-title-head {
            font-size: 20px;
          }
        `}
      </style>
      {" "}
      <Card className="authincation-content card-body py-5">
        <div className="text-center mb-3 logo-div01">
          <h3 className="block-subTitle text-center uss-text-info">
            <span className="uss-text-primary">Verify OTP</span>
          </h3>
        </div>
        <h6 className="uss-text- text-center">
          Hi{" "}
          {capitalizeFirstLetterOfEachWord(
            dataList?.booking_details?.careseeker_details?.careseeker_details
              ?.ndisContactName
          )}
          ,
          <br />
          Please enter the one-time password (OTP),<br/> we just sent to your email to verify your account.
          {/* <span
            style={{
              color: "#53aeb2",
            }}
          >
            {
              dataList?.booking_details?.careseeker_details?.careseeker_details
                ?.ndisContactEmail
            }
          </span> */}

        </h6>
        <Formik
          initialValues={{ otp: ["", "", "", ""] }}
          validationSchema={OtpValidationSchema}
          onSubmit={(values, actions) => {
            const otpValue = values.otp.join("");
            // console.log("OTP Submitted:", otpValue);
            OTPSubmitHandler(
              values,
              actions,
              setOTP,
              dataList,
              id,
              setdataList,
              setOtp,
              setAlldata
            );
          }}
        >
          {({ handleSubmit, setFieldValue, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div className="col-md-8 mx-auto">
                <div
                  id="otp"
                  className="inputs d-flex flex-row justify-content-center mt-2 main_otp"
                >
                  {Array(4)
                    .fill("")
                    .map((_, index) => (
                      <Field
                        key={index}
                        name={`otp[${index}]`}
                        as={Input}
                        className="m-2 text-center form-control otp-field"
                        maxLength={1}
                        innerRef={(el: any) => (inputRefs.current[index] = el)}
                        onChange={(e: { target: { value: any } }) =>
                          handleChange(e, index, setFieldValue)
                        }
                        onKeyDown={(e: {
                          key: string;
                          target: { value: string };
                        }) => handleKeyDown(e, index, setFieldValue)}
                      />
                    ))}
                </div>
              </div>
              <ErrorMessage
                name="otp"
                component="div"
                className="text-danger text-center mt-2"
              />
              <div className="text-center mt-30">
                <Button
                  className="btn btn-brand-1"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submit..." : "Submit"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Button
          color="link"
          onClick={handleResendCode}
          disabled={isTimerActive || resendCount >= 3}
          className="text-center"
        >
          {isTimerActive ? `Resend Code in ${timer}s` : "Resend SMS Code"}
        </Button>

        {resendCount >= 3 && (
          <div className="text-danger text-center">
            Maximum resend attempts reached.
          </div>
        )}
      </Card>
    </>
  );
}
