import { useEffect, useState } from "react";

import { useQuery } from "react-query";
import {
  getHolidays,
  getUserData,
  getUserDataCareSeekerRelation,
} from "../../Api/WebsiteApi";
import Step4 from "./Steps/Step4";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import { getURLPassData } from "../../Utils/helpers/helpers";

const CreateProfileCareSeeker = () => {
  const navigate = useNavigate();

  const token = getURLPassData();

  const {
    data: Userdata,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery([`user`], getUserData, {
    keepPreviousData: true,
  });
  const {
    data: Userdatacareeseeker,
    error: UserErrorcareeseeker,
    isLoading: UserLoadingcareeseeker,
    refetch: UserListRefetchcareeseeker,
  }: any = useQuery(
    [`user_careseeker`, Userdata?.careseeker_details?.id],
    () => getUserDataCareSeekerRelation(Userdata?.careseeker_details?.id),
    {
      keepPreviousData: true,
    }
  );

  const [all_data, setAllData] = useState<any>([]);
  useEffect(() => {
    if (
      Userdatacareeseeker?.[1]?.careseeker_details?.supportFor?.id !== 1 &&
      Userdatacareeseeker?.[1]?.careseeker_details?.supportFor
    ) {
      setAllData(Userdatacareeseeker?.[1]);
    } else {
      setAllData(Userdatacareeseeker?.[0]);
    }
  }, [
    Userdata?.careseeker_details?.id,
    Userdatacareeseeker?.[1]?.careseeker_details?.supportFor,
    Userdatacareeseeker?.[0],
  ]);

  const [step, setStep] = useState<any>(
    Userdatacareeseeker?.[1]?.careseeker_details?.supportFor?.id !== 1 &&
      Userdatacareeseeker
      ? Userdatacareeseeker?.[1]?.careseeker_details?.nextStep
      : Userdatacareeseeker?.[0]?.careseeker_details?.nextStep || 1
  );

  const nextStep = all_data?.careseeker_details?.nextStep?all_data?.careseeker_details?.nextStep:1;
  useEffect(() => {
    if (localStorage.getItem("AUTH_TOKEN") || token) {
      const currentStep =
        step || nextStep || all_data?.careseeker_details?.nextStep || 1;
      setStep(currentStep ? currentStep : 1);
    } else {
      navigate("/careseeker-create-account", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Userdata?.careseeker_details, nextStep, navigate, step, token, all_data]);
  const isStepDisabled = (stepNumber: number) => stepNumber > nextStep;
  console.log(
    all_data,
    " Userdatacareeseeker?.[1]?.careseeker_details?.supportFor?.id",
    step,
    nextStep
  );

  return (
    <>
      {UserLoading ? (
        <Loader />
      ) : (
        <div className="authincation h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-10 login-page01">
                <div className="authincation-content card card-body py-4">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="text-center mb-3 logo-div01">
                        <h3 className="block-subTitle text-center uss-text-info">
                          <span className="uss-text-primary">Create</span>{" "}
                          CareSeeker Profile
                        </h3>
                      </div>
                      <div className="login-page01 wizard-form01">
                        <div className="stepwizard">
                          <div className="stepwizard-row setup-panel">
                            {[1, 2, 3, 4]?.map((stepNumber) => (
                              <div
                                className={`stepwizard-step ${
                                  stepNumber < nextStep ? "success" : ""
                                }`}
                                key={stepNumber}
                              >
                                <button
                                  className={`btn ${
                                    // stepNumber === step
                                    //   ? "btn-default"
                                    //   :
                                    stepNumber <= nextStep
                                      ? "btn-primary"
                                      : "btn-default"
                                  } btn-circle`}
                                  disabled={isStepDisabled(stepNumber)}
                                  onClick={() => {
                                    setStep(stepNumber);
                                  }}
                                >
                                  {stepNumber < nextStep ? (
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  ) : (
                                    stepNumber
                                  )}
                                </button>
                                <p>
                                  <span
                                    id={`steps-${stepNumber}_tick`}
                                    className="d-none"
                                  >
                                    <i className="fa fa-check"></i>
                                  </span>{" "}
                                  Step {stepNumber}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {step === 1 && (
                        <Step1
                          setStep={setStep}
                          Userdata={all_data}
                          ListRefetch={
                            UserListRefetchcareeseeker || UserListRefetch
                          }
                          tab={step}
                          UserLoading_data={UserLoading}
                        />
                      )}
                      {step === 3 && (
                        <Step2
                          setStep={setStep}
                          Userdata={all_data}
                          ListRefetch={
                            UserListRefetchcareeseeker || UserListRefetch
                          }
                          UserLoading_data={UserLoading}
                          tab={step}
                        />
                      )}
                      {step === 2 && (
                        <Step3
                          setStep={setStep}
                          Userdata={all_data}
                          ListRefetch={
                            UserListRefetchcareeseeker || UserListRefetch
                          }
                          UserLoading_data={UserLoading}
                          tab={step}
                        />
                      )}
                      {step === 4 && (
                        <Step4
                          setStep={setStep}
                          Userdata={all_data}
                          ListRefetch={
                            UserListRefetchcareeseeker || UserListRefetch
                          }
                          tab={step}
                          UserLoading_data={UserLoading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateProfileCareSeeker;
