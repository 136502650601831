import moment from "moment";
import { formatDate } from "../../../../Utils/helpers/helpers";

export default function Reviews({ data_path }: any) {
  return (
    <>
      {" "}
      <div className="tab-pane fade show active">
        <h4>Comments</h4>

        {data_path?.additionalData?.reviews?.map((item: any) => {
          return (
            <>
            <style>
              {`
              .author-bio .author-image .person-img {
                width: 55px;
                height: 55px;
                border-radius: 8px;
              }
                    .author-bio .author-image .person-img img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                    margin-right: 0;
                    }
              `}
            </style>
              <div className="author-bio mt-40 bg-white d-block">
                <div className="author-des p-10">
                  <div className="author-image mb-10 justify-content-between">
                    <div className="d-float-center gap-10">
                      <div className="person-img">
                        <img
                          className="avatar"
                          src={item?.careseekerThumbnail}
                          alt=""
                        />
                      </div>
                      <div className="author-infor">
                        <h6 className="mt-0 mb-0">
                          {item?.careseekerFullName}
                        </h6>
                        <div className="star-rating d-float-center my-0">
                          {Array.from({ length: 5 }).map((_, index) => (
                            <i
                              className={`fa ${
                                index < item?.careseekerRating
                                  ? "fa-star text-warning"
                                  : "fa-star-o text-warning"
                              }`}
                            />
                          ))}
                        </div>
                      </div>
                    </div>                    
                    <p className="mb-0 color-text-paragraph-2 font-sm">
                      {moment(item?.engagementDate).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <hr className="mt-0 mb-2" />
                  <p className="font-md color-text-paragraph">
                    {item?.careseekerReview}
                  </p>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
