import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
const todayStartOfTheDay = today.startOf("day");

export default function ResponsiveTimePickers({
  label,
  name,
  onChange,
  value,
  className,
  aria_invalid,
  selectedDate,
}: any) {
  const parsedDate = selectedDate ? dayjs(selectedDate) : null;

  // Get today's date without time
  const today = dayjs().startOf("day");

  // Get the current time
  const now = dayjs();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        <DemoItem label={label}>
          <TimePicker
            name={name}
            onChange={onChange}
            className={className}
            minutesStep={30}
            skipDisabled
            // closeOnSelect={true}
            // disablePast
            disablePast={parsedDate?.isSame(today, "day")} // Disable past times only if today
            minTime={parsedDate?.isSame(today, "day") ? now : undefined} // Set minTime only if to
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
