import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { GENDER } from "../../../Utils/Constants/select_options";
import SelectComponent from "../../../components/ReactSelect/ReactSelect";
import { useQuery } from "react-query";
import {
  getAustState,
  getProfileData,
  getResidancyData,
  getSubrub,
} from "../../../Api/WebsiteApi";
import { ProfileSubmitHandler } from "../ProfileMethods/ProfileSubmitHandlers";
import { api } from "../../../Api/Api";
import DatePickerReact from "../../../components/DatePicker/DatePickerReact";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Step1 = ({ setStep, Userdata, UserListRefetch, holiday }: any) => {
  const [profileImgPreview, setProfileImgPreview] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serch_sate, setSearch] = useState("");
  const { data: Residancydata }: any = useQuery(
    [`Residancydatadata`],
    () => getResidancyData(),
    {
      keepPreviousData: true,
    }
  );

  const { data: profiledata }: any = useQuery(
    [`profile`],
    () => getProfileData(),
    {
      keepPreviousData: true,
    }
  );

  const [formData, setFormData] = useState({
    profile_type: null,
    dob: null,
    gender: "",
    residency_status: "",
    // address: "",
    profile_img: null,
    profile_img_: null,
    email: "",
    mobile: "",
    last_name: "",
    street_number: "",
    address_line_one: "",
    address_line_two: "",
    first_name: "",
    address_suburb: null,
    state: "",
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    api
      .get(`/user/user_details`, true)
      .then(([status, response]) => {
        const data = response?.data;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions

        setFormData({
          profile_type: data?.user_details?.profileType?.id || "",
          dob: data?.user_details?.dob || null,
          gender: data?.user_details?.gender?.id?.toString() || "",
          residency_status: data?.user_details?.residencyStatus?.id || "",
          address_line_one: data?.user_details?.addressLineOne || "",
          address_line_two: data?.user_details?.addressLineTwo || "",
          street_number: data?.user_details?.streetNumber || "",
          profile_img: null,
          profile_img_: data?.user_details?.profileImg || null,
          email: data?.email || "",
          mobile: data?.mobile || "",
          first_name: data?.firstName || "",
          last_name: data?.lastName || "",
          address_suburb: data?.user_details?.addressSuburb?.id || "",
          state: data?.user_details?.state?.id || "",
        });
        setSearch(
          data?.user_details?.addressSuburb?.locality
            ? data?.user_details?.addressSuburb?.locality
            : ""
        );
      })
      .catch((err) => {});
  };

  const { data: state_aust } = useQuery([`state_aust`], () => getAustState(), {
    keepPreviousData: true,
    staleTime: 1000,
  });
  // const { data: surub_data, isLoading: subloading } = useQuery(
  //   [`surub_data`, formData?.state],
  //   () => getSubrub(formData?.state),
  //   {
  //     // keepPreviousData: true,
  //     staleTime: 1000,
  //   }
  // );
  const { data: surub_data, isLoading: subloading } = useQuery(
    [`surub_data`, formData?.state, serch_sate],
    () =>
      serch_sate || formData?.state
        ? getSubrub(formData?.state, serch_sate)
        : "",
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  // console.log(formData?.state, formData?.suburb, "ashdjagshdagsdgsad");
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, profile_img: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImgPreview(reader?.result);
      };
      reader.readAsDataURL(file);
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        profile_img: undefined,
      }));
    }
  };

  const handleChange = (e: any, namez?: any) => {
    if (namez === "dob") {
      const formattedDate: any = e; // Format date if necessary
      setFormData({ ...formData, [namez]: formattedDate });
      setErrors((prevErrors: any) => ({ ...prevErrors, [namez]: undefined }));
    } else if (namez === "mobile") {
      setFormData({ ...formData, [namez]: e });
      setErrors((prevErrors: any) => ({ ...prevErrors, [namez]: undefined }));
    } else {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  const validateForm = () => {
    let newErrors: any = {};

    if (!formData.first_name) {
      newErrors.first_name = "First name is required";
    } else if (!/^[a-zA-Z\s.]+$/.test(formData.first_name)) {
      newErrors.first_name =
        "First name can only contain letters, spaces, and periods";
    } else if (formData?.first_name?.split(/\s+/)?.length > 5) {
      newErrors.first_name = "First name must not exceed 5 words";
    }
    if (!formData.last_name) {
      newErrors.last_name = "Last name is required";
    } else if (!/^[a-zA-Z\s.]+$/.test(formData.last_name)) {
      newErrors.last_name =
        "Last name can only contain letters, spaces, and periods";
    } else if (formData?.first_name?.split(/\s+/)?.length > 5) {
      newErrors.last_name = "Last name must not exceed 5 words";
    }

    if (!formData.email) newErrors.email = "Email is required";

    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.dob) {
      newErrors.dob = "Date of birth is required";
    } else {
      const today = new Date();
      const dob = new Date(formData.dob);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      const dayDifference = today.getDate() - dob.getDate();

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      if (age < 18) {
        newErrors.dob = "You must be 18 years or older";
      }
    }

    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required";
    } else if (!/^\+614\d{8}$/.test(formData.mobile)) {
      newErrors.mobile =
        "Mobile number must be in the format +614XXXXXXXX (e.g., +61412345678)";
    }

    if (!formData.street_number) {
      newErrors.street_number = "Street number is required";
    } else if (formData.street_number.split(/\s+/).length > 30) {
      newErrors.street_number = "Street number must not exceed 30 words";
    }
    if (!formData.address_line_one) {
      newErrors.address_line_one = "Address line 1 is required";
    } else if (formData.address_line_one.split(/\s+/).length > 30) {
      newErrors.address_line_one = "Address line 1 must not exceed 30 words";
    }

    // if (formData.address_line_two.split(/\s+/)?.length > 30) {
    //   newErrors.address_line_two = "Address line 2 must not exceed 30 words";
    // }

    if (!formData.profile_type) newErrors.profile_type = "Service is required";
    if (!formData.residency_status)
      newErrors.residency_status = "Residency status is required";

    if (!formData.state) newErrors.state = "State is required";
    if (!formData.address_suburb)
      newErrors.address_suburb = "Suburb is required";

    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData?.email && !emailRegex?.test(formData?.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!formData.profile_img && !formData.profile_img_)
      newErrors.profile_img = "Profile picture is required";

    // setErrors(newErrors);

    // // const firstErrorField = Object.keys(newErrors)[0];

    // // // Focus on the first error field, whether it's a regular input or react-select
    // // if (firstErrorField && fieldRefs.current[firstErrorField]) {
    // //   fieldRefs.current[firstErrorField].scrollIntoView({ behavior: "smooth" });
    // //   fieldRefs.current[firstErrorField].focus();
    // // } else if (firstErrorField && selectRefs.current[firstErrorField]) {
    // //   selectRefs.current[firstErrorField].focus();
    // // }

    return newErrors;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    ProfileSubmitHandler(formData, setStep, UserListRefetch, setIsSubmitting);
  };
  const [isCamera, setIsCamera] = useState(false);

  const handleFileClick = (option: "camera" | "gallery") => {
    const fileInput = document.getElementById(
      "profileImage"
    ) as HTMLInputElement;

    if (option === "camera") {
      setIsCamera(true);
      fileInput.setAttribute("capture", "user"); // Open camera for selfie
    } else {
      setIsCamera(false);
      fileInput.removeAttribute("capture"); // Remove camera access for gallery
    }

    fileInput.click(); // Programmatically click the file input
  };

  return (
    <div>
      <style>{`
        .parent {
          width: auto;
          margin: auto;
          padding: 2rem;
          background: #ffffff;
          border-radius: 25px;
          box-shadow: 7px 20px 20px rgb(210, 227, 244);
        }

        .file-upload {
          text-align: center;
          border: 3px dashed rgb(210, 227, 244);
          padding: 1.5rem;
          position: relative;
          cursor: pointer;
        }

        .file-upload p {
          font-size: 0.87rem;
          margin-top: 10px;
          color: #bbcada;
        }

        .file-upload input {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
          /* Default to showing web text and hide mobile text */
.web-text {
  display: block;
}
.mobile-text {
  display: none;
}

/* On mobile devices, show the mobile text and hide the web text */
@media only screen and (max-width: 768px) {
  .web-text {
    display: none;
  }
  .mobile-text {
    display: block;
  }
}

      `}</style>

      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Which service do you offer?</Label>
          <Select
            // ref={(el: any) => {
            //   selectRefs.current.profile_type = el;

            // }}
            className="select-wrap"
            classNamePrefix="select-box"
            options={profiledata}
            value={profiledata?.find(
              (option: any) => option.value === formData.profile_type
            )}
            onChange={(selectedOption: any) => {
              setFormData({ ...formData, profile_type: selectedOption?.value });
              setErrors((prevErrors: any) => ({
                ...prevErrors,
                profile_type: "",
              }));
            }}
            id="profile_type"
            name="profile_type"
            isClearable={true}
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "35px", // Custom height
                borderColor: errors.profile_type ? "red" : base.borderColor, // Show red border on error
                "&:hover": {
                  borderColor: errors.profile_type ? "red" : base.borderColor,
                },
              }),
            }}
          />
          {errors.profile_type && (
            <div className="text-danger">{errors.profile_type}</div>
          )}
        </FormGroup>

        <FormGroup>
          <Label>First Name</Label>
          <input
            // ref={(el: any) => {
            //   fieldRefs.current.first_name = el;
            // }}
            type="text"
            name="first_name"
            className={`form-control ${
              errors.first_name ? "is-invalid" : "is-valid"
            }`}
            value={formData.first_name}
            onChange={handleChange}
          />
          {errors.first_name && (
            <div className="text-danger">{errors.first_name}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Last Name</Label>
          <input
            // ref={(el: any) => (fieldRefs.current.last_name = el)}
            type="text"
            name="last_name"
            className={`form-control ${
              errors.last_name ? "is-invalid" : "is-valid"
            }`}
            value={formData.last_name}
            onChange={handleChange}
          />
          {errors.last_name && (
            <div className="text-danger">{errors.last_name}</div>
          )}
        </FormGroup>

        <FormGroup>
          <Label>Phone Number</Label>
          {/* <input
            // ref={(el: any) => (fieldRefs.current.mobile = el)}
            type="text"
            name="mobile"
            className={`form-control ${
              errors.mobile ? "is-invalid" : "is-valid"
            }`}
            value={formData.mobile}
            onChange={handleChange}
            disabled
          /> */}
          <PhoneInput
            country={"au"}
            value={formData.mobile}
            onChange={(phone) => {
              handleChange("+" + phone, "mobile");
            }}
            onlyCountries={["au"]}
            inputClass={` form-control ${
              errors.mobile ? "is-invalid" : "is-valid"
            }`}
            disabled
            disableDropdown
            countryCodeEditable={false}
          />
          {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
        </FormGroup>

        <FormGroup>
          <Label>Email</Label>
          <input
            type="text"
            name="email"
            className={`form-control ${
              errors.email ? "is-invalid" : "is-valid"
            }`}
            value={formData.email}
            onChange={handleChange}
            disabled
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
        </FormGroup>
        <Card>
          <CardBody>
            <FormGroup>
              <Label>Address</Label>

              <hr />
              <FormGroup>
                <Label>Street Number</Label>
                <input
                  // ref={(el: any) => (fieldRefs.current.street_number = el)}
                  type="text"
                  name="street_number"
                  className={`form-control ${
                    errors.street_number ? "is-invalid" : "is-valid"
                  }`}
                  value={formData?.street_number}
                  onChange={handleChange}
                  // style={{ height: "150px" }}
                />
                {errors.street_number && (
                  <div className="text-danger">{errors.street_number}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Address Line - 1</Label>
                <input
                  // ref={(el: any) => (fieldRefs.current.address_line_one = el)}
                  type="text"
                  name="address_line_one"
                  className={`form-control ${
                    errors.address_line_one ? "is-invalid" : "is-valid"
                  }`}
                  value={formData?.address_line_one}
                  onChange={handleChange}
                  // style={{ height: "150px" }}
                />
                {errors.address_line_one && (
                  <div className="text-danger">{errors.address_line_one}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Address Line - 2</Label>
                <input
                  // ref={(el: any) => (fieldRefs.current.address_line_two = el)}
                  type="text"
                  name="address_line_two"
                  className={`form-control ${
                    errors.address_line_two ? "is-invalid" : "is-valid"
                  }`}
                  value={formData?.address_line_two}
                  onChange={handleChange}
                  // style={{ height: "150px" }}
                />
                {errors.address_line_two && (
                  <div className="text-danger">{errors.address_line_two}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>State</Label>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "35px", // Custom height
                      borderColor: errors.state ? "red" : base.borderColor, // Show red border on error
                      "&:hover": {
                        borderColor: errors.state ? "red" : base.borderColor,
                      },
                    }),
                  }}
                  options={state_aust}
                  value={state_aust?.find(
                    (option: any) => option.value === formData.state
                  )}
                  // ref_data={(el:any) => (fieldRefs.current.state = el)}
                  onChange={(selectedOption: any) => {
                    setFormData({
                      ...formData,
                      state: selectedOption?.value,
                      address_suburb: null,
                    });
                    setSearch("");
                    setErrors((prevErrors: any) => ({
                      ...prevErrors,
                      state: "",
                    }));
                  }}
                  isClearable={true}
                  // ref={(el: any) => {
                  //   selectRefs.current.state = el;

                  // }}
                  name="state"
                  // isMulti={true}
                />
                {errors.state && (
                  <div className="text-danger">{errors.state}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Suburb</Label>
                <Select
                  options={surub_data ? surub_data : []}
                  value={
                    formData?.address_suburb && surub_data
                      ? surub_data?.find(
                          (option: any) =>
                            option.value === formData?.address_suburb
                        )
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    setFormData({
                      ...formData,
                      address_suburb: selectedOption?.value,
                    });
                    setErrors((prevErrors: any) => ({
                      ...prevErrors,
                      address_suburb: "",
                    }));
                  }}
                  onInputChange={(e: any) => {
                    setSearch(e);
                  }}
                  isClearable={true}
                  // isMulti={true}
                  // ref={(el: any) => {
                  //   selectRefs.current.suburb = el;

                  // }}
                  name="address_suburb"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "35px", // Custom height
                      borderColor: errors.address_suburb
                        ? "red"
                        : base.borderColor, // Show red border on error
                      "&:hover": {
                        borderColor: errors.address_suburb
                          ? "red"
                          : base.borderColor,
                      },
                    }),
                  }}
                />
                {errors.address_suburb && (
                  <div className="text-danger">{errors.address_suburb}</div>
                )}
              </FormGroup>
            </FormGroup>
          </CardBody>
        </Card>

        <FormGroup className="mt-3">
          <Label>Gender</Label>
          <Row>
            {GENDER?.map((item: any, index: number) => (
              <div className="col-1" key={index}>
                <input
                  type="radio"
                  name="gender"
                  value={item.id}
                  checked={formData.gender === item.id.toString()}
                  onChange={handleChange}
                />
                &nbsp;
                <Label className="form-check-label">{item.name}</Label>
              </div>
            ))}
          </Row>
          {errors.gender && <div className="text-danger">{errors.gender}</div>}
        </FormGroup>

        <FormGroup>
          <Label>What is your residency status?</Label>
          <Select
            options={Residancydata}
            value={Residancydata?.find(
              (option: any) => option.value === formData.residency_status
            )}
            onChange={(selectedOption: any) => {
              setFormData({
                ...formData,
                residency_status: selectedOption?.value,
              });
              setErrors((prevErrors: any) => ({
                ...prevErrors,
                residency_status: "",
              }));
            }}
            //  id="residency_status"
            name="residency_status"
            isClearable={true}
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "35px", // Custom height
                borderColor: errors.residency_status ? "red" : base.borderColor, // Show red border on error
                "&:hover": {
                  borderColor: errors.residency_status
                    ? "red"
                    : base.borderColor,
                },
              }),
            }}
            // ref={(el: any) => {
            //   selectRefs.current.residency_status = el;

            // }}
          />
          {errors.residency_status && (
            <div className="text-danger">{errors.residency_status}</div>
          )}
        </FormGroup>

        <FormGroup>
          <Label>Date of Birth</Label>
          <DatePickerReact
            selected={formData.dob ? new Date(formData.dob) : null}
            onChange={(date: any) => handleChange(date, "dob")}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            placeholderText="DD/MM/YYYY"
            className={`form-control ${errors.dob ? "is-invalid" : "is-valid"}`}
            showIcon
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            }
            minDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 100))
            }
            holidays={holiday}
          />
          {errors.dob && <div className="text-danger">{errors.dob}</div>}
        </FormGroup>
        <FormGroup>
          <Label>Profile Picture</Label>
          <div className="file-upload">
            {profileImgPreview || formData.profile_img_ ? (
              <>
                <img
                  src={profileImgPreview || formData.profile_img_}
                  alt="Profile Preview"
                  width={150}
                  height={150}
                />

                <p className="mobile-text">
                  Click here to change image (
                  <span onClick={() => handleFileClick("camera")}>
                    <u>take selfie</u>
                  </span>{" "}
                  or{" "}
                  <span onClick={() => handleFileClick("gallery")}>
                    <u> choose from gallery</u>
                  </span>
                  )
                </p>
                <p
                  className="web-text"
                  onClick={() => handleFileClick("gallery")}
                >
                  Click here to change image
                </p>
              </>
            ) : (
              <>
                <p className="mobile-text">
                  Click here to upload image (
                  <span onClick={() => handleFileClick("camera")}>
                    <u> take selfie</u>
                  </span>{" "}
                  or{" "}
                  <span onClick={() => handleFileClick("gallery")}>
                    <u> choose from gallery</u>
                  </span>
                  )
                </p>
                <p
                  className="web-text"
                  onClick={() => handleFileClick("gallery")}
                >
                  Click here to upload image
                </p>
              </>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              id="profileImage"
              style={{ display: "none" }} // Hide the input
            />
          </div>
          {errors.profile_img && (
            <div className="text-danger">{errors.profile_img}</div>
          )}
        </FormGroup>
        {Object.keys(errors)?.length === 1 ? (
          <>
            {" "}
            <div>
              <Alert color="danger" className="mt-30" id="g">
                Please make sure all fields are filled correctly.
              </Alert>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="text-center d-float-center justify-content-center gap-15">
          <Button
            className="btn btn-brand-1"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting && <Spinner size="sm"></Spinner>}
            Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Step1;
