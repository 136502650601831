import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
export default function Rates({ handleChange, values, errors_ }: any) {
  return (
    <>
      <div className="mb-3">
        <Label>Weekdays(Rates/Hrs)</Label>
        <Row>
          <Col lg="12">
            <div className="mb-2">
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input
                  id={`weekday_rate`}
                  name={`weekday_rate`}
                  type="text"
                  className={`form-control ${
                    errors_?.weekday_rate ? "is-invalid" : "is-valid"
                  }`}
                  onChange={(e) => handleChange(e)}
                  value={values?.weekday_rate}
                />
              </InputGroup>

              <div className="text-danger">{errors_?.weekday_rate}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Label>Saturdays(Rates/Hrs)</Label>
        <Row>
          <Col lg="12">
            <div className="mb-2">
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input
                  id={`saturday_rate`}
                  name={`saturday_rate`}
                  type="text"
                  className={`form-control ${
                    errors_?.saturday_rate ? "is-invalid" : "is-valid"
                  }`}
                  onChange={(e) => handleChange(e)}
                  value={values?.saturday_rate}
                />
              </InputGroup>

              <div className="text-danger">{errors_?.saturday_rate}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Label>Sundays(Rates/Hrs)</Label>
        <Row>
          <Col lg="12">
            <div className="mb-2">
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input
                  id={`sunday_rate`}
                  name={`sunday_rate`}
                  type="text"
                  className={`form-control ${
                    errors_?.sunday_rate ? "is-invalid" : "is-valid"
                  }`}
                  onChange={(e) => handleChange(e)}
                  value={values?.sunday_rate}
                />
              </InputGroup>

              <div className="text-danger">{errors_?.sunday_rate}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Label>Public Holidays Charges(Rates/Hrs)</Label>
        <Row>
          <Col lg="12">
            <div className="mb-2">
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input
                  id={`public_holiday_rate`}
                  name={`public_holiday_rate`}
                  type="text"
                  className={`form-control ${
                    errors_?.public_holiday_rate ? "is-invalid" : "is-valid"
                  }`}
                  onChange={(e) => handleChange(e)}
                  value={values?.public_holiday_rate}
                />
              </InputGroup>

              <div className="text-danger">{errors_?.public_holiday_rate}</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
