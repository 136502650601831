import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray, getIn } from "formik";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import Questen from "./SubSections/Step5/Questen";

import Rates from "./SubSections/Step5/Rates";
import DocumentUpload from "./SubSections/Step3/DocumentUpload";
import { api } from "../../../Api/Api";
import { ProfileSubmitHandler5 } from "../ProfileMethods/ProfileSubmitHandlers";

const Step5 = ({ setStep, Userdata, UserListRefetch }: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState<any>({
    step: 5,
    extra_charge_after_hours: false,
    saturday_rate: "",
    weekday_rate: "",
    public_holiday_rate: "",
    sunday_rate: "",

    abn: "",
    bank_name: "",
    bank_branch_name: "",
    bank_account_number: "",
    company_name: "",
    valid_license: false,
    comprehensive_car_insurance: false,
    third_party_insurance: false,
    cover_Kms_per_day: "",
    drive_overnight: false,
    charged_at_AUD: "",
    iam_willing_to_drive: false,
    driving_license_expire: "",
    driving_license_img: "",
    driving_license_img_: "",
    driving_license_id: "",
    vehicle_insurance_img: "",
    vehicle_insurance_img_: "",
    vehicle_insurance_id: "",
    vehicle_insurance_expire: "",
  });
  const [errors_, setErrors] = useState<any>({});
  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    api
      .get("/user/user_details", true)
      .then(([status, response]) => {
        const documentData = response?.data?.user_details?.documents;
        const UserData = response?.data;
        const medicardwcarray = documentData?.filter(
          (doc: any) => doc.documentName === "driving_license_id"
        );
        const medicardwcarray_ = documentData?.filter(
          (doc: any) => doc.documentName === "vehicle_insurance_id"
        );
        setValues({
          ...values,
          saturday_rate: UserData?.user_details?.caregiver_charges?.[1]?.rate,
          weekday_rate: UserData?.user_details?.caregiver_charges?.[3]?.rate,
          public_holiday_rate:
            UserData?.user_details?.caregiver_charges?.[2]?.rate,
          sunday_rate: UserData?.user_details?.caregiver_charges?.[0]?.rate,
          extra_charge_after_hours:
            UserData?.user_details?.extraChargeAfterHours === 1 ? true : false,

          abn: UserData?.user_details?.abn,
          bank_name: UserData?.user_details?.bankName,
          bank_branch_name: UserData?.user_details?.bankBranchName,
          bank_account_number: UserData?.user_details?.bankAccountNumber,
          company_name: UserData?.user_details?.companyName,
          valid_license:
            UserData?.user_details?.validLicense === 1
              ? "true"
              : UserData?.user_details?.validLicense === 0
              ? "false"
              : "",
          comprehensive_car_insurance:
            UserData?.user_details?.comprehensiveCarInsurance === 1
              ? "true"
              : UserData?.user_details?.comprehensiveCarInsurance === 0
              ? "false"
              : "",
          third_party_insurance:
            UserData?.user_details?.thirdPartyInsurance === 1
              ? "true"
              : UserData?.user_details?.thirdPartyInsurance === 0
              ? "false"
              : "",
          cover_Kms_per_day: UserData?.user_details?.coverKmsPerDay,
          drive_overnight:
            UserData?.user_details?.driveOvernight === 1
              ? "true"
              : UserData?.user_details?.driveOvernight === 0
              ? "false"
              : "",
          charged_at_AUD: UserData?.user_details?.chargedAtAud,
          iam_willing_to_drive:
            UserData?.user_details?.validLicense === 1
              ? "true"
              : UserData?.user_details?.validLicense === 0
              ? "false"
              : "",
          driving_license_expire: UserData?.user_details?.drivingLicenseExpire,
          driving_license_img: medicardwcarray?.[0],
          driving_license_img_: medicardwcarray?.[0],
          driving_license_id: UserData?.user_details?.drivingLicenseId,
          vehicle_insurance_img: medicardwcarray_?.[0],
          vehicle_insurance_img_: medicardwcarray_?.[0],
          vehicle_insurance_id: UserData?.user_details?.vehicleInsuranceId,
          vehicle_insurance_expire:
            UserData?.user_details?.vehicleInsuranceExpire,
        });
      })
      .catch((err) => {});
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    namez?: any
  ) => {
    if (
      namez === "vehicle_insurance_expire" ||
      namez === "driving_license_expire"
    ) {
      setValues({ ...values, [namez]: e });
      setErrors((prevErrors: any) => ({ ...prevErrors, [namez]: undefined }));
    } else {
      const { name, value, type, checked } = e.target;
      setValues({ ...values, [name]: type === "checkbox" ? checked : value });
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: undefined }));
    }
  };
  const allowedFileTypes = ["png", "jpg", "jpeg", "gif", "pdf", "jif"];
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

  const validateFiles = (file: any): { isValid: boolean; errors: string[] } => {
    if (!file) return { isValid: false, errors: ["No file selected"] };

    const { isValid, errors } = validateFile(file);

    return {
      isValid,
      errors,
    };
  };
  const validateFileType = (file: any): boolean => {
    const type = file?.documentType ? file?.documentType : file?.type;
    return type
      ? allowedFileTypes.includes(type.split("/").pop()?.toLowerCase())
      : false;
  };

  const validateFile = (file: any): { isValid: boolean; errors: string[] } => {
    console.log(file, "filefilefile");
    const errors: string[] = [];

    if (!validateFileType(file)) {
      errors.push(
        "Invalid file type. Only PDF, JPG, JPEG, PNG, GIF, and JIF are allowed."
      );
    }

    // if (!validateFileSize(file)) {
    //   errors.push("File size exceeds 2 MB.");
    // }

    return {
      isValid: errors.length === 0,
      errors,
    };
  };
  const handleMultipleImagesChange = (event: any, fieldName: string) => {
    const file = event.target.files?.[0]; // Get the first file
    const { isValid, errors } = validateFile(file);
    const borderColor = isValid ? "green" : "red";
    if (file) {
      const updatedImage = {
        file,
        preview: URL.createObjectURL(file),
        documentType: file.type,
        borderColor: borderColor,
        errors: errors,
        isValid: isValid,
      };

      setValues((prevFormData: any) => ({
        ...prevFormData,
        [fieldName]: updatedImage, // Store only a single file object
      }));
      setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName]: "" }));
    }
  };
  const validateForm = () => {
    let errors: any = {};

    if (!values.iam_willing_to_drive) {
      errors.iam_willing_to_drive =
        "Willing driving assistance field is required";
    }

    if (values.iam_willing_to_drive) {
      if (!values.valid_license) {
        errors.valid_license = "Valid license field is required";
      }
      if (!values.comprehensive_car_insurance) {
        errors.comprehensive_car_insurance =
          "Comprehensive car insurance field is required";
      }
      if (!values.third_party_insurance) {
        errors.third_party_insurance =
          "Third party insurance field is required";
      }
      if (!values.cover_Kms_per_day) {
        errors.cover_Kms_per_day = "Cover Kms per day field is required";
      } else {
        if (
          values.cover_Kms_per_day &&
          !/^\d+(\.\d{1,2})?$/.test(values.cover_Kms_per_day)
        ) {
          errors.cover_Kms_per_day =
            "Must be a valid number with up to 2 decimal places";
        }
      }
      if (!values.drive_overnight) {
        errors.drive_overnight = "Drive overnight field is required";
      }
      if (!values.charged_at_AUD) {
        errors.charged_at_AUD = "Charged at each KM field is required";
      } else {
        if (
          values.charged_at_AUD &&
          !/^\d+(\.\d{1,2})?$/.test(values.charged_at_AUD)
        ) {
          errors.charged_at_AUD =
            "Must be a valid number with up to 2 decimal places";
        }
      }
    }

    if (values.valid_license) {
      if (
        !values.driving_license_expire ||
        new Date(values.driving_license_expire) < new Date()
      ) {
        errors.driving_license_expire =
          "Driving license expiry date cannot be in the past";
      }
      if (!values.driving_license_img && !values.driving_license_img_) {
        errors.driving_license_img = "Driving license Document is required";
      }
      if (!values.driving_license_id) {
        errors.driving_license_id = "Driving license ID is required";
      }
    }

    if (values.comprehensive_car_insurance) {
      if (
        !values.vehicle_insurance_expire ||
        new Date(values.vehicle_insurance_expire) < new Date()
      ) {
        errors.vehicle_insurance_expire =
          "Vehicle insurance expiry date cannot be in the past";
      }
      if (!values.vehicle_insurance_img && !values.vehicle_insurance_img_) {
        errors.vehicle_insurance_img = "Vehicle insurance Document is required";
      }
      if (!values.vehicle_insurance_id) {
        errors.vehicle_insurance_id = "Vehicle insurance ID field is required";
      }
    }

    if (!values.abn || !/^\d{11}$/.test(values.abn)) {
      errors.abn = "ABN must be exactly 11 digits";
    }

    if (!values.bank_name) {
      errors.bank_name = "Bank name is required";
    }

    if (!values.company_name) {
      errors.company_name = "Company name is required";
    }

    if (
      !values.bank_account_number ||
      !/^\d{6}$/.test(values.bank_account_number)
    ) {
      errors.bank_account_number =
        "Bank account number must be exactly 6 digits";
    }

    if (
      !values.bank_branch_name ||
      !/^\d{3}-\d{3}$/.test(values.bank_branch_name)
    ) {
      errors.bank_branch_name = "BSB must be in the format XXX-XXX";
    }

    if (!values?.sunday_rate) {
      errors.sunday_rate = "Sunday rate is required";
    } else {
      if (!/^\d+(\.\d{1,2})?$/.test(values.saturday_rate)) {
        errors.saturday_rate =
          "Must be a valid number with up to 2 decimal places";
      }
    }
    if (!values?.saturday_rate) {
      errors.saturday_rate = "Saturday rate is required";
    } else {
      if (!/^\d+(\.\d{1,2})?$/.test(values.saturday_rate)) {
        errors.saturday_rate =
          "Must be a valid number with up to 2 decimal places";
      }
    }
    if (!values?.public_holiday_rate) {
      errors.public_holiday_rate = "Public holiday rate rate is required";
    } else {
      if (!/^\d+(\.\d{1,2})?$/.test(values.saturday_rate)) {
        errors.saturday_rate =
          "Must be a valid number with up to 2 decimal places";
      }
    }
    if (!values?.weekday_rate) {
      errors.weekday_rate = "Weekday rate rate is required";
    } else {
      if (!/^\d+(\.\d{1,2})?$/.test(values.saturday_rate)) {
        errors.saturday_rate =
          "Must be a valid number with up to 2 decimal places";
      }
    }

    setErrors(errors);
    return errors;
  };
  console.log(errors_, "xhasxagxfagsf");
  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    namez?: string
  ) => {
    if (
      namez === "vehicle_insurance_expire" ||
      namez === "driving_license_expire"
    ) {
      const errors = validateForm(); // Run validation
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [namez]: errors[namez] || "", // Update only the specific field's error
      }));
    } else if (
      namez === "vehicle_insurance_img" ||
      namez === "driving_license_img"
    ) {
    } else {
      const { name } = e.target;
      const errors = validateForm(); // Run validation

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the specific field's error
      }));
    }
  };
  const removeImage = (name: any) => {
    setValues((prevFormData: any) => ({
      ...prevFormData,
      [name]: null, // Reset the field
    }));
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (Object.keys(validateForm()).length === 0) {
      // Proceed with submission
      ProfileSubmitHandler5(values, setIsSubmitting, setStep, UserListRefetch);
    }
  };
  console.log(values, "anu c");
  return (
    <>
      <style>{`
        .parent {
          width: auto;
          margin: auto;
          padding: 2rem;
          background: #ffffff;
          border-radius: 25px;
          box-shadow: 7px 20px 20px rgb(210, 227, 244);
        }

        .file-upload {
          text-align: center;
          border: 3px dashed rgb(210, 227, 244);
          padding: 1.5rem;
          position: relative;
          cursor: pointer;
          float: left;
          width: 100%;
        }

        .file-upload p {
          font-size: 0.87rem;
          margin-top: 10px;
          color: #bbcada;
        }

        .file-upload input {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
        .file-upload span.badge {
          font-size: 0px;
          padding: 2px;
          margin-left: 5px;
          right: 5px;
          top: 5px;
        }
          .file-upload img {
            margin: 0 5px;
            border-radius: 5px;
          }
      `}</style>
      <form onSubmit={handleSubmit}>
        <div className="text-center mb-3 logo-div01">
          <h3 className="block-subTitle text-center uss-text-info">
            {/* <span className="uss-text-primary">Create</span> Account */}
          </h3>
        </div>
        <Card>
          <CardBody>
            <Rates
              handleChange={handleChange}
              values={values}
              errors_={errors_}
            />
          </CardBody>
        </Card>
        <Card className="mb-3 px-3 mt-4">
          <h6 className="mt-2">Ability to Drive</h6>
          <hr />
          <CardBody>
            <Questen
              values={values}
              feildName="iam_willing_to_drive"
              label=" Are you willing to provide driving assistance?"
              errors_={errors_}
              handleChange={handleChange}
            />
            <Row>
              <Questen
                values={values}
                feildName="valid_license"
                label=" 1. Do you have a valid license?"
                errors_={errors_}
                handleChange={handleChange}
              />

              <Questen
                values={values}
                feildName="comprehensive_car_insurance"
                label="2. Do you have a comprehensive car insurance?"
                errors_={errors_}
                handleChange={handleChange}
              />
            </Row>
            <Row>
              <Questen
                values={values}
                feildName="third_party_insurance"
                label=" 3. Do you have a third party insurance?"
                errors_={errors_}
                handleChange={handleChange}
              />
              <Questen
                values={values}
                feildName="drive_overnight"
                label="4. I can drive overnight?"
                errors_={errors_}
                handleChange={handleChange}
              />
            </Row>
            <Row className="mt-2">
              <Col className="col-12">
                <FormGroup>
                  <Label>
                    5. I can cover{" "}
                    <span style={{ textDecoration: "underline" }}>
                      <Input
                        name="cover_Kms_per_day" // Ensure this matches Formik's field name
                        type="text"
                        placeholder="______________"
                        className={`form-control border-0 border-bottom w-auto d-inline text-center ${
                          errors_.cover_Kms_per_day ? "is-invalid" : "is-valid"
                        }`}
                        style={{
                          background: "transparent",
                          outline: "none",
                        }}
                        onChange={handleChange}
                        value={values.cover_Kms_per_day}
                      />{" "}
                    </span>
                    Kms per day
                  </Label>

                  <div className="text-danger">{errors_.cover_Kms_per_day}</div>
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label>
                    6. Each KM will be charged at{" "}
                    <span style={{ textDecoration: "underline" }}>
                      {" "}
                      <Input
                        name="charged_at_AUD" // Ensure this matches Formik's field name
                        type="text"
                        placeholder="______________"
                        className="form-control border-0 border-bottom w-auto d-inline text-center"
                        style={{
                          background: "transparent",
                          outline: "none",
                        }}
                        onChange={handleChange}
                        value={values.charged_at_AUD}
                      />
                    </span>{" "}
                    AUD in addition to the hourly charges
                  </Label>
                  <div className="text-danger">{errors_.charged_at_AUD}</div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <DocumentUpload
          formData={values}
          handleChange={handleChange}
          errors={errors_}
          handleBlur={handleBlur}
          label="Vehicle Insurance"
          document_id="vehicle_insurance_id"
          document_img="vehicle_insurance_img"
          document_date="vehicle_insurance_expire"
          handleMultipleImagesChange={handleMultipleImagesChange}
          removeImage={removeImage}
        />

        <DocumentUpload
          formData={values}
          handleChange={handleChange}
          errors={errors_}
          handleBlur={handleBlur}
          label="Driving license"
          document_id="driving_license_id"
          document_img="driving_license_img"
          document_date="driving_license_expire"
          handleMultipleImagesChange={handleMultipleImagesChange}
          removeImage={removeImage}
        />
        </Card>
      
        <Card>
          <CardBody>
            <FormGroup className="mt-5">
              <label className="mt-3" htmlFor="bank_name">
                ABN
              </label>
              <Input
                type="text"
                name="abn"
                className={`form-control ${
                  errors_.abn ? "is-invalid" : "is-valid"
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.abn}
              />
              <div className="text-danger">{errors_.abn}</div>
            </FormGroup>
            <FormGroup className="mt-5">
              <label className="mt-3" htmlFor="company_name">
                Company Name
              </label>
              <Input
                type="text"
                name="company_name"
                className={`form-control ${
                  errors_.company_name ? "is-invalid" : ""
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company_name}
              />
              <div className="text-danger">{errors_.company_name}</div>
            </FormGroup>
            <FormGroup>
              <label htmlFor="bank_name">Bank Name</label>
              <Input
                type="text"
                name="bank_name"
                className={`form-control ${
                  errors_.bank_name ? "is-invalid" : "is-valid"
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bank_name}
              />
              <div className="text-danger">{errors_.bank_name}</div>
            </FormGroup>

            <FormGroup>
              {" "}
              <label htmlFor="bank_branch_name">BSB</label>
              <input
                type="text"
                name="bank_branch_name"
                className={`form-control ${
                  errors_.bank_branch_name ? "is-invalid" : "is-valid"
                }`}
                onChange={(e: any) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-digits
                  if (value.length > 3) {
                    value = `${value.slice(0, 3)}-${value.slice(3, 6)}`; // Add the hyphen after the first 3 digits
                  }
                  handleChange(e); // Use Formik's setFieldValue to update the field
                }}
                onBlur={handleBlur}
                value={values.bank_branch_name}
              />
              <div className="text-danger">{errors_.bank_branch_name}</div>
            </FormGroup>
            <FormGroup>
              <label htmlFor="bank_account_number">Bank Account Number</label>
              <input
                type="text"
                name="bank_account_number"
                className={`form-control ${
                  errors_.bank_account_number ? "is-invalid" : "is-valid"
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bank_account_number}
              />
              <div className="text-danger">{errors_.bank_account_number}</div>
            </FormGroup>
          </CardBody>
        </Card>
        {Object.keys(errors_).length === 1 ? (
          <>
            {" "}
            <div>
              <Alert color="danger" className="mt-30" id="g">
                Please make sure all fields are filled correctly.
              </Alert>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="mt-5"></div>
        <div className="text-center d-float-center justify-content-center gap-15 mt-3">
          <Button
            className="btn btn-brand-2"
            type="submit"
            onClick={() => [setStep(4)]}
          >
            <i className="ri-arrow-left-line"></i>&nbsp;BACK
          </Button>
          <Button
            className="btn btn-brand-1"
            type="submit"
            disabled={isSubmitting}
          >
            {/* {isSubmitting && <Spinner size="sm"></Spinner>} */}
            Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
          </Button>
        </div>
      </form>
    </>
  );
};
export default Step5;
