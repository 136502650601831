import * as Yup from "yup";

// validation schema

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(
      /^[a-zA-Z\s.]+$/,
      "First name can only contain letters, spaces, and periods"
    )
    .test("min-words", "First name must contain at least 1 word", (value) => {
      if (!value) return false; // Invalid if value is undefined, null, or empty
      return value.split(/\s+/).filter(Boolean).length >= 1; // Check for at least 1 word
    })
    .test(
      "max-words",
      "First name must not contain more than 5 words",
      (value) => {
        if (!value) return true; // Skip validation if value is undefined or null
        return value.split(/\s+/).filter(Boolean).length <= 5; // Count words and check
      }
    )
    .required("First name is required"),
  last_name: Yup.string()
    .matches(
      /^[a-zA-Z\s.]+$/,
      "Last name can only contain letters, spaces, and periods"
    )
    .test("min-words", "Last name must contain at least 1 word", (value) => {
      if (!value) return false; // Invalid if value is undefined, null, or empty
      return value.split(/\s+/).filter(Boolean).length >= 1; // Check for at least 1 word
    })
    .test(
      "max-words",
      "Last name must not contain more than 5 words",
      (value) => {
        if (!value) return true; // Skip validation if value is undefined or null
        return value.split(/\s+/).filter(Boolean).length <= 5; // Count words and check
      }
    )
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email must be 100 characters or less")
    .required("Email is required"),
    mobile: Yup.string()
    .matches(
      /^\+614\d{8}$/,
      "Mobile number must be in the format +614XXXXXXXX (e.g., +61412345678)"
    )
    .required("Mobile number is required"),

  agree_to_term_and_privacy: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms of Service and Privacy Policy"
  ),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(100, "Password must be 100 characters or less")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Password doesn't match with confirm password "
    )
    .required("Confirm password is required"),
});

export const OtpValidationSchema = Yup.object().shape({
  otp: Yup.array()
    .required("OTP is required")
    .length(4, "Please enter OTP")
    .test(
      "validate-digits",
      "Each OTP field must contain exactly 1 digit",
      function (value) {
        if (value && value.length === 4) {
          return value.every((val) => /^[0-9]$/.test(val));
        }
        return true; // Skip individual validation if length is not 5
      }
    ),
});

// initial values
export const initializeRegisterInitialValues = {
  email: "",
  agree_to_term_and_privacy: false,
  mobile: "",
  first_name: "",
  password: "",
  confirm_password: "",
  last_name: "",
};
