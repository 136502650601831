import { useState } from "react";
import { Button } from "reactstrap";
import ModalContainers from "../../../../../components/Modal/ModalContainers";
import ConformModal from "./ConfirmModal";
import constants from "../../../../../Utils/Constants/Constants";
import { useNavigate } from "react-router-dom";

export default function SuccessModal({
  isClose,
  isClose_1,
  isClose_2,
  value,
  caregiver,
  all_data,
}: any) {
  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="slect-time-slots">
        <div className="modal-header">
          <h4></h4>
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            // aria-label="Close"
            onClick={() => {
              if (value === 2) {
                window.location.reload();
              } else {
                isClose();
              }
            }}
          >
            <span aria-hidden="true">
              <i className="ri-close-fill"></i>
            </span>
          </button>
        </div>
        <div className="modal-body p-4">
          {value === 2 ? (
            <div className="row">
              <div className="col-md-12">
                <div className="text-center pt-3">
                  <h5 className="mb-4" style={{ fontSize: 50 }}>
                    <img  src={`${constants.IMAGE_PATH.IMAG_PATH}hand-shake.gif`}/>
                  </h5>
                </div>
              </div>
              <div className="col-md-12">
                <div className="success-message text-center">
                  <h5>{all_data?.booking?.bookingId}</h5>
                  <h4 className="show-title mb-3">Booking Request Successful.</h4>
                  <p className="show-date-time date">
                    You will receive a notification when the care giver approve
                    this request.
                  </p>
                  <p className="show-date-time time">
                    Make payment to confirm the booking.
                  </p>
                  {/* <p className="show-content hour-rate">
                  <span className="color-brand-2">$100.00</span> is Charged
                </p> */}
                </div>
              </div>
              <div className="col-md-12 mt-2 mb-3 text-center">
                {/* <button type="button" className="btn btn-default mb-2">OK</button> */}
                <hr />
              </div>
              <div className="col-md-12">
                <div className="success-message text-center">
                  {/* <h4 className="show-title mb-3">Easly handle this booking.</h4> */}
                  <p className="show-date-time date">
                    To manage your booking details easly, please to download
                    care seeker app.
                  </p>
                </div>
              </div>
              <div className="col-md-12 download-app-popup">
                <div className="row">
                  <div className="col-md-6 col-6 app-scanner-box app-left">
                    <div className="qr-box">
                      <a href="#">
                        <img
                          className="qr-img"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                          alt="QR Code"
                        />
                        <p className="get-app-text">
                          <img
                            className="qr-img"
                            src={`${constants.IMAGE_PATH.IMAG_PATH}template/app-store-btn.png`}
                            alt="QR Code"
                          />
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 app-scanner-box app-right">
                    <div className="qr-box">
                      <a href="#">
                        <img
                          className="qr-img"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                          alt="QR Code"
                        />
                        <p className="get-app-text">
                          <img
                            className="qr-img"
                            src={`${constants.IMAGE_PATH.IMAG_PATH}template/android-btn.png`}
                            alt="QR Code"
                          />
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row new-user-booking">
              <div className="col-md-12">
                <div className="text-center pt-3">
                  <h5 className="mb-4" style={{ fontSize: 50 }}>
                    <i className="ri-error-warning-fill text-warning" />
                  </h5>
                </div>
              </div>
              <div className="col-md-12">
                <div className="success-message text-center">
                  <h4 className="show-title mb-3">Sorry !</h4>
                  <p className="show-date-time date">
                    We could not find your email address on our record.
                  </p>
                  <p className="show-date-time time">
                    If you are a new user click below Register button.
                  </p>
                  {/* <p className="show-content hour-rate">
                  <span className="color-brand-2">$100.00</span> is Charged
                </p> */}
                </div>
              </div>
              <div className="col-md-12 mt-2 mb-3 text-center">
                <button
                  type="button"
                  className="btn btn-default mb-2"
                  onClick={() => navigate("/register")}
                >
                  Register
                </button>
                <hr />
                <h4 className="show-title mb-0">OR</h4>
                {/* <hr /> */}
              </div>
              <div className="col-md-12">
                <div className="success-message text-center">
                  {/* <h4 className="show-title mb-3">Easly handle this booking.</h4> */}
                  <p className="show-date-time date">
                    Download care seeker app.
                  </p>
                </div>
              </div>
              <div className="col-md-12 download-app-popup">
                <div className="row">
                  <div className="col-md-6 col-6 app-scanner-box app-left">
                    <div className="qr-box">
                      <a href="#">
                        <img
                          className="qr-img"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                          alt="QR Code"
                        />
                        <p className="get-app-text">
                          <img
                            className="qr-img"
                            src={`${constants.IMAGE_PATH.IMAG_PATH}template/app-store-btn.png`}
                            alt="QR Code"
                          />
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 app-scanner-box app-right">
                    <div className="qr-box">
                      <a href="#">
                        <img
                          className="qr-img"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                          alt="QR Code"
                        />
                        <p className="get-app-text">
                          <img
                            className="qr-img"
                            src={`${constants.IMAGE_PATH.IMAG_PATH}template/android-btn.png`}
                            alt="QR Code"
                          />
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
