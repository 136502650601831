import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import constants from "../../../../Utils/Constants/Constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { getFAQList, getTopworkersList } from "../../../../Api/WebsiteApi";

export default function TopWorkers() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const sliderRef = useRef<any>(null);

  const settings: any = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
    // nextArrow: true,
    // prevArrow: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const {
    data: ListItems,
    error: ListItemsError,
    isLoading: ListItemsLoading,
    refetch: ListItemsListRefetch,
  } = useQuery(["topworkers"], () => getTopworkersList(), {
    keepPreviousData: true,
  });
  const generateStars = (rating: any) => {
    const maxStars = 5;
    const stars = [];

    for (let i = 1; i <= maxStars; i++) {
      if (i <= rating) {
        stars.push(<i key={i} className="ri-star-fill text-warning me-1"></i>);
      } else {
        stars.push(<i key={i} className="ri-star-line text-warning me-1"></i>);
      }
    }

    return stars;
  };

  const truncateNotes = (notes: any, maxWords = 5) => {
    if (!notes) return "";
    const words = notes?.split(" ");
    return words?.length > maxWords
      ? words?.slice(0, maxWords)?.join(" ") + "..."
      : notes;
  };
  return (
    <>
      <style>
        {`
          .swiper-style-2.swiper-container .item-logo {
            height: 65mm;
            overflow: hidden;
            overflow-y: auto;
          }
          .swiper-style-2.swiper-container .item-logo::-webkit-scrollbar {
              width: 5px;
          }
          .swiper-style-2.swiper-container .item-logo::-webkit-scrollbar-track {
              background: #f1f1f1;
          }

          .swiper-style-2.swiper-container .item-logo::-webkit-scrollbar-thumb {
              background: #888;
          }

          .swiper-style-2.swiper-container .item-logo::-webkit-scrollbar-thumb:hover {
              background: #555;
          }
        `}
      </style>
      <section className="section-box mt-0">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
              Top Support Workers
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Connect with and book support workers in your community
            </p>
          </div>
        </div>
        <div className="container">
          <div className="box-swiper mt-50">
            <div className="swiper-container  swiper-style-2 swiper">
              <div className="box-swiper mt-50 our-support-swiper">
                <Slider {...settings} ref={sliderRef}>
                  {ListItems?.map((item: any, index: any) => {
                    return (
                      <>
                        <style>
                          {`
                            .box-swiper .swiper-container .item-logo .image-left {
                              min-width: 55px;
                              max-width: 55px;
                              max-height: 50px;
                            }
                            .box-swiper .swiper-container .item-logo .image-left img {
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              background-repeat: no-repeat;
                            }
                          `}
                        </style>
                        {" "}
                        <a href="#">
                          <div className="item-logo">
                            <div className="d-flex align-items-top">
                              <div className="image-left mb-2">
                                <img
                                  alt=""
                                  src={
                                    item?.profile_img
                                      ? item?.profile_img
                                      : "https://i.pravatar.cc/52"
                                  }
                                  className="rounded"
                                />
                              </div>
                              <div className="text-info-right w-auto mb-2">
                                <h4>
                                  <Link to={`/care-giver-view?id=${item?.id}`}>
                                    {item?.fullName}
                                  </Link>
                                </h4>
                                {generateStars(item?.totalRatingCount)}

                                <span className="font-xs color-text-mutted ml-10">
                                  <span>(</span>
                                  <span>{item?.totalRatingCount}</span>
                                  <span>)</span>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="text-info-bottom font-xs mb-5">
                                {" "}
                                <span className="font-xs color-text-mutted icon-location">
                                  {item?.suburb?.locality}-
                                  {item?.suburb?.postcode}
                                </span>
                              </div>
                              <div className="text-info-bottom font-xs mb-5">
                                <span className="font-xs color-text-mutted">
                                <i className="ri-customer-service-2-line"></i>&nbsp;&nbsp;
                                  {item?.totalCompletedEngagements} Support
                                </span>
                              </div>

                              <div className="text-info-bottom mb-0 mt-2">
                                <p className="mt-1" style={{lineHeight: '1.25'}}>
                                  {truncateNotes(item?.notes)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </>
                    );
                  })}
                </Slider>
              </div>
            </div>

            <div
              className="swiper-button-next swiper-button-next-1"
              onClick={() => sliderRef.current.slickNext()}
            ></div>
            <div
              className="swiper-button-prev swiper-button-prev-1"
              onClick={() => sliderRef.current.slickPrev()}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
}
