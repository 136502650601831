import { useState } from "react";
import ModalContainers from "../../../../../components/Modal/ModalContainers";
import OtpModal from "./OTPMoadal";
import SuccessModal from "./SuccessCancelModal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import { EmailClickHandler } from "./SubmitHandlers";
import { calculateHoursDifference, calculateHoursDifference_ } from "../../../../../Utils/helpers/helpers";
import OTPConformModal from "./OTPConfirmModal";

const validationSchema = Yup.object().shape({
  emailOrPhone: Yup.string()
    .required("Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Must be a valid email")
    .nullable(),
});

export default function ConformModal({
  isClose_1,
  amount,
  values,
  service_providers,
  caregiver,
}: any) {
  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
  };
  const [value, setValue] = useState<any>();
  const [centred_, setCentered_] = useState(false);
  const centeredToggle_ = () => setCentered_(!centred_);
  const onCloseModal_ = () => {
    setCentered_(!centred_);
  };
  const [data_full, setDataFull] = useState([]);
  const [email, setEmail] = useState<any>();
  console.log(data_full, "amountamount");
  return (
    <>
      <div className="slect-time-slots confirm-booking-slot">
        {/* <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">Select your time slot</h5>
          <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i className="ri-close-fill"></i></span>
          </button>
        </div> */}
        <div className="modal-body px-4 py-4">
          <div className="text-center pt-4">
            <h5 className="mb-4" style={{ fontSize: 50 }}>
              <i className="ri-checkbox-circle-fill text-success" />
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="success-message text-center">
                <h4 className="show-title mb-3">Time slot available</h4>
                <p className="show-date-time date">
                  {moment().format("dddd")},
                  {moment(values?.start_date).format("DD MMM YYYY")}
                </p>
                <p className="show-date-time time">
                  {moment(values?.start_time).format("hh:mm A")} -{" "}
                  {moment(values?.end_time).format("hh:mm A")}{" "}
                  <span>
                    (
                    {calculateHoursDifference_(
                      values?.start_time,
                      values?.end_time
                    )}{" "}
                    hr)
                  </span>
                </p>
                <p className="show-content hour-rate">
                  <span className="color-brand-2">${amount}</span> is estimated.
                </p>
              </div>
              <hr />
              <Formik
                initialValues={{ emailOrPhone: "" }} // Initial form values
                validationSchema={validationSchema} // Apply validation schema
                onSubmit={(values) => {
                  EmailClickHandler(
                    values,
                    setDataFull,
                    setCentered,
                    setCentered_,
                    setValue
                  );
                  setEmail(values?.emailOrPhone);
                  // centeredToggle();
                }}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <small
                        id="emailHelp"
                        className="form-text text-muted col-md-12 mb-2"
                      >
                        Enter your registered email address .
                      </small>
                      <div className="col-md-12">
                        <Field
                          type="text"
                          name="emailOrPhone" // Field name for Formik
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email "
                        />
                        <ErrorMessage
                          name="emailOrPhone"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-float-center justify-content-center mt-3 mb-3">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={isClose_1} // Close the modal
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-default">
                        GET OTP
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {centred && (
          <>
            {" "}
            <ModalContainers
              toggle={centeredToggle}
              isOpen={centred}
              onClosed={onCloseModal}
              titl="Sucess"
              modalBodyClassName={""}
              children={
                <OTPConformModal
                  isClose_2={centeredToggle}
                  isClose_1={isClose_1}
                  data_full_={data_full}
                  email={email}
                  service_providers={service_providers}
                  values_={values}
                  amount={amount}
                  caregiver={caregiver}
                />
              }
              size={"lg"}
              backdrop
            />
          </>
        )}
        {centred_ && (
          <>
            {" "}
            <ModalContainers
              toggle={centeredToggle_}
              isOpen={centred_}
              onClosed={onCloseModal_}
              titl="Sucess"
              modalBodyClassName={""}
              children={
                <SuccessModal
                  isClose={centeredToggle_}
                  value={value}
                  setValue={setValue}
                  caregiver={caregiver}
                  values={values}
                />
              }
              size={"lg"}
              backdrop
            />
          </>
        )}
      </div>
    </>
  );
}
