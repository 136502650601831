/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner,
  Badge,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { ProfileSubmitHandlerStep3 } from "../ProfileMethods/ProfileSubmitHandlers";
import { api } from "../../../Api/Api";
import moment from "moment";
import DatePickerReact from "../../../components/DatePicker/DatePickerReact";
import { WWCC_TYPe } from "../../../Utils/Constants/select_options";
import constants from "../../../Utils/Constants/Constants";
import DocumentUpload from "./SubSections/Step3/DocumentUpload";

const Step3 = ({ setStep, UserListRefetch }: any) => {
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState<any>({
    document_id: "",
    document_id_expire: null,
    document_id_img: [], // Array to store multiple document images
    police_verification_id: "",
    police_verification_id_expire: null,
    police_verification_id_img: [], // Array to store multiple police verification images
    wwcc_id: "",
    wwcc_id_expire: null,
    wwcc_id_img: [], // Array to store multiple WWCC images
    first_aid_certificate_id: "",
    first_aid_certificate_expire: null,
    first_aid_certificate_img: [], // Array to store multiple first aid images
    wwcc_type: "",
    ndiswc_id: "",
    ndiswc_id_expire: null,
    ndiswc_id_img: [],
    medicard_id: "",
    medicard_id_expire: null,
    medicard_id_img: [],
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    api
      .get("/user/user_details", true)
      .then(([status, response]) => {
        const documentData = response?.data?.user_details?.documents;
        const documentIdArray = documentData?.filter(
          (doc: any) => doc.documentName === "document_id"
        );
        const policeVerificationArray = documentData?.filter(
          (doc: any) => doc.documentName === "police_verification_id"
        );
        const wwccIdArray = documentData?.filter(
          (doc: any) => doc.documentName === "wwcc_id"
        );
        const firstAidCertificateArray = documentData?.filter(
          (doc: any) => doc.documentName === "first_aid_certificate"
        );
        const ndiswcarray = documentData?.filter(
          (doc: any) => doc.documentName === "ndis_worker_clearance_id"
        );
        const medicardwcarray = documentData?.filter(
          (doc: any) => doc.documentName === "medical_card"
        );
        setFormData({
          ...formData,
          document_id: response?.data?.user_details?.documentId || "",
          document_id_expire:
            response?.data?.user_details?.documentIdExpire || null,
          document_id_img: documentIdArray?.[0] || [],

          police_verification_id:
            response?.data?.user_details?.policeVerificationId || "",
          police_verification_id_expire:
            response?.data?.user_details?.policeVerificationIdExpire || null,
          police_verification_id_img: policeVerificationArray?.[0] || [],

          wwcc_id: response?.data?.user_details?.wwccId || "",
          wwcc_id_expire: response?.data?.user_details?.wwccIdExpire || null,
          wwcc_id_img: wwccIdArray?.[0] || [],

          first_aid_certificate_id:
            response?.data?.user_details?.firstAidCertificateId || "",
          first_aid_certificate_expire:
            response?.data?.user_details?.firstAidCertificateExpire || null,
          first_aid_certificate_img: firstAidCertificateArray?.[0] || [],

          wwcc_type: response?.data?.user_details?.wwccType,

          ndiswc_id: response?.data?.user_details?.ndiswcId || null,
          ndiswc_id_expire:
            response?.data?.user_details?.ndiswcIdExpire || null,
          ndiswc_id_img: ndiswcarray?.[0] || [],

          medicard_id: response?.data?.user_details?.medicalCardId || null,
          medicard_id_expire:
            response?.data?.user_details?.medicalCardExpire || null,
          medicard_id_img: medicardwcarray?.[0] || [],
        });
      })
      .catch((err) => {});
  };

  const handleMultipleImagesChange = (event: any, fieldName: string) => {
    const file = event.target.files?.[0]; // Get the first file
    const { isValid, errors } = validateFile(file);
    const borderColor = isValid ? "green" : "red";
    if (file) {
      const updatedImage = {
        file,
        preview: URL.createObjectURL(file),
        documentType: file.type,
        borderColor: borderColor,
        errors: errors,
        isValid: isValid,
      };

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [fieldName]: updatedImage, // Store only a single file object
      }));
      setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName]: "" }));
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    namez?: any
  ) => {
    if (
      namez === "first_aid_certificate_expire" ||
      namez === "police_verification_id_expire" ||
      namez === "document_id_expire" ||
      namez === "wwcc_id_expire" ||
      namez === "ndiswc_id_expire" ||
      namez === "medicard_id_expire"
    ) {
      setFormData({ ...formData, [namez]: e });
      setErrors((prevErrors: any) => ({ ...prevErrors, [namez]: undefined }));
    } else {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  const allowedFileTypes = ["png", "jpg", "jpeg", "gif", "pdf", "jif"];
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

  /**
   * Validates if a file matches the allowed MIME types.
   */
  const validateFileType = (file: any): boolean => {
    const type = file?.documentType ? file?.documentType : file?.type;
    return type
      ? allowedFileTypes.includes(type.split("/").pop()?.toLowerCase())
      : false;
  };

  /**
   * Validates if a file is within the allowed size limit.
   */
  const validateFileSize = (file: any): boolean => {
    console.log(file, "file?.file");
    const fileSize = file?.file?.size || file?.documentSize;
    return fileSize ? fileSize <= MAX_FILE_SIZE : false;
  };

  /**
   * Runs all validations on a file and returns an object with validation results.
   */
  const validateFile = (file: any): { isValid: boolean; errors: string[] } => {
    console.log(file, "filefilefile");
    const errors: string[] = [];

    if (!validateFileType(file)) {
      errors.push(
        "Invalid file type. Only PDF, JPG, JPEG, PNG, GIF, and JIF are allowed."
      );
    }

    // if (!validateFileSize(file)) {
    //   errors.push("File size exceeds 2 MB.");
    // }

    return {
      isValid: errors.length === 0,
      errors,
    };
  };

  /**
   * Validates all files in an array and aggregates results.
   */
  // const validateFiles = (
  //   files: any[]
  // ): { isValid: boolean; errors: string[] } => {
  //   const allErrors: string[] = [];

  //   const allValid = files?.every((file) => {
  //     const { isValid, errors } = validateFile(file);
  //     if (!isValid) {
  //       allErrors.push(...errors);
  //     }
  //     return isValid;
  //   });

  //   return {
  //     isValid: allValid,
  //     errors: allErrors,
  //   };
  // };
  const validateFiles = (file: any): { isValid: boolean; errors: string[] } => {
    if (!file) return { isValid: false, errors: ["No file selected"] };

    const { isValid, errors } = validateFile(file);

    return {
      isValid,
      errors,
    };
  };

  const validateForm = (fieldName?: string, fieldValue?: any) => {
    let newErrors: any = {};

    // Regular expressions for each document ID format
    const driversLicenseRegex = /^\d{8}$/; // 8 digits
    const policeVerificationRegex = /^\d{7}PC$/; // 7 digits followed by PC
    const wwccIdRegex = /^WWC\d{7}[A-Z]$/; // WWC followed by 7 digits and 1 alphabet
    const firstAidIdRegex = /^[a-zA-Z0-9]{6,14}$/; // 6-14 alphanumeric characters

    const MedicardIdRegex = /^\d{4}\s?\d{5}\s?\d{1}(\s?\d{1})?$/;
    // Function to check if a date is in the future
    const isFutureDate = (dateString: any) => {
      const dateString_ = moment(dateString).format("DD/MM/YYYY");
      const [day, month, year] = dateString_?.split("/").map(Number);
      const date = new Date(year, month - 1, day); // Month is 0-indexed in JS
      return date > new Date();
    };

    // Validate Drivers License ID
    if (!formData?.wwcc_type) {
      newErrors.wwcc_type = "WWCC type  is required";
    }
    if (!formData.document_id) {
      newErrors.document_id = "Australian photo ID is required";
    } else if (!driversLicenseRegex.test(formData.document_id)) {
      newErrors.document_id = "Australian photo ID must be 8 digits";
    }

    // Validate Document ID Expiry Date
    if (!formData.document_id_expire) {
      newErrors.document_id_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.document_id_expire)) {
      newErrors.document_id_expire = "Expiry date must be in the future";
    }

    // Validate Police Verification ID and Expiry Date
    if (!formData.police_verification_id) {
      newErrors.police_verification_id = "Police verification ID is required";
    } else if (!policeVerificationRegex.test(formData.police_verification_id)) {
      newErrors.police_verification_id =
        "Police verification ID must be 7 digits followed by 'PC' Eg:2324567PC";
    }

    if (!formData.police_verification_id_expire) {
      newErrors.police_verification_id_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.police_verification_id_expire)) {
      newErrors.police_verification_id_expire =
        "Expiry date must be in the future";
    }
    if (!formData.medicard_id) {
      newErrors.medicard_id = "Medicard ID is required";
    } else if (!MedicardIdRegex.test(formData.medicard_id)) {
      newErrors.medicard_id =
        "Medicard ID must be 10 or 11 digits Ex:1234 56789 1 or 1234 56789 1 3 ";
    }

    // Validate First Aid Certificate Expiry Date
    if (!formData.medicard_id_expire) {
      newErrors.medicard_id_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.medicard_id_expire)) {
      newErrors.medicard_id_expire = "Expiry date must be in the future";
    }
    if (formData?.wwcc_type === "paid") {
      // Validate WWCC ID and Expiry Date
      if (!formData.wwcc_id) {
        newErrors.wwcc_id = "WWCC ID is required";
      } else if (!wwccIdRegex.test(formData.wwcc_id)) {
        newErrors.wwcc_id =
          "WWCC ID must be 'WWC' followed by 7 digits and 1 alphabet Eg:WWC1237890D";
      }

      if (!formData.wwcc_id_expire) {
        newErrors.wwcc_id_expire = "Expiry date is required";
      } else if (!isFutureDate(formData.wwcc_id_expire)) {
        newErrors.wwcc_id_expire = "Expiry date must be in the future";
      }

      if (!formData.wwcc_id_img || formData?.wwcc_id_img.length === 0) {
        newErrors.wwcc_id_img = "Document is required.";
      } else {
        const { isValid, errors } = validateFiles(formData.wwcc_id_img);
        if (!isValid) {
          newErrors.wwcc_id_img = errors.join(" ");
        }
      }
    }

    if (formData.ndiswc_id_expire && !isFutureDate(formData.ndiswc_id_expire)) {
      newErrors.ndiswc_id_expire = "Expiry date must be in the future";
    }

    // Validate First Aid Certificate ID and Expiry Date
    if (!formData.first_aid_certificate_id) {
      newErrors.first_aid_certificate_id =
        "First aid certificate ID is required";
    } else if (!firstAidIdRegex.test(formData.first_aid_certificate_id)) {
      newErrors.first_aid_certificate_id =
        "First aid certificate ID must be 6-14 alphanumeric characters";
    }

    // Validate First Aid Certificate Expiry Date
    if (!formData.first_aid_certificate_expire) {
      newErrors.first_aid_certificate_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.first_aid_certificate_expire)) {
      newErrors.first_aid_certificate_expire =
        "Expiry date must be in the future";
    }

    if (!formData.document_id_img || formData.document_id_img.length === 0) {
      newErrors.document_id_img = "Document is required.";
    } else {
      const { isValid, errors } = validateFiles(formData?.document_id_img);
      if (!isValid) {
        newErrors.document_id_img = errors.join(" ");
      }
    }
    if (
      !formData.police_verification_id_img ||
      formData?.police_verification_id_img.length === 0
    ) {
      newErrors.police_verification_id_img = "Document is required.";
    } else {
      const { isValid, errors } = validateFiles(
        formData.police_verification_id_img
      );
      if (!isValid) {
        newErrors.police_verification_id_img = errors.join(" ");
      }
    }

    if (
      !formData.first_aid_certificate_img ||
      formData?.first_aid_certificate_img.length === 0
    ) {
      newErrors.first_aid_certificate_img = "Document is required.";
    } else {
      const { isValid, errors } = validateFiles(
        formData.first_aid_certificate_img
      );
      if (!isValid) {
        newErrors.first_aid_certificate_img = errors.join(" ");
      }
    }
    if (formData.ndiswc_id_img && formData?.ndiswc_id_img?.length !== 0) {
      const { isValid, errors } = validateFiles(formData?.ndiswc_id_img);
      if (!isValid) {
        newErrors.ndiswc_id_img = errors.join(" ");
      }
    }
    if (!formData.medicard_id) {
      newErrors.medicard_id = "Medicare card ID is required";
    } else if (!MedicardIdRegex.test(formData?.medicard_id)) {
      newErrors.medicard_id =
        "Medicare card ID must be 10 or 11 digits Ex:1234 56789 1 or 1234 56789 1 3 ";
    }
    // Validate First Aid Certificate Expiry Date
    if (!formData.medicard_id_expire) {
      newErrors.medicard_id_expire = "Expiry date is required";
    } else if (!isFutureDate(formData.medicard_id_expire)) {
      newErrors.medicard_id_expire = "Expiry date must be in the future";
    }
    if (!formData.medicard_id_img || formData?.medicard_id_img.length === 0) {
      newErrors.medicard_id_img = "Document is required.";
    } else {
      const { isValid, errors } = validateFiles(formData?.medicard_id_img);
      if (!isValid) {
        newErrors.medicard_id_img = errors.join(" ");
      }
    }
    return newErrors;
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    namez?: string
  ) => {
    if (
      namez === "first_aid_certificate_expire" ||
      namez === "police_verification_id_expire" ||
      namez === "document_id_expire" ||
      namez === "wwcc_id_expire" ||
      namez === "ndiswc_id_expire" ||
      namez === "medicard_id_expire"
    ) {
      const errors = validateForm(); // Run validation
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [namez]: errors[namez] || "", // Update only the specific field's error
      }));
    } else if (
      namez === "first_aid_certificate_img" ||
      namez === "police_verification_id_img" ||
      namez === "document_id_img" ||
      namez === "wwcc_id_img" ||
      namez === "ndiswc_id_img" ||
      namez === "medicard_id_img"
    ) {
    } else {
      const { name } = e.target;
      const errors = validateForm(); // Run validation

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the specific field's error
      }));
    }
  };

  const removeImage = (name: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: null, // Reset the field
    }));
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // window.scrollTo(0, 0);
    const validationErrors: any = validateForm();
    if (Object?.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);
    ProfileSubmitHandlerStep3(
      formData,
      setStep,
      getUser,
      setIsSubmitting,
      UserListRefetch
    );
  };
  console.log(errors, "aDGHSAGDHAS", formData);
  return (
    <>
      <style>{`
        .parent {
          width: auto;
          margin: auto;
          padding: 2rem;
          background: #ffffff;
          border-radius: 25px;
          box-shadow: 7px 20px 20px rgb(210, 227, 244);
        }

        .file-upload {
          text-align: center;
          border: 3px dashed rgb(210, 227, 244);
          padding: 1.5rem;
          position: relative;
          cursor: pointer;
          float: left;
          width: 100%;
        }

        .file-upload p {
          font-size: 0.87rem;
          margin-top: 10px;
          color: #bbcada;
        }

        .file-upload input {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
        .file-upload span.badge {
          font-size: 0px;
          padding: 2px;
          margin-left: 5px;
          right: 5px;
          top: 5px;
        }
          .file-upload img {
            margin: 0 5px;
            border-radius: 5px;
          }
      `}</style>
      <form onSubmit={handleSubmit}>
        <Card>
          <DocumentUpload
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
            label="AUSTRALIAN PHOTO ID"
            document_id="document_id"
            document_img="document_id_img"
            document_date="document_id_expire"
            handleMultipleImagesChange={handleMultipleImagesChange}
            removeImage={removeImage}
          />
          <DocumentUpload
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
            label="POLICE VERIFICATION"
            document_id="police_verification_id"
            document_img="police_verification_id_img"
            document_date="police_verification_id_expire"
            handleMultipleImagesChange={handleMultipleImagesChange}
            removeImage={removeImage}
          />
          <DocumentUpload
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
            label="FIRST AID CERTIFICATE"
            document_id="first_aid_certificate_id"
            document_img="first_aid_certificate_img"
            document_date="first_aid_certificate_expire"
            handleMultipleImagesChange={handleMultipleImagesChange}
            removeImage={removeImage}
          />
          <DocumentUpload
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
            label="MEDICARE CARD"
            document_id="medicard_id"
            document_img="medicard_id_img"
            document_date="medicard_id_expire"
            handleMultipleImagesChange={handleMultipleImagesChange}
            removeImage={removeImage}
          />
          <CardBody>
            <FormGroup>
              <Label for="wwcc_id">WWCC Type</Label>

              <div className="row">
                {" "}
                {WWCC_TYPe?.map((item: any, index: number) => {
                  return (
                    <div className="col-2" key={index}>
                      <input
                        type="radio"
                        name="wwcc_type"
                        value={item.id}
                        checked={formData?.wwcc_type === item?.id?.toString()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      &nbsp;
                      <Label className="form-check-label">{item.name}</Label>
                    </div>
                  );
                })}
              </div>
              <div className="text-danger">{errors?.wwcc_type}</div>
            </FormGroup>
            {formData?.wwcc_type === "paid" && (
              <>
                {" "}
                <DocumentUpload
                  formData={formData}
                  handleChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                  label="WWCC"
                  document_id="wwcc_id"
                  document_img="wwcc_id_img"
                  document_date="wwcc_id_expire"
                  handleMultipleImagesChange={handleMultipleImagesChange}
                  removeImage={removeImage}
                />
              </>
            )}
          </CardBody>
          <DocumentUpload
            formData={formData}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
            label="NDISWC"
            document_id="ndiswc_id"
            document_img="ndiswc_id_img"
            document_date="ndiswc_id_expire"
            handleMultipleImagesChange={handleMultipleImagesChange}
            removeImage={removeImage}
          />
        </Card>

        <div className="text-center mt-5 d-float-center justify-content-center gap-15 mt-5">
          {" "}
          <Button
            className="btn btn-brand-2"
            type="submit"
            onClick={() => [setStep(2)]}
          >
            <i className="ri-arrow-left-line"></i>&nbsp;BACK
          </Button>
          <Button
            className="btn btn-brand-1"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting && <Spinner size="sm"></Spinner>}
            Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
          </Button>
        </div>
      </form>
    </>
  );
};

export default Step3;
