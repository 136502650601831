export default function Nodata() {
  return (
    <>
      <div className="text-center">
        {" "}
        <div>
          {" "}
          <img
            style={{ height: "200px" }}
            src="https://static.vecteezy.com/system/resources/previews/004/968/529/original/search-no-results-found-concept-illustration-flat-design-eps10-simple-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-with-editable-stroke-line-outline-linear-vector.jpg"
          />
        </div>
        <h6>Sorry,nothing matched your search.</h6>
      </div>
    </>
  );
}
