import { toast } from "react-toastify";
import { isNumeric, setValidationErrors } from "../../../Utils/helpers/helpers";
import { api } from "../../../Api/Api";
import axios from "axios";
import { BaseUrl } from "../../../Api/BaseUrl";
import Swal from "sweetalert2";
import moment from "moment";
import { useEffect } from "react";
import { useFormikContext } from "formik";

export const ProfileSubmitHandlerStep1 = (
  values: any,
  actions: any,
  setStep: any,
  navigate: any,
  ListRefetch: any,
  supportFor: any,
  setErrorBack: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "1");

  // formData.append(
  //   "suburb",
  //   isNumeric(values?.suburb) ? values?.suburb : values?.sub_id
  // );
  const seeker_id: any = localStorage?.getItem("relatedCareseekerId");

  if (
    values?.suppot_for_you !== 1 &&
    values?.suppot_for_you !== "1" &&
    seeker_id
  ) {
    formData.append("related_careseeker_id", seeker_id);
  }

  if (values?.profile_img) {
    formData.append("profile_img", values?.profile_img);
  }
  formData.append("support_for", values?.suppot_for_you);
  formData.append("email", values?.email);
  formData.append("first_name", values?.first_name);
  formData.append("last_name", values?.last_name);
  formData.append("mobile", values?.mobile);
  formData.append("dob", moment(values?.dob).format("YYYY-MM-DD"));
  formData.append("gender", values?.gender);
  formData.append("street_number", values?.street_number);
  formData.append("address_line_one", values?.address_line_one);
  formData.append("address_line_two", values?.address_line_two);
  formData.append("state", values?.state);
  formData.append("suburb", values?.suburb);

  axios({
    method: "post",
    url: `${BaseUrl}/user/careseeker_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      console.log(response, "response?.data?.statu");
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        window.scrollTo(0, 0);
        actions?.setSubmitting(true);
        // navigate("/create-profile-careseeker/2");
        ListRefetch();
        setStep(2);
        localStorage.setItem(
          "relatedCareseekerId",
          response?.data?.relatedCareseekerId
        );
      } else {
        // toast.success(response?.data?.message);
        actions?.setSubmitting(true);
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);
      // console.log(response?.response,"response?.data?.statu")
      if (response?.response?.data) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        if (response?.response?.data?.message?.includes("Email")) {
          actions?.setFieldError("Email", response?.response?.data?.message);
          setErrorBack(response?.response?.data?.message?.includes("Email"));
        }
        if (response?.response?.data?.message?.includes("file")) {
          actions?.setFieldError(
            "profile_img",
            response?.response?.data?.message
          );
        }

        // setValidationErrors(response?.response?.data?.mess, actions);
      } else {
        Swal.fire({
          title: "Error",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
        // toast.error("Unexpected Error Occurred");
      }
    });
};
export const ProfileSubmitHandlerStep2 = (
  values: any,
  actions: any,
  setStep: any,
  navigate: any,
  ListRefetch: any,
  supportFor: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "2");
  // Append non-file fields
  formData.append("payment_mode", values.payment_mode);
  const seeker_id: any = localStorage?.getItem("relatedCareseekerId");
  if (supportFor !== 1) {
    formData.append("related_careseeker_id", seeker_id);
  }

  formData.append(
    "ndis_contact_number",
    values.contact_number_dis ? values.contact_number_dis : "test"
  );

  formData.append(
    "ndis_contact_name",
    values.contact_name_ndis ? values.contact_name_ndis : "test"
  );
  formData.append("ndis_id", values.ndis ? values.ndis : "test");
  formData.append(
    "plan_manager_name",
    values.plan_manager_name ? values.plan_manager_name : "test"
  );
  formData.append(
    "email_address_for_sending_invoice",
    values.email ? values.email : "test"
  );
  formData.append(
    "ndis_contact_email",
    values.ndis_contact_email ? values.ndis_contact_email : "test@gmail.com"
  );

  formData.append(
    "support_coordinator_contact_number",
    values.support_coordinator_contact_number
      ? values.support_coordinator_contact_number
      : "test"
  );

  formData.append(
    "support_coordinator_name",
    values.support_coordinator_name ? values.support_coordinator_name : "test"
  );
  formData.append(
    "support_coordinator_email",
    values.support_coordinator_email
      ? values.support_coordinator_email
      : "test@gmail.com"
  );
  axios({
    method: "post",
    url: `${BaseUrl}/user/careseeker_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        setStep(3);
        ListRefetch();
        window.scrollTo(0, 0);
        // navigate("/create-profile-careseeker/3");
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        // toast.error("Unexpected Error Occurred");
        Swal.fire({
          title: "Error",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
      }
    });
};
export const ProfileSubmitHandlerStep3 = (
  values: any,
  actions: any,
  setStep: any,
  navigate: any,
  ListRefetch: any,
  supportFor: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "3");

  // Append simple fields

  formData.append("profile_title", values.care_request_title);
  formData.append("bio_description", values.summary);

  formData.append(
    "health_concerns_or_medical_conditions",
    values.health_status ? values.health_status : " "
  );

  const seeker_id: any = localStorage?.getItem("relatedCareseekerId");
  if (supportFor !== 1) {
    formData.append("related_careseeker_id", seeker_id);
  }

  axios({
    method: "post",
    url: `${BaseUrl}/user/careseeker_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        ListRefetch();
        setStep(4);
        window.scrollTo(0, 0);
        // navigate("/success-careseeker?profile_completed=1");
        // navigate("/create-profile-careseeker/4");
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        // toast.error("Unexpected Error Occurred");
        Swal.fire({
          title: "Error",
          text: "Unexpected Error Occurred",
          icon: "error",
        });
      }
    });
};

export const ProfileSubmitHandler5 = (
  values: any,
  actions: any,
  navigate: any
) => {
  actions.setSubmitting(true);
  const formData = new FormData();
  formData.append("step", "5");

  formData.append("abn", values?.abn);
  formData.append("extra_charge_after_hours", values?.extra_charge_after_hours);
  formData.append("bank_name", values?.bank_name);
  formData.append("bank_branch_name", values?.bank_branch_name);
  formData.append("bank_account_number", values?.bank_account_number);

  axios({
    method: "post",
    url: `${BaseUrl}/user/caregiver_complete_registration`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    },
  })
    .then(function (response: any) {
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success",
          text: "Details added successfully",
          icon: "success",
        });
        // toast.success("Detiles added successfully");
        actions?.setSubmitting(true);
        // setStep(2);
        navigate("/success?profile_completed=1");
      }
    })
    .catch(function (response) {
      actions?.setSubmitting(false);

      if (response?.response?.data?.errors) {
        // setValidationErrors(response?.response?.data?.errors, actions);
      } else {
        // toast.error("Unexpected Error Occurred");
      }
    });
};

// Step1 Values

export const addModalInitialValuesHandlerStep1 = (
  UserData: any,
  setAddModalInitialValues: Function,
  athorperson?: any
) => {
  setAddModalInitialValues({
    first_name: athorperson === 2 ? "" : UserData?.firstName,
    email: UserData?.email,
    mobile: UserData?.mobile,
    suppot_for_you: UserData?.careseeker_details?.supportFor?.id
      ? UserData?.careseeker_details?.supportFor?.id?.toString()
      : athorperson === 1
      ? "1"
      : "",
    suburb: UserData?.careseeker_details?.suburb?.id,
    dob: UserData?.careseeker_details?.dob,
    gender: UserData?.careseeker_details?.gender?.id?.toString(),

    last_name: athorperson === 2 ? "" : UserData?.lastName,
    street_number: UserData?.careseeker_details?.streetNumber,
    address_line_one: UserData?.careseeker_details?.addressLineOne,
    address_line_two:
      UserData?.careseeker_details?.addressLineTwo === "undefined"
        ? ""
        : UserData?.careseeker_details?.addressLineTwo,
    state: UserData?.careseeker_details?.state?.id,
    profile_img_: UserData?.careseeker_details?.profileImgThumbnail,
    profile_img: "",
  });
};

export const addModalInitialValuesHandlerStep2 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  setAddModalInitialValues({
    payment_mode: UserData?.careseeker_details?.paymentMode?.id?.toString(),
    ndis:
      UserData?.careseeker_details?.ndisId === "test"
        ? ""
        : UserData?.careseeker_details?.ndisId,
    plan_manager_name:
      UserData?.careseeker_details?.planManagerName === "test"
        ? ""
        : UserData?.careseeker_details?.planManagerName,
    contact_name_ndis:
      UserData?.careseeker_details?.ndisContactName === "test"
        ? ""
        : UserData?.careseeker_details?.ndisContactName,
    contact_number_dis:
      UserData?.careseeker_details?.ndisContactNumber === "test"
        ? ""
        : UserData?.careseeker_details?.ndisContactNumber === null
        ? ""
        : UserData?.careseeker_details?.ndisContactNumber,
    email: UserData?.careseeker_details?.emailAddressForSendingInvoice,
    ndis_contact_email:
      UserData?.careseeker_details?.ndisContactEmail === "test@gmail.com"
        ? ""
        : UserData?.careseeker_details?.ndisContactEmail,
    support_coordinator_name:
      UserData?.careseeker_details?.supportCoordinatorName === "test"
        ? ""
        : UserData?.careseeker_details?.supportCoordinatorName === null
        ? ""
        : UserData?.careseeker_details?.supportCoordinatorName,
    support_coordinator_contact_number:
      UserData?.careseeker_details?.supportCoordinatorContactNumber === "test"
        ? ""
        : UserData?.careseeker_details?.supportCoordinatorContactNumber === null
        ? ""
        : UserData?.careseeker_details?.supportCoordinatorContactNumber,
    support_coordinator_email:
      UserData?.careseeker_details?.supportCoordinatorEmail === "test@gmail.com"
        ? ""
        : UserData?.careseeker_details?.supportCoordinatorEmail === null
        ? ""
        : UserData?.careseeker_details?.supportCoordinatorEmail,
  });
};
export const addModalInitialValuesHandlerStep3 = (
  UserData: any,
  setAddModalInitialValues: Function
) => {
  setAddModalInitialValues({
    care_request_title: UserData?.careseeker_details?.profileTitle,
    summary: UserData?.careseeker_details?.bioDescription,
    health_status:
      UserData?.careseeker_details?.healthConcernsOrMedicalConditions,
  });
};

export const getFieldErrorNames = (formikErrors: any) => {
  const transformObjectToDotNotation = (
    obj: any,
    prefix?: string,
    result: string[] = []
  ) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length === 0) return;

    // Target all types of form fields, not just input
    const element: HTMLElement | null = document.querySelector(
      `input[name='${fieldErrorNames[0]}'], textarea[name='${fieldErrorNames[0]}'], select[name='${fieldErrorNames[0]}']`
    );

    if (!element) return;

    // Scroll and focus on the field with error
    element.scrollIntoView();
    element.focus();
  }, [submitCount, isValid, errors]);

  return null; // This is a side-effect-only component, no UI rendered
};
