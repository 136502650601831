import { Button, Card, FormGroup, Input, Label, Tooltip } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import constants from "../../../Utils/Constants/Constants";
import {
  CapitalFirstLetter,
  capitalizeFirstLetterOfEachWord,
  truncateText,
} from "../../../Utils/helpers/helpers";
import { Link } from "react-router-dom";

import React, { useState } from "react";
import CustomPagination from "../../../components/Pagination/Pagination";
import Nodata from "../../../components/Nodata/NodataFound";
import Pagination from "../../../components/Pagination/Pagination";
import DatePickerReact from "../../../components/DatePicker/DatePickerReact";
import SearchResult from "./SearchResult";

export default function CareGiverList({
  dataLoading,
  dataList,
  radius,
  handleChange,
  setValues,
  clearUrlParams,
  values,
  state_,
  currentPage,
  setCurrentPage,
  List_data,
}: any) {
  const [pages, setPages] = useState({
    page: 1,
    currentPage: dataList?.data?.meta?.currentPage,
    firstPage: dataList?.data?.meta?.total,
    firstPageUrl: "/?page=1",
    lastPage: dataList?.data?.meta?.lastPage,
    lastPageUrl: "",
    nextPageUrl: null,
    perPage: dataList?.data?.meta?.perPage,
    previousPageUrl: null,
    total: dataList?.data?.meta?.total,
  });
  // console.log(pages, "pages");
  const goToPage = (page: any) => {
    // if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
    setCurrentPage(page);
    // }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    goToPage(currentPage - 1);
  };
  const [basicTooltip, setBasicTooltip] = useState<any>({});

  const toggle = (index: any) => {
    setBasicTooltip((prev: any) => ({
      ...prev,
      [index]: !prev[index], // Toggle only the current index
    }));
  };
  const max_and_min = (item: any) => {
    const rates = item?.user_details?.caregiver_charges?.map((charge: any) =>
      Number(charge.rate ? charge.rate : 0)
    );

    const minRate = Math.min(...rates);
    const maxRate = Math.max(...rates);
    return {
      minRate: isFinite(minRate) ? minRate : 0,
      maxRate: isFinite(maxRate) ? maxRate : 0,
    };
  };
  const handleRemoveFilter = (key: any, index: any) => {
    setValues((prevState: any) => {
      const updatedArray = [...prevState[key]];
      updatedArray.splice(index, 1);
      return { ...prevState, [key]: updatedArray };
    });
  };
  const handleClearFilters = () => {
    setValues({
      state: "",
      service_type: [],
      suburb_id: null,
      gnder: "",
      lang: [],
      post_code: "",
      radius: "",
      sub_label: "",
      exp_aged_data: [],
      inter__data: [],
      exp_child_data: [],
      willing__data: [],
      avlibility_date: "",
    });
  };
  return (
    <>
      <style>
        {`
    
        `}
      </style>{" "}
      <div className="support-staff-finder">
        <div className="sub-staff-finder">
          {/* <SearchResult
            handleRemoveFilter={handleRemoveFilter}
            filter_inputs={values}
          /> */}
          <div className="col-xl-6 col-lg-5 mt-5">
            <span className="text-small text-showing">
              Showing <strong>{dataList?.data?.data?.length}</strong> of{" "}
              <strong>{dataList?.data?.meta?.total} </strong> Care Givers
            </span>
          </div>
          <hr className="mt-2" />

          {dataLoading ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="row">
                    <div className="col-md-4 mt-3 mb-5">
                      {" "}
                      <FormGroup switch>
                        <Input
                          type="switch"
                          checked={values?.has_driver_option}
                          onChange={(e: any) => {
                            // setValues({
                            //   ...values,
                            //   has_driver_option: e.target.checked,
                            // });
                            handleChange("has_driver_option", e.target.checked);
                          }} // Toggle Master switch
                        />
                        <Label className="mb-0" check>
                          Driving assistance provided
                        </Label>
                      </FormGroup>
                    </div>
                    <Label>Availability Date</Label>
                    <div className="col-md-4 calender-time-right">
                      <DatePickerReact
                        selected={
                          values?.avlibility_date
                            ? new Date(values?.avlibility_date)
                            : null
                        }
                        onChange={(date: any) =>
                          handleChange("avlibility_date", date)
                        }
                        name="avlibility_date"
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        placeholderText="DD/MM/YYYY"
                        className={`form-control `}
                        showIcon
                        minDate={new Date()}
                      />
                    </div>
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="btn btn-default mt-1"
                        onClick={() => List_data()}
                      >
                        Check Availability
                      </a>
                    </div>
                  </div>
                </div>
                {dataList?.data?.data?.length === 0 ? (
                  <Nodata />
                ) : (
                  <>
                    {dataList?.data?.data?.map((item: any, index: any) => {
                      return (
                        <>
                          {/* Giver Lists */}
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 service-list01 care-giver-lists">
                            <div className="card-grid-2 hover-up">
                              <div className="card-grid-2-image-left pb-1">
                                <div className="image-box">
                                  <img
                                    className="service-img01"
                                    src={
                                      item?.user_details?.profileImgThumbnail
                                    }
                                  />
                                </div>
                                <div className="right-info">
                                  <Link
                                    className="name-job"
                                    to={`/care-giver-view?id=${item?.id}`}
                                  >
                                    {capitalizeFirstLetterOfEachWord(
                                      item?.firstName
                                    )}{" "}
                                    {capitalizeFirstLetterOfEachWord(
                                      item?.lastName
                                    )}
                                  </Link>
                                  <div className="star-rating mb-1 d-float-center mt-0">
                                    <ul className="star-rate-sub d-float-center">
                                      {Array.from({ length: 5 }).map(
                                        (_, index) => (
                                          <li key={index}>
                                            <i
                                              className={`fa ${
                                                index <
                                                item?.user_details?.avgRating
                                                  ? "fa-star text-warning"
                                                  : "fa-star-o text-warning"
                                              }`}
                                            />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                    <span className="review-text text-capitalize ms-0">
                                      ({item?.user_details?.avgRating}
                                      {""}/{""}5)
                                    </span>
                                  </div>
                                  <div className="right-info">
                                    <span className="location-small">
                                      {/* <i className="ri-map-pin-line ms-0" /> */}
                                      {CapitalFirstLetter(
                                        item?.user_details?.suburb?.locality
                                      )}{" "}
                                      ({item?.user_details?.suburb?.state}{" "}
                                      {item?.user_details?.suburb?.postcode}) +{" "}
                                      <strong>
                                        {item?.user_details?.serviceRadius}
                                      </strong>{" "}
                                      KMs
                                    </span>
                                  </div>
                                  <div className="mt-5">
                                    <span className="speak-in text-dark f-13">
                                      <i className="ri-speak-line ms-0 text-dark f-15" />{" "}
                                      Speaks:&nbsp;
                                    </span>
                                    <span className="f-13 speak-out">
                                      {item?.user_details?.languages_spokens?.map(
                                        (items: any, index: number) => (
                                          <React.Fragment key={index}>
                                            {items?.language?.name}
                                            {index <
                                              item.user_details
                                                ?.languages_spokens?.length -
                                                1 && ", "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </span>
                                  </div>
                                  {/* <p className="font-sm color-text-paragraph mt-0 mb-3 service-description01">Conduct Art Therapy Sessions And Provide Guide...</p> */}
                                </div>
                              </div>
                              <div className="card-block-info">
                                <div className="card-2-bottom mt-0 px-1">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p
                                        className="font-sm color-text-paragraph mt-0"
                                        // id={`tooltip-${index}`} // Add the matching id here
                                      >
                                        {capitalizeFirstLetterOfEachWord(
                                          truncateText(
                                            item?.user_details?.aboutYourself,
                                            150
                                          )
                                        )}
                                        {/* <Tooltip
                                          target={`tooltip-${index}`} // Unique target id for each Tooltip
                                          placement="top"
                                          isOpen={basicTooltip[index]} // Assuming you have basicTooltip as an array
                                          toggle={() => toggle(index)} // Toggle function to open/close the Tooltip
                                        >
                                          {item?.user_details?.aboutYourself}
                                        </Tooltip> */}
                                      </p>
                                      <hr className="mt-3 mb-3" />
                                    </div>
                                    <div className="col-lg-7 col-7">
                                      <div className="d-float-center h-100">
                                        <span className="card-text-price01">
                                          <span style={{ fontSize: "20px" }}>
                                            {" "}
                                            <strong>
                                              {max_and_min(item)?.minRate
                                                ? "$" +
                                                  max_and_min(item)?.minRate +
                                                  " - "
                                                : ""}
                                              {max_and_min(item)?.maxRate
                                                ? "$" +
                                                  max_and_min(item)?.maxRate
                                                : ""}
                                            </strong>
                                          </span>
                                          {max_and_min(item)?.minRate ? (
                                            <>
                                              <span className="text-muted">
                                                /Hour
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-5 text-end">
                                      <div className="btn btn-apply-now">
                                        <Link
                                          target="_blank"
                                          className="text-white"
                                          to={`/care-giver-view?id=${item?.id}`}
                                        >
                                          View more
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 care-giver-lists d-none">
                            <div className="card-grid-2 hover-up">
                              <div className="card-grid-2-image-left pb-0">
                                <div className="image-box">
                                  <img
                                    src={
                                      item?.user_details?.profileImgThumbnail
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="right-info">
                                  <a className="name-job" href="#">
                                    {capitalizeFirstLetterOfEachWord(
                                      item?.firstName
                                    )}{" "}
                                    {capitalizeFirstLetterOfEachWord(
                                      item?.lastName
                                    )}
                                  </a>
                                  <div className="star-rating mb-2 d-float-center mt-1">
                                    <ul className="star-rate-sub d-float-center">
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-fill ms-0 text-warning" />
                                      </li>
                                      <li>
                                        <i className="ri-star-line ms-0 text-warning" />
                                      </li>
                                    </ul>
                                    <span className="review-text text-capitalize ms-0">
                                      (4.1)
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="right-info">
                                <span className="location-small">
                                  <i className="ri-map-pin-line ms-0" />
                                  {CapitalFirstLetter(
                                    item?.user_details?.suburb?.locality
                                  )}{" "}
                                  ({item?.user_details?.suburb?.state}{" "}
                                  {item?.user_details?.suburb?.postcode}) +{" "}
                                  <strong>
                                    {item?.user_details?.serviceRadius}
                                  </strong>{" "}
                                  KMs
                                </span>
                              </div>
                              <div className="card-block-info">
                                <p
                                  className="font-sm color-text-paragraph mt-0"
                                  id={`tooltip-${index}`} // Add the matching id here
                                >
                                  {capitalizeFirstLetterOfEachWord(
                                    truncateText(
                                      item?.user_details?.aboutYourself,
                                      150
                                    )
                                  )}
                                  <Tooltip
                                    target={`tooltip-${index}`} // Unique target id for each Tooltip
                                    placement="top"
                                    isOpen={basicTooltip[index]} // Assuming you have basicTooltip as an array
                                    toggle={() => toggle(index)} // Toggle function to open/close the Tooltip
                                  >
                                    {item?.user_details?.aboutYourself}
                                  </Tooltip>
                                </p>
                                <div className="mt-5">
                                  <span className="speak-in text-dark f-13">
                                    <i className="ri-speak-line ms-0 text-dark f-15" />{" "}
                                    Speaks:&nbsp;
                                  </span>
                                  <span className="f-13 speak-out">
                                    {item?.user_details?.languages_spokens?.map(
                                      (items: any, index: number) => (
                                        <React.Fragment key={index}>
                                          {items?.language?.name}
                                          {index <
                                            item.user_details?.languages_spokens
                                              ?.length -
                                              1 && ", "}
                                        </React.Fragment>
                                      )
                                    )}
                                  </span>
                                </div>
                                <hr className="mt-2 mb-2" />
                                <div className="card-2-bottom mt-0 px-0">
                                  <div className="row">
                                    <div className="col-lg-6 col-6 pt-1">
                                      {/* <span className="card-text-price">
                                        {max_and_min(item)?.minRate
                                          ? "$" +
                                            max_and_min(item)?.minRate +
                                            " - "
                                          : ""}{max_and_min(item)?.maxRate
                                          ? "$" + max_and_min(item)?.maxRate
                                          : ""}
                                      </span> */}
                                      <span style={{ fontSize: "20px" }}>
                                        {" "}
                                        <strong>
                                          {max_and_min(item)?.minRate
                                            ? "$" +
                                              max_and_min(item)?.minRate +
                                              " - "
                                            : ""}
                                          {max_and_min(item)?.maxRate
                                            ? "$" + max_and_min(item)?.maxRate
                                            : ""}
                                        </strong>
                                      </span>
                                      {max_and_min(item)?.minRate ? (
                                        <>
                                          <span className="text-muted">
                                            /Hour
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-lg-6 col-6 text-end">
                                      <div className="btn btn-apply-now">
                                        <Link
                                          target="_blank"
                                          className="text-white"
                                          to={`/care-giver-view?id=${item?.id}`}
                                        >
                                          View more
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div className="paginations m-0 mt-0 col-md-12 text-end">
                      <hr className="mt-0 mb-2" />
                      <Pagination
                        goToPreviousPage={goToPreviousPage}
                        goToPage={goToPage}
                        goToNextPage={goToNextPage}
                        totalPages={dataList?.data?.meta?.lastPage}
                        currentPage={currentPage}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
