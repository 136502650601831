import { ErrorMessage, Field } from "formik";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { YES_NO } from "../../../../../Utils/Constants/select_options";

export default function Questen({
  values,
  feildName,
  label,
  errors_,
  handleChange,
}: any) {
  return (
    <>
      {" "}
      <Col className="col-6">
        <FormGroup>
          {" "}
          <Label className="" htmlFor={feildName}>
            {label}
          </Label>
          <Row>
            {YES_NO?.map((item: any, index: number) => {
              return (
                <div className="col-2" key={index}>
                  <Input
                    type="radio"
                    name={feildName}
                    value={item.id}
                    checked={values?.[`${feildName}`] === item?.id?.toString()}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <Label className="form-check-label">{item.name}</Label>
                </div>
              );
            })}
            <div className="text-danger">{errors_?.[`${feildName}`]}</div>
          </Row>
        </FormGroup>
      </Col>
    </>
  );
}
