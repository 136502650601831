import React, { useState } from "react";
import { Formik, Field, FieldArray, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "reactstrap";

const TaskAccordionForm = ({ values }: any) => {
 

  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);

  const toggleAccordion = (index: number) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <>
      <style>
        {`
          .task-list { position: relative; }
          .task-list input {
            font-weight: 500!important;
          }
            .task-list div.text-danger { position: absolute; }
        `}
      </style>
    <FieldArray name="tasks">
        {({ insert, remove, push }) => (
          <div>
            <div className="d-float-center justify-content-between mt-4">
              <label>Task List</label>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => push({ taskName: "" })}
                style={{ backgroundColor: "#05264e" }}
              >
                <i className="ri-add-line" aria-hidden="true"></i>&nbsp;Add Task
              </button>
            </div>
            {values.tasks.length > 0 &&
              values.tasks.map((task: any, index: any) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                  <div
                    onClick={() => toggleAccordion(index)}
                    style={{
                      cursor: "pointer",
                      background: "#f0f0f0",
                      padding: "10px",
                      border: "1px solid #ccc",
                    }}
                    className="mt-3"
                  >
                    <strong>{task.taskName || `Task ${index + 1}`}</strong>
                    <span style={{ float: "right" }}>
                      {expandedIndex === index ? "-" : "+"}
                    </span>
                  </div>
                  {expandedIndex === index && (
                    <div style={{ padding: "10px", border: "1px solid #ccc" }}>
                      <label htmlFor={`tasks.${index}.taskName`}>
                        Task Name
                      </label>
                      <div className="d-float-center gap-15"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <div className="task-list" style={{ flex: 1, marginRight: "0rem" }}>
                          {/* <label htmlFor={`tasks.${index}.taskName`}>
                                  Task Name
                                </label> */}
                          <Field
                            name={`tasks.${index}.taskName`}
                            placeholder="Enter task name"
                            type="text" />
                          <ErrorMessage
                            name={`tasks.${index}.taskName`}
                            component="div"
                            className="warning-text-message text-danger" />
                        </div>
                        <span
                          className="btn btn-danger"
                          onClick={() => remove(index)}
                          style={{
                            position: "relative",
                            color: "white",
                            cursor: "pointer",
                            padding: "10px",
                            // textDecoration: "underline",
                            whiteSpace: "nowrap", // Ensure the text stays on one line
                          }}
                        >
                          <i className="ri-delete-bin-6-line"></i>
                        </span>
                      </div>

                      {/* <div>
                                        <label htmlFor={`tasks.${index}.dueDate`}>
                                          Due Date
                                        </label>
                                        <Field
                                          name={`tasks.${index}.dueDate`}
                                          placeholder="Enter due date"
                                          type="date"
                                        />
                                        <ErrorMessage
                                          name={`tasks.${index}.dueDate`}
                                          component="div"
                                          className="error"
                                        />
                                      </div>
                                      <div>
                                        <label>
                                          <Field
                                            name={`tasks.${index}.completed`}
                                            type="checkbox"
                                          />
                                          Completed
                                        </label>
                                      </div> */}
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </FieldArray>
      </>
  );
};

export default TaskAccordionForm;
