/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import { Button, CardBody, FormGroup, Label, Badge } from "reactstrap";
import DatePickerReact from "../../../../../components/DatePicker/DatePickerReact";
import constants from "../../../../../Utils/Constants/Constants";

export default function DocumentUpload({
  formData,
  handleChange,
  errors,
  handleBlur,
  label,
  document_id,
  document_img,
  document_date,
  handleMultipleImagesChange,
  removeImage,
}: any) {
  
  return (
    <>
      <CardBody >
        <h5 className="mb-2 text-uppercase mt-3">{label}</h5>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for={`${document_id}`}>Document ID</Label>
            <input
              type="text"
              name={document_id}
              value={formData?.[`${document_id}`]}
              onChange={handleChange}
              className={`form-control  ${
                errors?.[`${document_id}`] ? "is-invalid" : "is-valid"
              }
              `}
              onBlur={handleBlur}
            />
            <div className="text-danger">{errors?.[`${document_id}`]}</div>
          </FormGroup>
          <FormGroup className="col-md-6">
            <div>
              <Label for={`${document_date}`}>Expiry Date</Label>
              <DatePickerReact
                name={document_date}
                selected={formData?.[`${document_date}`]}
                onChange={(date: any) => handleChange(date, document_date)}
                dateFormat="dd/MM/yyyy"
                className={`form-control ${
                  errors?.[`${document_date}`] ? "is-invalid" : "is-valid"
                }`}
                placeholderText="DD/MM/YYYY"
                showIcon
                minDate={new Date().setDate(new Date().getDate() + 1)}
                onBlur={(e: any) => {
                  handleBlur(e, document_date);
                }}
              />
              <div className="text-danger">{errors?.[`${document_date}`]}</div>
            </div>
          </FormGroup>
        </div>
        <FormGroup>
          <Label>Upload Document</Label>
          <div className="file-upload">
            <p id={document_img}>Click here to upload Document</p>

            <input
              type="file"
              onChange={(e) => handleMultipleImagesChange(e, document_img)}
              onBlur={(e: any) => {
                handleBlur(e, document_img);
              }}
            />

            {formData?.[`${document_img}`] && (
              <div
                style={{
                  margin: "10px",
                  border: `2px solid ${formData?.document_img?.borderColor}`,
                  borderRadius: "5px",
                  padding: "5px",
                  alignItems: "center",
                }}
              >
                <div
                  className="sub-loop"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    {formData?.[`${document_img}`]?.documentType ? (
                      <Badge
                        color="warning"
                        style={{ position: "absolute" }}
                        onClick={() => removeImage(document_img)}
                      >
                        <Button
                          close
                          aria-label="Cancel"
                          onClick={() => removeImage(document_img)}
                        />
                      </Badge>
                    ) : null}
                    {formData?.[`${document_img}`]?.file?.type?.includes(
                      "application/pdf"
                    ) ||
                    formData?.[`${document_img}`]?.documentType === "pdf" ? (
                      <iframe
                        src={
                          formData?.[`${document_img}`].preview ||
                          formData?.[`${document_img}`]?.documentPath
                        }
                        style={{ width: "150px", height: "200px" }}
                      />
                    ) : formData?.[`${document_img}`]?.file?.type?.includes(
                        "image/"
                      ) ||
                      ["png", "jpg", "jpeg", "gif", "jif"].includes(
                        formData?.[`${document_img}`]?.documentType
                      ) ? (
                      <img
                        src={
                          formData?.[`${document_img}`].preview ||
                          formData?.[`${document_img}`]?.documentPath
                        }
                        alt={`Document`}
                        style={{ width: "150px", height: "200px" }}
                      />
                    ) : formData?.[`${document_img}`]?.documentPath ? (
                      <div>
                        <img
                          className="error-doc img-fluid"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}error.jpg`}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {formData?.[`${document_img}`]?.isValid && (
                      <>
                        {" "}
                        {!formData?.[`${document_img}`]?.isValid && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                              whiteSpace: "pre-wrap",
                              lineHeight: "1.5",
                            }}
                          >
                            {formData?.[`${document_img}`]?.errors?.join(", ")}{" "}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {errors?.[`${document_img}`] && (
            <div className="text-danger">{errors?.[`${document_img}`]}</div>
          )}
        </FormGroup>
      </CardBody>
    </>
  );
}
