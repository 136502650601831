import { Card, CardBody, Table } from "reactstrap";
import { FullCalendarIndex } from "../../../../components/FullCander/FullViewCalander";
import {
  convertTo12HourFormat,
  formatAmount,
} from "../../../../Utils/helpers/helpers";
import Nodata from "../../../../components/Nodata/NodataFound";
import NodataList from "../../../../components/Nodata/NodataFoundList";

export default function Rates({ data_path }: any) {
  const order = ["Weekday", "Saturday", "Sunday", "PublicHoliday"]; // Desired order

  const reordered = order?.map((name, index) => {
    // Find the item matching the name in the desired order
    const matchedItem = data_path?.caregiver_charges?.find(
      (item: any) => item.dayType.name === name
    );

    // If a match is found, update the name for "PublicHoliday" and return it
    return matchedItem
      ? {
          ...matchedItem,
          dayType: {
            ...matchedItem.dayType,
            name: name === "PublicHoliday" ? "Public Holiday" : name,
          },
        }
      : null; // Return null if no match is found
  });

  // Filter out any null values (in case an item is not found)
  const filteredReordered = reordered?.filter((item) => item !== null);

  return (
    <>
      {" "}
      <div className="tab-pane fade show active">
        {/* <h4>Availability</h4> */}
        <div id="calendar" className="available-calender mt-20">
          {/* <FullCalendarIndex
            availabilityDetails={data_path?.availability_details}
          /> */}
          <Card>
            <CardBody>
              {" "}
              <Table striped>
                {filteredReordered?.length === 0 ? (
                  <>
                    <NodataList />
                  </>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredReordered?.map((item: any) => {
                        return (
                          <>
                            <tr>
                              <td>{item?.dayType?.name}s</td>
                              <td>${formatAmount(item?.rate)}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
