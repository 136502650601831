import { useRef, useState } from "react";
import Select from "react-select";
import { Col, Input, Label, Row } from "reactstrap";
import ModalContainers from "../../../../../components/Modal/ModalContainers";
import SuccessModal from "./SuccessCancelModal";
import { useQuery } from "react-query";
import { getCareSeekerRealtionList } from "../../../../../Api/WebsiteApi";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import { BookingClickHandler } from "./SubmitHandlers";
import moment from "moment";
import {
  calculateHoursDifference,
  calculateHoursDifference_,
} from "../../../../../Utils/helpers/helpers";
import TaskListForm from "./TaskList";
import SelectComponent from "../../../../../components/ReactSelect/ReactSelect";

export default function OtpModal({
  isClose_1,
  isClose_2,
  data_full,
  email,
  service_providers,
  values_,
  amount,
}: any) {
  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
  };
  const [all_data, setALlData] = useState([]);
  const [value, setValue] = useState<any>();
  // const { data: date_time, isLoading: date_timeLoading }: any = useQuery(
  //   [`date_time`],
  //   () => getCareSeekerRealtionList(),
  //   {
  //     keepPreviousData: true,
  //     staleTime: 1000,
  //   }
  // );
  const inputRefs = useRef<any>([]);

  const handleChange = (
    e: { target: { value: any } },
    index: number,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean
      ): Promise<void | FormikErrors<{ otp: string[] }>>;
      (arg0: string, arg1: any): void;
    }
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      // Ensure only a single digit is entered
      setFieldValue(`otp[${index}]`, value);

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    e: { key: string; target: { value: string } },
    index: number,
    setFieldValue: any
  ) => {
    if (e.key === "Backspace") {
      if (e.target.value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        setFieldValue(`otp[${index}]`, "");
      }
    }
  };
  const validationSchema = Yup.object().shape({
    // emailOrPhone: Yup.string().required("Email or Phone is required"),
    selectedService: Yup.string().required("Please select a service for"),
    tasks: Yup.array()
      .of(
        Yup.object({
          taskName: Yup.string().required("Task name is required"),
        })
      )
      .required("At least one task is required"),
  });
  console.log(data_full, "data_full");
  const data_option = data_full?.data?.careseekers?.map((item: any) => ({
    value: item?.id,
    label: `${item?.firstName} ${item?.lastName} `,
  }));
  return (
    <>
      <style>
        {`
              .care-seeker-input input {
              height: auto;
              }
        `}
      </style>

      <div className="slect-time-slots confirm-booking-slot">
        {/* <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle"></h5>
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => isClose()}
          >
            <span aria-hidden="true">
              <i className="ri-close-fill"></i>
            </span>
          </button>
        </div> */}

        <div className="modal-body px-4 py-4">
          <div className="text-center pt-3">
            <h5 className="mb-4" style={{ fontSize: 50 }}>
              <i className="ri-checkbox-circle-fill text-success" />
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="success-message text-center">
                <h4 className="show-title mb-3">Time slot available</h4>
                <p className="show-date-time date">
                  {" "}
                  {moment().format("dddd")},
                  {moment(values_?.start_date).format("DD MMM YYYY")}
                </p>
                <p className="show-date-time time">
                  {moment(values_?.start_time).format("hh:mm A")} -{" "}
                  {moment(values_?.end_time).format("hh:mm A")}{" "}
                  <span>
                    (
                    {calculateHoursDifference_(
                      values_?.start_time,
                      values_?.end_time
                    )}{" "}
                    hr)
                  </span>
                </p>
                <p className="show-content hour-rate">
                  <span className="color-brand-2">${amount}</span> is Charged
                </p>
              </div>
              <hr />
              <Formik
                initialValues={{
                  instruction: "",
                  emailOrPhone: email,
                  selectedService: "",
                  tasks: [
                    { taskName: "" }, // Example of an initial task
                  ],
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  // console.log("Form values:", values);
                  // centeredToggle();
                  // setValue(2);
                  BookingClickHandler(
                    values,
                    data_full,
                    setCentered,
                    service_providers,
                    setValue,
                    values_,
                    setALlData
                  );
                }}
              >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                  <>
                    <Form
                      onSubmit={handleSubmit}
                      className="otp-verification-modal"
                    >
                      <div className="form-group row">
                        <small className="form-text text-muted col-md-12 mb-2">
                          Your registered email address or phone number.
                        </small>
                        <div className="col-md-12">
                          <Field
                            name="emailOrPhone"
                            type="text"
                            className="form-control"
                            placeholder="Enter Email or Phone number"
                            readOnly
                            value={email}
                          />
                          <ErrorMessage
                            name="emailOrPhone"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <small className="form-text text-muted col-md-12 mb-2">
                          Select Care Seeker
                        </small>
                        <div className="col-md-12 care-seeker-input">
                          {/* <Select
                            name="selectedService"
                            value={values?.selectedService}
                            options={data_option ? data_option : []}
                            onChange={(option: any) =>
                              setFieldValue("selectedService", option)
                            }
                            isClearable
                            isSearchable
                          /> */}
                          <SelectComponent
                            // loading={subloading}
                            options={data_option}
                            value={data_option?.find(
                              (option: any) =>
                                option.value === values.selectedService
                            )}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "selectedService",
                                selectedOption?.value
                              );
                            }}
                            error={
                              errors?.selectedService
                                ? errors?.selectedService
                                : ""
                            }
                            isClearable
                          />
                          <div className="text-danger">
                            {errors?.selectedService}
                          </div>
                        </div>
                        {/* <ErrorMessage
                          name="selectedService"
                          component="div"
                          className="text-danger"
                        /> */}
                      </div>
                      {/* <hr /> */}
                      <div className="col-md-12">
                        <small className="form-text text-muted col-md-12 mb-2">
                          Instruction
                        </small>
                        <Field
                          name="instruction"
                          as="textarea"
                          className="form-control"
                          placeholder="Instruction"
                          style={{ height: "100px" }}
                        />
                      </div>
                      <TaskListForm values={values} />

                      <hr />
                      <div className="d-float-center justify-content-center mt-3 mb-0">
                        <button
                          type="button"
                          className="btn btn-outline-secondary me-2"
                          onClick={isClose_2}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-default">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"><i className="ri-close-fill"></i> Cancel</button>
          <Button className="btn btn-default" onClick={()=>centeredToggle()}>Continue <i className="ri-arrow-right-line"></i></Button>
        </div> */}
        {centred && (
          <>
            {" "}
            <ModalContainers
              toggle={centeredToggle}
              isOpen={centred}
              onClosed={onCloseModal}
              titl="Sucess"
              modalBodyClassName={""}
              children={
                <SuccessModal
                  isClose={centeredToggle}
                  value={value}
                  setValue={setValue}
                  isClose_1={isClose_1}
                  isClose_2={isClose_2}
                  all_data={all_data}
                />
              }
              size={"lg"}
              backdrop
            />
          </>
        )}
      </div>
    </>
  );
}
