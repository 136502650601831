import { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

const MasterChildSwitches = () => {
  const [masterSwitch, setMasterSwitch] = useState(false);
  const [childSwitches, setChildSwitches] = useState([false, false, false]);

  // Handle Master Switch Change
  const handleMasterChange = (checked: boolean) => {
    setMasterSwitch(checked);
    setChildSwitches(childSwitches.map(() => checked)); // Set all children to match master
  };

  // Handle Child Switch Change
  const handleChildChange = (index: number, checked: boolean) => {
    const updatedChildren = [...childSwitches];
    updatedChildren[index] = checked;
    setChildSwitches(updatedChildren);

    // If all children are ON, Master should be ON
    if (updatedChildren.every((child) => child)) {
      setMasterSwitch(true);
    } 
    // If any child is OFF, Master should be OFF
    else {
      setMasterSwitch(false);
    }
  };

  return (
    <div>
      {/* Master Switch */}
      <FormGroup switch>
        <Input
          type="switch"
          id="masterSwitch"
          checked={masterSwitch}
          onChange={(e) => handleMasterChange(e.target.checked)}
        />
        <Label for="masterSwitch">Master Switch</Label>
      </FormGroup>

      {/* Child Switches */}
      {childSwitches.map((child, index) => (
        <FormGroup switch key={index}>
          <Input
            type="switch"
            checked={child}
            onChange={(e) => handleChildChange(index, e.target.checked)}
          />
          <Label>Child {index + 1}</Label>
        </FormGroup>
      ))}
    </div>
  );
};

export default MasterChildSwitches;
