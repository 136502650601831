import moment from "moment";
import { calculateAge } from "./helpers";
import constants from "../Constants/Constants";

export const careSeekeAge = (bookingValues: any) => {
  return (
    <>
      {calculateAge(bookingValues?.careseeker_details?.careseeker_details?.dob)}
    </>
  );
};

export const careSeekeMobile = (bookingValues: any) => {
  return <>{bookingValues?.careseeker_details?.mobile}</>;
};
export const careSeekeEmail = (bookingValues: any) => {
  return <>{bookingValues?.careseeker_details?.email}</>;
};
export const careSeekeSurub = (bookingValues: any) => {
  return (
    <>
      {bookingValues?.careseeker_details?.careseeker_details?.suburb?.locality}-
      {bookingValues?.careseeker_details?.careseeker_details?.suburb?.postcode}{" "}
    </>
  );
};
export const careSeekeName = (bookingValues: any) => {
  return (
    <>
      {bookingValues?.careseeker_details?.firstName}{" "}
      {bookingValues?.careseeker_details?.lastName}{" "}
    </>
  );
};
export const careSeekeID = (bookingValues: any) => {
  return <>{bookingValues?.careseeker_details?.careseeker_details?.ussId}</>;
};
export const careSeekeGender = (bookingValues: any) => {
  return (
    <>
      {bookingValues?.careseeker_details?.careseeker_details?.gender?.id === 1
        ? "M"
        : bookingValues?.careseeker_details?.careseeker_details?.gender?.id ===
          2
        ? "F"
        : bookingValues?.careseeker_details?.careseeker_details?.gender?.id ===
          3
        ? "O"
        : ""}
    </>
  );
};
export const careSeekeDOB = (bookingValues: any) => {
  return (
    <>
      {moment(
        bookingValues?.careseeker_details?.careseeker_details?.dob
      ).format("DD MMM YYYY")}
    </>
  );
};
export const careSeekeGenderFull = (bookingValues: any) => {
  return (
    <>
      {bookingValues?.careseeker_details?.careseeker_details?.gender?.id === 1
        ? "Male"
        : bookingValues?.careseeker_details?.careseeker_details?.gender?.id ===
          2
        ? "Female"
        : bookingValues?.careseeker_details?.careseeker_details?.gender?.id ===
          3
        ? "Others"
        : ""}
    </>
  );
};

export const careSeekeFullAddress = (bookingValues: any) => {
  return (
    <>
      #{bookingValues?.careseeker_details?.careseeker_details?.streetNumber},
      {bookingValues?.careseeker_details?.careseeker_details?.addressLineOne},
      {bookingValues?.careseeker_details?.careseeker_details?.addressLineTwo},
      {bookingValues?.careseeker_details?.careseeker_details?.state?.state_name}
    </>
  );
};
export const careSeekerProfilepic = (bookingValues: any) => {
  return (
    <>
      {bookingValues?.careseeker_details?.careseeker_details
        ?.profileImgThumbnail !== ""
        ? bookingValues?.careseeker_details?.careseeker_details
            ?.profileImgThumbnail
        : `${constants.DEFUALT_IMAGE_PROFILE}/avatar/avatar.png`}
    </>
  );
};
