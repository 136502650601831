import { Link } from "react-router-dom";
import constants from "../../../../Utils/Constants/Constants";
import {
  capitalizeFirstLetterOfEachWord,
  dateConvertion,
  truncateText,
} from "../../../../Utils/helpers/helpers";
import { useQuery } from "react-query";
import { getNewsList } from "../../../../Api/WebsiteApi";
import { useState } from "react";
import { Pagination } from "reactstrap";

export default function NewsList() {
  // console.log(pages, "pages");
  const [currentPage, setCurrentPage] = useState(1);
  const goToPage = (page: any) => {
    // if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
    setCurrentPage(page);
    // }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    goToPage(currentPage - 1);
  };
  const [basicTooltip, setBasicTooltip] = useState<any>({});

  const toggle = (index: any) => {
    setBasicTooltip((prev: any) => ({
      ...prev,
      [index]: !prev[index], // Toggle only the current index
    }));
  };
  const {
    data: ListItems,
    error: ListItemsError,
    isLoading: ListItemsLoading,
    refetch: ListItemsListRefetch,
  } = useQuery(
    ["getNewsList", currentPage],
    () => (currentPage ? getNewsList(currentPage) : ""),
    {
      keepPreviousData: true,
    }
  );
  return (
    <>
      {" "}
      <section className="section-box mt-50 mb-50">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
              News and Events
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Get the latest news, updates and tips
            </p>
          </div>
        </div>
        <div className="container">
          <div className="mt-50 home-news-events">
            <div className="box-swiper style-nav-top">
              <div className="swiper-container swiper-group-3 swiper">
                <div className="swiper-wrapper pb-70 pt-5 row">
                  {ListItems?.data?.data?.map((item: any, index: any) => {
                    return (
                      <>
                        <div className="col-md-4 px-3 mt-2" key={index}>
                          <div className="card-grid-3 hover-up wow animate__animated animate__fadeIn">
                            <div className="text-center card-grid-3-image">
                              <a href="#">
                                <figure>
                                  <img
                                    alt=""
                                    src={
                                      item?.imagePath
                                        ? item?.imagePath
                                        : `${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/recognising.png`
                                    }
                                  />
                                </figure>
                              </a>
                            </div>
                            <div className="card-block-info">
                              <div className="tags mb-15">
                                <a className="btn btn-tag" href="#">
                                  {item?.isNews === 1 ? "News" : "Event"}
                                </a>
                              </div>
                              <h5
                                title="COVID-19 emergency leave extended for permanent aged care residents."
                                className="news-events-title"
                              >
                                <Link to={`/view-news-events/${item?.id}`}>
                                  {" "}
                                  {capitalizeFirstLetterOfEachWord(
                                    truncateText(item?.title, 30)
                                  )}
                                </Link>
                              </h5>
                              <p className="mt-10 color-text-paragraph font-sm">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: capitalizeFirstLetterOfEachWord(
                                      truncateText(item?.content, 100)
                                    ),
                                  }}
                                ></div>
                              </p>
                              <div className="card-2-bottom mt-20">
                                <div className="row">
                                  <div className="col-lg-6 col-6">
                                    <div className="d-flex">
                                      <img
                                        className="img-rounded"
                                        src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/admin.png`}
                                        alt=""
                                      />
                                      <div className="info-right-img">
                                        <span className="font-sm font-bold color-brand-1 op-70">
                                          Admin
                                        </span>
                                        <br />
                                        <span className="font-xs color-text-paragraph-2">
                                          {dateConvertion(item?.publishDate)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 text-end col-6 pt-15">
                                    <span className="color-text-paragraph-2 font-xs">
                                      {item?.readingTime} mins to read
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className="paginations m-0 mt-0 col-md-12 text-end">
                    <Pagination
                      goToPreviousPage={goToPreviousPage}
                      goToPage={goToPage}
                      goToNextPage={goToNextPage}
                      totalPages={ListItems?.data?.meta?.lastPage}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
