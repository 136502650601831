import { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import ModalContainers from "../../../../../components/Modal/ModalContainers";
import ConformModal from "./ConfirmModal";
import DatePickerReact from "../../../../../components/DatePicker/DatePickerReact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { max } from "moment";
import { getAvalibletIme } from "../../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AmountClickHandler } from "./SubmitHandlers";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ResponsiveTimePickers from "../../../../../components/TimePicker/TimePicker";
import { YES_NO } from "../../../../../Utils/Constants/select_options";

export default function SlotModal({
  isClose,
  caregiver,
  service_providers,
  all_data,
}: any) {
  const initialValues = {
    timeFrom: null,
    timeTo: null,
    date: new Date(),
    driving_assistance_required: "",
    km_driven: "",
  };
  const [date_select, setDateSelect] = useState(new Date());
  const validationSchema = Yup.object().shape({
    driving_assistance_required: Yup.boolean().required(
      "This field is required"
    ),

    km_driven: Yup.string().when("driving_assistance_required", {
      is: true,
      then: (schema: any) =>
        schema
          .matches(
            /^\d+(\.\d{1,2})?$/,
            "Must be a valid number with up to 2 decimal places"
          )
          .required("Cover Kms per day field is required"),
      // Ensures end_date is not in the future
      otherwise: (schema) => schema.notRequired(),
    }),
    timeFrom: Yup.date()
      .nullable()
      .required("Time from is required")
      .typeError("Time from must be a valid time")
      .test(
        "is-future-time-from",
        "Past time are not allowed",
        function (value) {
          const { date } = this.parent;
          if (!value || !date) return true;

          const selectedDate = new Date(date).setHours(0, 0, 0, 0);
          const today = new Date().setHours(0, 0, 0, 0);
          const now = new Date();

          // If the selected date is today, ensure timeFrom is in the future
          if (selectedDate === today && new Date(value) < now) {
            return false;
          }
          return true;
        }
      ),

    timeTo: Yup.date()
      .nullable()
      .required("Time to is required")
      .typeError("Time to must be a valid time")
      .test(
        "is-after-time-from",
        "Time to must be at least 1:00 hr after time from",
        function (value) {
          const { timeFrom } = this.parent;
          if (!value || !timeFrom) return true;

          const timeFromMs = new Date(timeFrom).getTime();
          const timeToMs = new Date(value).getTime();

          return timeToMs - timeFromMs >= 60 * 60 * 1000; // Ensure at least 30 minutes difference
        }
      )
      .test("is-future-time-to", "Past time are not allowed", function (value) {
        const { date } = this.parent;
        if (!value || !date) return true;

        const selectedDate = new Date(date).setHours(0, 0, 0, 0);
        const today = new Date().setHours(0, 0, 0, 0);
        const now = new Date();

        // If the selected date is today, ensure timeTo is in the future
        if (selectedDate === today && new Date(value) < now) {
          return false;
        }
        return true;
      }),
  });

  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => setCentered(!centred);

  const timeSlotArray: any = [
    { id: 1, time: "08:00 AM - 10:00 AM" },
    { id: 2, time: "06:30 PM - 07:30 PM" },
  ];

  const formatTimeSlots = (timeSlots: any) => {
    return timeSlots.flatMap((slot: any) => {
      const [start, end] = slot.time.split(" - ");
      return [
        moment(start, "hh:mm A").toDate(),
        moment(end, "hh:mm A").toDate(),
      ];
    });
  };

  const availableTimes = formatTimeSlots(timeSlotArray);
  const [amount, setAmount] = useState<any>(0);
  const [all_avli, setAllVali] = useState(false);
  const { data: date_time, isLoading: date_timeLoading }: any = useQuery(
    [`date_time`, date_select, all_avli],
    () =>
      getAvalibletIme(
        moment(date_select ? date_select : new Date()).format("YYYY-MM-DD"),
        caregiver,
        all_avli
      ),
    {
      // keepPreviousData: true,
      staleTime: 1000,
    }
  );

  const [isDisabled, setIsDisabled] = useState(true); // Control this based on your logic
  // Group by day

  console.log(amount, "amount", amount);
  const [values_, setValues] = useState<any>({
    start_date: "",
    start_time: "",
    end_time: "",
    driving_assistance_required: "",
    km_driven: "",
  });

  const handleSubmit = (values: any) => {
    console.log("Form submitted with values:", values);
    // centeredToggle();
    AmountClickHandler(values, setAmount, caregiver, date_select, setCentered);
    setValues({
      ...values_,
      start_date: date_select,
      start_time: values?.timeFrom,
      end_time: values?.timeTo,
      driving_assistance_required: values?.driving_assistance_required,
      km_driven: values?.km_driven,
    });
  };
  const [end_date, setENd_date] = useState<any>();
  const givenDate = (date: any) => {
    const givenDate_ = moment(date, "YYYY-MM-DD");

    // Add 6 days to the given date
    const resultDate = givenDate_.add(6, "days");

    // Format the new date
    const formattedDate: any = moment(resultDate).format("DD MMM YYYY"); // '13/11/2024'
    console.log(resultDate, "resultDate");
    setENd_date(formattedDate);
  };
  function getTimesBetween(startTime: any, endTime: any) {
    console.log(startTime, "startTime", endTime);
    const times = [];

    let start = new Date(`1970-01-01T${startTime}`);
    let end = new Date(`1970-01-01T${endTime}`);
    console.log(end, start, "anu");
    // Loop through the time range and push times to the array
    while (start <= end) {
      times.push(start.toTimeString().substring(0, 5)); // Format time as HH:MM
      start.setMinutes(start.getMinutes() + 30); // Increment by 30 minutes
    }

    return times;
  }
  useEffect(() => {
    if (all_avli === true) {
      findLarge();
    }
  }, [all_avli]);
  const [max_min, setmax] = useState({ max: "", min: "" });
  const findLarge = () => {
    let earliestTime: any = null;
    let latestTime: any = null;

    // Loop through the data to find the earliest and latest times
    if (date_time?.length !== 0) {
      date_time?.forEach((item: any) => {
        const startTime = new Date(`1970-01-01T${item.start_time}`); // Use a consistent date format
        const endTime = new Date(`1970-01-01T${item.end_time}`); // Same for end time

        if (earliestTime === null || startTime < earliestTime) {
          earliestTime = startTime; // Update the earliest time if a new earlier time is found
        }
        if (latestTime === null || endTime > latestTime) {
          latestTime = endTime; // Update the latest time if a new later time is found
        }
      });
      const earliestTimeString = moment(earliestTime).format("HH:mm:ss");
      const latestTimeString = moment(latestTime).format("HH:mm:ss");
      setmax({
        ...max_min,
        max: latestTimeString,
        min: earliestTimeString,
      });
      console.log("Earliest time:", earliestTimeString);
      console.log("Latest time:", latestTimeString);
    }
  };

  // Format the results back to time strings if needed

  const startTime = date_time?.start_time
    ? date_time?.start_time
    : max_min?.min; // example start time
  const endTime = date_time?.end_time ? date_time?.end_time : max_min?.max; // example end time
  const result = getTimesBetween(startTime, endTime);

  return (
    <>
      <style>
        {`label {
  font-weight: 600;
  color: #555;
}
  .modal-header {
  background-color: #53aeb2;
  color: white;
  padding: 1rem;
  border-bottom: 2px solid #53aeb2;
}
  .modal-title {
  font-size: 1.25rem;
  font-weight: bold;
 
}
  .modal-body {
  padding: 1.5rem;
}
  .available-box {
  background-color: #f8f9fa;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 1rem;
}
  .btn-available {
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  font-size: 0.85rem;
}
  .btn-available.text-success {
  background-color: #d4edda;
  color: #155724;
}
.btn-available.text-danger {
  background-color: #f8d7da;
  color: #721c24;
}
  availability-info h6 {
  font-size: 1.1rem;
  font-weight: 600;
}
  .form-control.time-picker .MuiInputBase-formControl {
    padding-right: 0;
  }
  .form-control.time-picker .MuiInputBase-formControl input {
    height: auto;
    font-weight: 500 !important;
    color: #000 !important; 
    border: 0;   
  }
  .available-time button { margin-left: -45px !important; margin-right: 0; }
  .MuiStack-root, .css-11a8txn-MuiStack-root {
  width: 100%;
  }

`}
      </style>
      <div className="slect-time-slots">
        <div className="modal-header">
          <h5 className="modal-title text-white">Select your time slot</h5>
          <button type="button" className="close" onClick={() => isClose()}>
            <span aria-hidden="true">
              <i className="ri-close-fill"></i>
            </span>
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <>
              <Form className="form-section">
                <div className="modal-body p-4 pt-0">
                  <div className="modal-body p-4 px-0">
                    <div className="calender-time-right row">
                      <div className="mb-0 col-md-12">
                        <label className="d-block">Select Date</label>
                        <DatePickerReact
                          selected={date_select}
                          onChange={(date: any) => {
                            setDateSelect(date);
                            givenDate(date);
                            setFieldValue("date", date);
                          }}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          placeholderText="DD/MM/YYYY"
                          className="form-control"
                          showIcon
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="calender-time-right">
                    <div className="available-box mb-3 col-md-12">
                      <h6>
                        {all_avli === true ? (
                          <>
                            <span className="avail-between01">
                              Availability between:
                            </span>{" "}
                            <p className="heading-sm-1">
                              {moment(date_select).format("DD MMM YYYY")}&nbsp;(
                              {moment(date_select).format("dddd")}) to{" "}
                              {end_date}
                              &nbsp;(
                              {moment(end_date).format("dddd")})
                            </p>
                          </>
                        ) : (
                          <>
                            {" "}
                            Availability on{" "}
                            {moment(date_select).format("DD MMM YYYY")}(
                            {moment(date_select).format("dddd")}){" "}
                          </>
                        )}
                      </h6>
                      <hr />
                      <div className="mb-0 col-md-12">
                        {/* <label className="d-block "><Input type="checkbox" className=""/><span className="px-2">Veiw All Availability</span> </label>
                         */}
                        <Input
                          type="checkbox"
                          name="all_avli"
                          checked={all_avli} // Controlled component
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setAllVali(e.target.checked);
                            givenDate(date_select ? date_select : new Date());
                          }}
                        />{" "}
                        &nbsp;
                        <Label className="mb-2">View All Days</Label>
                      </div>
                      {/* <div className="row">
                   <div className="col-3">Day</div>
                   <div className="col-6">Time</div>
                 </div> */}
                      {date_timeLoading ? (
                        <Skeleton count={7} />
                      ) : (
                        <>
                          {all_avli === false ? (
                            <div className="availability-row row soft-green p-2 mb-0">
                              <div className="col-4">
                                <span className="availability-day text-dark">
                                  {date_time?.day}
                                </span>
                              </div>
                              <div className="col-8">
                                <a
                                  className={`btn btn-available btn-sm ${
                                    date_time?.start_time
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                >
                                  {date_time?.start_time ? (
                                    <>
                                      {moment(
                                        date_time?.start_time,
                                        "HH:mm"
                                      ).format("hh:mm A")}{" "}
                                      -{" "}
                                      {moment(
                                        date_time?.end_time,
                                        "HH:mm"
                                      ).format("hh:mm A")}
                                    </>
                                  ) : (
                                    "Unavailable"
                                  )}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="row">
                                {date_time?.map((item: any, index: any) => {
                                  return (
                                    <>
                                      {" "}
                                      <div className="col-md-6 all-date-show">
                                        <div className="availability-row row soft-green p-2 mb-0">
                                          <div className="col-4">
                                            <span className="availability-day text-dark">
                                              {item?.day}
                                            </span>
                                          </div>
                                          <div className="col-8">
                                            <a
                                              className={`btn btn-available btn-sm ${
                                                item?.start_time
                                                  ? "text-success"
                                                  : "text-danger"
                                              }`}
                                            >
                                              {item?.start_time ? (
                                                <>
                                                  {moment(
                                                    item?.start_time,
                                                    "HH:mm"
                                                  ).format("hh:mm A")}{" "}
                                                  -{" "}
                                                  {moment(
                                                    item?.end_time,
                                                    "HH:mm"
                                                  ).format("hh:mm A")}
                                                </>
                                              ) : (
                                                "Unavailable"
                                              )}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {/* <div className="unavailable-box my-3">
                 <div className="soft-grey p-0">
                   <p className="mb-2">
                     <span className="red-point text-dark">
                       Unavailable Slots (Already booked)
                     </span>
                   </p>
                   <div className="day-comment d-float-center gap-15">
                     <a className="btn btn-unavailable mr-5 mt-2">
                       08:00 AM - 10:00 AM
                     </a>
                     <a className="btn btn-unavailable mr-5 mt-2">
                       01:00 PM - 02:00 PM
                     </a>
                   </div>
                 </div>
               </div> */}
                    {/* <div className="col-md-6 mb-3">
                 <label>Time From</label>
                 <Field name="timeFrom">
                   {({ field }: any) => (
                     <DatePicker
                       selected={field.value}
                       showTimeSelect
                       showTimeSelectOnly
                       timeIntervals={60}
                       timeCaption="Time"
                       dateFormat="h:mm aa"
                       placeholderText="Select a time"
                       className="px-4"
                       onChange={(date) => setFieldValue("timeFrom", date)}
                       showIcon
                       icon={
                         <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 24 24"
                           fill="currentColor"
                         >
                           <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                         </svg>
                       }
                       // includeTimes={result?.map(
                       //   (item) => new Date(`1970-01-01T${item}`)
                       // )}
                     />
                   )}
                 </Field>
                 <ErrorMessage
                   name="timeFrom"
                   component="div"
                   className="text-danger"
                 />
               </div>
               <div className="col-md-6 mb-3">
                 <label>Time To</label>
                 <Field name="timeTo">
                   {({ field }: any) => (
                     <DatePicker
                       selected={field.value}
                       showTimeSelect
                       showTimeSelectOnly
                       timeIntervals={60}
                       timeCaption="Time"
                       dateFormat="h:mm aa"
                       placeholderText="Select a time"
                       className="px-4"
                       onChange={(date) => setFieldValue("timeTo", date)}
                       showIcon
                       icon={
                         <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 24 24"
                           fill="currentColor"
                         >
                           <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                         </svg>
                       }
                       // includeTimes={result?.map(
                       //   (item) => new Date(`1970-01-01T${item}`)
                       // )}
                     />
                   )}
                 </Field>
                 <ErrorMessage
                   name="timeTo"
                   component="div"
                   className="text-danger"
                 />
               </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-6 available-time">
                      <ResponsiveTimePickers
                        label="Time From"
                        name={"timeFrom"}
                        onChange={(e: any) => {
                          if (e?.$d instanceof Date) {
                            setFieldValue("timeFrom", e?.$d);
                          }
                        }}
                        value={values?.timeFrom}
                        className={`form-control time-picker ${
                          errors.timeFrom && touched?.timeFrom ? "" : ""
                        }`}
                        selectedDate={date_select}
                      />
                      <ErrorMessage
                        name="timeFrom"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6 available-time">
                      <ResponsiveTimePickers
                        label="Time To"
                        name={"timeTo"}
                        onChange={(e: any) => {
                          if (e?.$d instanceof Date) {
                            setFieldValue("timeTo", e?.$d);
                          }
                        }}
                        value={values?.timeTo}
                        className={`form-control time-picker ${
                          errors?.timeTo ? "" : ""
                        }`}
                        selectedDate={date_select}
                      />
                      <ErrorMessage
                        name="timeTo"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {all_data?.validLicense === 1 && (
                      <>
                        {" "}
                        <div className="col-md-12 available-time mt-3">
                          <Label>
                            Do you require additional driving assistance?
                          </Label>
                          <Field
                            as="select"
                            name="driving_assistance_required"
                            className={`form-control ${
                              errors?.driving_assistance_required
                                ? "is-invalid"
                                : "is-valid"
                            }`}
                          >
                            <option value="">Select</option>
                            {YES_NO.map((option: any) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="driving_assistance_required"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-12 available-time mt-3">
                          <Label>Kilometers driven</Label>
                          <Field
                            name="km_driven"
                            value={values?.km_driven}
                            className={`form-control ${
                              errors?.km_driven ? "is-invalid" : "is-valid"
                            }`}
                          />
                          <ErrorMessage
                            name="km_driven"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="modal-footer mt-3">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={isClose}
                    >
                      <i className="ri-close-fill text-uppercase"></i> Cancel
                    </button>
                    <button type="submit" className="btn btn-default">
                      Continue <i className="ri-arrow-right-line"></i>
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
      {centred && (
        <ModalContainers
          toggle={centeredToggle}
          isOpen={centred}
          onClosed={onCloseModal}
          titl="Success"
          modalBodyClassName=""
          children={
            <ConformModal
              isClose_1={centeredToggle}
              amount={amount}
              values={values_}
              service_providers={service_providers}
              caregiver={caregiver}
            />
          }
          size="lg"
          backdrop
        />
      )}
    </>
  );
}
