import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { FieldArray, ErrorMessage, Field, FormikErrors } from "formik";
import DatePicker from "react-datepicker"; // Assuming you're using DatePicker
import "react-datepicker/dist/react-datepicker.css";

const AvailabilityForm = ({
  values,
  setFieldValue,
  handleStartDateChange,
  daysOfWeek,
  errors,
  touched,
}: any) => {
  console.log(errors, "ashchags");
  const [masterSwitch, setMasterSwitch] = useState(values.all_day_availability);

  // Sync the master switch with form values
  useEffect(() => {
    setMasterSwitch(values.all_day_availability);
  }, [values.all_day_availability]);

  // Handler to toggle the master switch and update all children
  const handleMasterSwitch = () => {
    setMasterSwitch(!masterSwitch);
    // Set all children to the same value as the master
    values.availability_details.forEach((detail: any, index: any) => {
      setFieldValue(
        `availability_details.${index}.toggleSwitch`,
        !masterSwitch
      );
    });
  };

  // Handler to toggle individual child switches
  const handleSwitchChange = (index: number) => {
    const updatedAvailabilityDetails = [...values.availability_details];
    const currentSwitchValue = updatedAvailabilityDetails[index].toggleSwitch;

    // Toggle the individual child switch (day-specific)
    updatedAvailabilityDetails[index].toggleSwitch = !currentSwitchValue;

    // Check if any child is OFF
    const anyChildIsOff = updatedAvailabilityDetails.some(
      (detail) => !detail.toggleSwitch
    );

    // Check if all children are ON
    const allChildrenAreOn = updatedAvailabilityDetails.every(
      (detail) => detail.toggleSwitch
    );

    // Update the field value with modified details
    setFieldValue("availability_details", updatedAvailabilityDetails);

    // Determine the Master switch value
    if (allChildrenAreOn) {
      // If all child switches are ON, turn Master ON
      setFieldValue("all_day_availability", true);
    } else if (anyChildIsOff) {
      // If any child switch is OFF, turn Master OFF
      setFieldValue("all_day_availability", false);
    }
  };

  return (
    <Row className="availability-row-form">
      <div className="col-md-12">
        <Label className=" mb-20">Availability</Label>
      </div>
      <Col lg="4">
        <FormGroup switch>
          <Input
            type="switch"
            checked={masterSwitch}
            onChange={handleMasterSwitch} // Toggle Master switch
          />
          <Label className="mb-0" check>
            {masterSwitch ? "All Day Available" : "Not All Day Available"}
          </Label>
        </FormGroup>
      </Col>
      <Col lg="4">
        <FormGroup>
          <Label for="start_time">Start Time</Label>
          <DatePicker
            selected={masterSwitch ? values.start_time : null}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="h:mm aa"
            placeholderText="Select a time"
            className={`px-4 form-control ${
              errors?.start_time && touched?.start_time
                ? "is-invalid"
                : "is-valid"
            }`}
            onChange={(date) => handleStartDateChange("start_time", date)}
            disabled={!masterSwitch}
          />
          <ErrorMessage
            name="start_time"
            component="div"
            className="text-danger"
          />
        </FormGroup>
      </Col>
      <Col lg="4">
        <FormGroup>
          <Label for="end_time">End Time</Label>
          <DatePicker
            selected={masterSwitch ? values.end_time : null}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="h:mm aa"
            placeholderText="Select a time"
            className={`px-4 form-control ${
              errors?.end_time && touched?.end_time ? "is-invalid" : "is-valid"
            }`}
            onChange={(date) => handleStartDateChange("end_time", date)}
            disabled={!masterSwitch}
          />
          <ErrorMessage
            name="end_time"
            component="div"
            className="text-danger"
          />
        </FormGroup>
      </Col>

      <hr />

      <FieldArray name="availability_details">
        {({ insert, remove, push }) => (
          <>
            {values?.availability_details?.length > 0 &&
              values?.availability_details?.map((detail: any, index: any) => (
                <div key={index} className="mb-3">
                  <Row>
                    <Col>
                      <FormGroup switch>
                        <Input
                          type="switch"
                          checked={detail?.toggleSwitch}
                          name={`availability_details.${index}.toggleSwitch`}
                          onChange={() => handleSwitchChange(index)} // This will toggle individual availability
                        />
                        <Label className="mb-0" check>
                          {detail?.toggleSwitch ? "Available" : "Not Available"}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={`availability_details.${index}.day`}>
                          Day
                        </Label>
                        <Field
                          as="select"
                          name={`availability_details.${index}.day`}
                          className="form-control"
                          disabled
                        >
                          {daysOfWeek
                            .filter(
                              (day: any) =>
                                !values.availability_details.some(
                                  (detail: any, i: any) =>
                                    detail.day === day.value && i !== index
                                )
                            )
                            .map((day: any) => (
                              <option key={day.value} value={day.value}>
                                {day.label}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name={`availability_details.${index}.day`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={`availability_details.${index}.start_time`}>
                          Start Time
                        </Label>
                        <DatePicker
                          selected={
                            detail?.toggleSwitch && masterSwitch
                              ? values.availability_details?.[index]
                                  ?.start_time || values.start_time
                              : detail?.toggleSwitch && !masterSwitch
                              ? values.availability_details?.[index]?.start_time
                              : !detail?.toggleSwitch && !masterSwitch
                              ? null
                              : null
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select a time"
                          className={`px-4 form-control ${
                            (
                              errors?.availability_details?.[
                                index
                              ] as FormikErrors<{
                                start_time: string;
                                end_time: string;
                                day: string;
                                toggleSwitch: boolean;
                              }>
                            )?.start_time &&
                            (
                              touched?.availability_details?.[
                                index
                              ] as FormikErrors<{
                                start_time: string;
                                end_time: string;
                                day: string;
                                toggleSwitch: boolean;
                              }>
                            )?.start_time
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={(date) =>
                            setFieldValue(
                              `availability_details.${index}.start_time`,
                              date
                            )
                          }
                          disabled={!detail?.toggleSwitch}
                        />
                        <ErrorMessage
                          name={`availability_details.${index}.start_time`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for={`availability_details.${index}.end_time`}>
                          End Time
                        </Label>
                        <DatePicker
                          selected={
                            detail?.toggleSwitch && masterSwitch
                              ? values.availability_details?.[index]
                                  ?.end_time || values.end_time
                              : detail?.toggleSwitch && !masterSwitch
                              ? values.availability_details?.[index]?.end_time
                              : !detail?.toggleSwitch && !masterSwitch
                              ? null
                              : null
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select a time"
                          className={`px-4 form-control ${
                            (
                              errors?.availability_details?.[
                                index
                              ] as FormikErrors<{
                                start_time: string;
                                end_time: string;
                                day: string;
                                toggleSwitch: boolean;
                              }>
                            )?.end_time &&
                            (
                              touched?.availability_details?.[
                                index
                              ] as FormikErrors<{
                                start_time: string;
                                end_time: string;
                                day: string;
                                toggleSwitch: boolean;
                              }>
                            )?.end_time
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={(date) =>
                            setFieldValue(
                              `availability_details.${index}.end_time`,
                              date
                            )
                          }
                          disabled={!detail?.toggleSwitch}
                        />
                        <ErrorMessage
                          name={`availability_details.${index}.end_time`}
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              ))}
          </>
        )}
      </FieldArray>
    </Row>
  );
};

export default AvailabilityForm;
