import { Card, CardBody, CardHeader, Container } from "reactstrap";
import constants from "../../../Utils/Constants/Constants";

export default function NDISApprovalExpired() {
  return (
    <>
      <style>{`i {
    font-style: italic; /* This is the default style for <i> */
    color: gray;
}`}</style>
      <Container className="ndis-container my-4">
        <Card className="ndis-page">
          <CardHeader className="p-25">
            <table className="w-100 ndis-table ndis-head">
              <thead>
                <tr>
                  <th>
                    <h3 className="m-0 ndis-title-head">NDIS approval</h3>
                  </th>
                  <th>
                    <div className="header-logo">
                      <a className="logo-link" href="#">
                        <img
                          className="uss-logo01"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
                          alt="USS Logo"
                        />
                      </a>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </CardHeader>
          <CardBody className="p-25">
            <table className="w-100 ndis-table ndis-body">
              <tbody>
                <tr>
                  {" "}
                  <span className="input" style={{ fontSize: "15px" }}>
                    NDIS approval Link Expired
                  </span>
                </tr>
                <br />
                <tr>
                  <span className="input" style={{ fontSize: "15px" }}>
                    Dear User ,
                  </span>
                </tr>
                <br />
                <tr>
                  <span className="input" style={{ fontSize: "15px" }}>
                    {" "}
                    The NDIS approval link you used has expired. To reset this
                    link or for further assistance, please contact our support
                    team at
                    <strong>
                      <a href="mailto:admin@ustaffings.com">
                        {" "}
                        admin@ustaffings.com
                      </a>
                    </strong>
                    .
                  </span>
                </tr>
                <br />
                <tr>
                  <span className="input" style={{ fontSize: "15px" }}>
                    We apologize for the inconvenience.
                  </span>
                </tr>
                <p className="mt-5">
                  <i>
                    Note: Please ensure that the email includes the
                    corresponding NDIS ID. For example, the request email should
                    contain the NDIS ID in this format: NDIS-XXXXXXX
                  </i>
                </p>
                <br />
                <tr>Best regards,</tr>
                <p>USStaffing Ltd.</p>
                <p>1300 052 00</p>
                <p>connect@ustaffings.com</p>
                <p>19 Woronora Ave, Leumeah NSW 2560, AUS</p>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>

      {/* Subscribed Successfully Modal Popup Start  */}
      
{/* Subscribed Successfully Modal Popup End  */}
    </>
  );
}
