/* eslint-disable react/jsx-no-target-blank */
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../Api/BaseUrl";
import ProfilePicture from "../../../components/ProfilePicture/ProfilePicture";
import { getUserData } from "../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import JSZip from "jszip";
import {
  calculateAge,
  convertTo12HourFormat,
  dateConvertion,
} from "../../../Utils/helpers/helpers";
import moment from "moment";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { saveAs } from "file-saver";
const validationSchema = Yup.object().shape({
  declare: Yup.bool().oneOf([true], "You must accept the declaration."),
});

const Step6 = ({ setStep, UserListRefetch_data }: any) => {
  const {
    data: Userdata,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery([`user`], () => getUserData(), {
    keepPreviousData: true,
  });
  const navigate = useNavigate();
  let actionType = ""; // Track button click
  const handleButtonClick = (type: string) => {
    actionType = type; // Track which button is clicked
  };
  const ProfileCompleteHandler = () => {
    const formData = new FormData();
    formData.append("step", "6");
    const set_: any = true;

    formData.append("registration_confirmation_declaration", set_);
    axios({
      method: "post",
      url: `${BaseUrl}/user/caregiver_complete_registration`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response: any) {
        if (response?.data?.status === true) {
          // toast.success("Detiles added successfully");
          Swal.fire({
            title: "Success",
            text: "You're all set and Congratulations! You have successfully created your profile.",
            icon: "success",
          });
          localStorage.removeItem("AUTH_TOKEN");
          navigate("/success?profile_completed=1");
        }
      })
      .catch(function (response) {
        if (response?.response?.data?.errors) {
          // setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          Swal.fire({
            title: "Error",
            text: "Unexpected Error Occurred",
            icon: "error",
          });
          // toast.error("Unexpected Error Occurred");
        }
      });
  };
  const ProfileSubmitHandler = () => {
    const formData = new FormData();
    formData.append("step", "6");
    const set_: any = true;

    // formData.append("registration_confirmation_declaration", set_);
    axios({
      method: "post",
      url: `${BaseUrl}/user/caregiver_complete_registration`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response: any) {
        if (response?.data?.status === true) {
          // toast.success("Detiles added successfully");
          Swal.fire({
            title: "Success",
            text: "Your data is saved  successfully",
            icon: "success",
          });
          // localStorage.removeItem("AUTH_TOKEN");
          // navigate("/success?profile_completed=1");
        }
      })
      .catch(function (response) {
        if (response?.response?.data?.errors) {
          // setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          Swal.fire({
            title: "Error",
            text: "Unexpected Error Occurred",
            icon: "error",
          });
          // toast.error("Unexpected Error Occurred");
        }
      });
  };
  const documentIdArray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "document_id"
  );
  const policeVerificationArray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "police_verification_id"
  );
  const wwccIdArray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "wwcc_id"
  );
  const firstAidCertificateArray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "first_aid_certificate"
  );
  const ndiswcarray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "ndis_worker_clearance_id"
  );
  const medicardarray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "medical_card"
  );
  const drivecardarray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "driving_license_id"
  );
  const vechilecardarray = Userdata?.user_details?.documents?.filter(
    (doc: any) => doc.documentName === "vehicle_insurance_id"
  );
  const downloadZip = async (documents: any, name: any) => {
    const zip = new JSZip();
    const folder: any = zip.folder("downloads");

    for (const file of documents) {
      // console.log(file, "file");
      const response = await fetch(file.documentPath);
      const blob = await response.blob();
      folder.file(file.id, blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${name}.zip`);
    });
  };

  const VieDocuments = (documents: any) => {
    return (
      <>
        {documents?.map((item: any, i: any) => {
          return (
            <>
              <tr>
                <td>
                  {item?.documentName === "document_id"
                    ? "AUSTRALIAN PHOTO ID "
                    : item?.documentName === "medical_card"
                    ? "MEDICARE CARD"
                    : item?.documentName?.replace(/_/g, " ").toUpperCase()}{" "}
                  {item?.documentName === "wwcc_id" ? (
                    <>
                      <span className="text-primary text-uppercase">
                        ({Userdata?.user_details?.wwccType})
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {item?.documentName === "document_id"
                    ? Userdata?.user_details?.documentId
                    : item?.documentName === "medical_card"
                    ? Userdata?.user_details?.medicalCardId
                    : item?.documentName === "police_verification_id"
                    ? Userdata?.user_details?.policeVerificationId
                    : item?.documentName === "first_aid_certificate"
                    ? Userdata?.user_details?.firstAidCertificateId
                    : item?.documentName === "wwcc_id"
                    ? Userdata?.user_details?.wwccId
                    : item?.documentName === "ndis_worker_clearance_id"
                    ? Userdata?.user_details?.ndiswcId
                    : item?.documentName === "driving_license_id"
                    ? Userdata?.user_details?.drivingLicenseId
                    : item?.documentName === "vehicle_insurance_id"
                    ? Userdata?.user_details?.vehicleInsuranceId
                    : ""}
                </td>

                <td>
                  {dateConvertion(
                    item?.documentName === "document_id"
                      ? Userdata?.user_details?.documentIdExpire
                      : item?.documentName === "medical_card"
                      ? Userdata?.user_details?.medicalCardExpire
                      : item?.documentName === "police_verification_id"
                      ? Userdata?.user_details?.policeVerificationIdExpire
                      : item?.documentName === "first_aid_certificate"
                      ? Userdata?.user_details?.firstAidCertificateExpire
                      : item?.documentName === "wwcc_id"
                      ? Userdata?.user_details?.wwccIdExpire
                      : item?.documentName === "ndis_worker_clearance_id"
                      ? Userdata?.user_details?.ndiswcIdExpire
                      : item?.documentName === "driving_license_id"
                      ? Userdata?.user_details?.drivingLicenseExpire
                      : item?.documentName === "vehicle_insurance_id"
                      ? Userdata?.user_details?.vehicleInsuranceId
                      : ""
                  )}
                </td>
                <td>
                  {/* <Button className="btn-dwonload-icon" on>Dwonload</Button> */}
                  <a
                    href={"#"}
                    // target="_blank"
                    onClick={() => {
                      downloadZip(
                        documents,
                        item?.documentName === "document_id"
                          ? "AUSTRALIAN PHOTO ID -" + (i + 1)
                          : item?.documentName === "medical_card"
                          ? "MEDICARE CARD"
                          : item?.documentName?.replace(/_/g, " ").toUpperCase()
                      );
                    }}
                    // download
                  >
                    {" "}
                    <i className="ri-download-line" aria-hidden="true"></i>
                  </a>
                </td>
              </tr>
            </>
          );
        })}
      </>
    );
  };
  return (
    <>
      {!UserListRefetch ? (
        <Skeleton count={12} />
      ) : (
        <Formik
          initialValues={{
            declare: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (actionType === "save") {
              ProfileSubmitHandler();
            } else if (actionType === "complete") {
              ProfileCompleteHandler();
            }
            setSubmitting(false); // Stop submission spinner
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="text-center mb-3 logo-div01">
                <h4 className="block-subTitle text-center ">
                  {/* <span className="uss-text-primary">Create</span> Account */}
                  CONFIRM ALL DETAILS
                </h4>
              </div>
              {UserLoading ? (
                <Spinner className="m-5 text-center" color="primary">
                  Loading...
                </Spinner>
              ) : (
                <>
                  <ProfilePicture
                    name={Userdata?.name}
                    title={""}
                    description={Userdata?.user_details?.aboutYourself}
                    src={`${Userdata?.user_details?.profileImg}`}
                  />
                  <Card className="mt-3">
                    <CardBody className="giver-form-final">
                      {" "}
                      <h6 className="d-float-center justify-content-between mb-3">
                        General Info{" "}
                        <a
                          href="#"
                          onClick={() => {
                            setStep(1);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <i className="ri-edit-box-line"></i> Edit
                        </a>
                      </h6>
                      <Row>
                        <Col lg={4}>
                          <Label>Which service do you offer? </Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.profileType?.name}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>First Name </Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">{Userdata?.firstName}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Last Name </Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">{Userdata?.lastName}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Phone Number</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">{Userdata?.mobile}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Email</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">{Userdata?.email}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Age & DOB </Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {calculateAge(Userdata?.user_details?.dob)},{" "}
                            {moment(Userdata?.user_details?.dob).format(
                              "DD MMM YYYY"
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Street Number </Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.streetNumber}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Address line 1 </Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.addressLineOne}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Address line 2</Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.addressLineTwo !== " "
                              ? Userdata?.user_details?.addressLineTwo
                              : "-"}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>State </Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.state?.state_name}(
                            {Userdata?.user_details?.state?.state_code})
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Address Suburb </Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.addressSuburb?.locality}(
                            {Userdata?.user_details?.addressSuburb?.postcode})
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Gender</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.gender?.id === 1
                              ? "Male"
                              : Userdata?.user_details?.gender?.id === 2
                              ? "Female"
                              : "Others"}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Residency status</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {" "}
                            {Userdata?.user_details?.residencyStatus?.name}
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <h6 className="heading-lg d-float-center justify-content-between mb-3">
                        Biographical Details{" "}
                        <a
                          href="#"
                          onClick={() => {
                            setStep(2);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <i className="ri-edit-box-line"></i> Edit
                        </a>
                      </h6>
                      <Row>
                        <Col lg={4}>
                          <Label>Langauge Spoken</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          {Userdata?.user_details?.languages_spokens?.map(
                            (item: any) => {
                              return (
                                <>
                                  <span className="badge btn-primary light me-2">
                                    {item?.language?.name}
                                  </span>
                                </>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Interest</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          {Userdata?.user_details?.interests?.map(
                            (item: any) => {
                              return (
                                <>
                                  <span className="badge btn-primary light me-2">
                                    {item?.interest?.name}
                                  </span>
                                </>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Care Task</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {Userdata?.user_details?.service_provides?.map(
                            (item: any) => {
                              return (
                                <>
                                  <span className="badge btn-primary light me-2">
                                    {item?.serviceProvides?.name}
                                  </span>
                                </>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Aged Care Experience</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {Userdata?.user_details?.experience_with_aged_cares?.map(
                            (item: any) => {
                              return (
                                <>
                                  <span className="badge btn-primary light me-2">
                                    {item?.experienceWithAgedCare?.name}
                                  </span>
                                </>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Childcare Experience</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={8}>
                          {Userdata?.user_details?.experience_with_children?.map(
                            (item: any) => {
                              return (
                                <>
                                  <span className="badge btn-primary light me-2">
                                    {item?.experienceWithChildren?.name}
                                  </span>
                                </>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Are you ok with pets?</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={4}>
                          {" "}
                          <span>
                            {Userdata?.user_details?.okayWithPets === 1
                              ? "Yes"
                              : "No"}
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <h6 className="heading-lg d-float-center justify-content-between mb-3">
                        Work Experiences{" "}
                        <a
                          href="#"
                          onClick={() => {
                            setStep(2);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <i className="ri-edit-box-line"></i> Edit
                        </a>
                      </h6>
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Company Name</th>
                            <th>Designation</th>
                            <th>Start date</th>
                            <th>End date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Userdata?.user_details?.work_experiences?.map(
                            (item: any) => {
                              return (
                                <>
                                  <tr>
                                    <td>{item?.companyName}</td>
                                    <td>{item?.designation}</td>
                                    <td>{dateConvertion(item?.startDate)}</td>
                                    <td>{dateConvertion(item?.endDate)}</td>
                                    <td></td>
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                      <hr />
                      <h6 className="heading-lg d-float-center justify-content-between mb-3">
                        Qualifications{" "}
                        <a
                          href="#"
                          onClick={() => {
                            setStep(2);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <i className="ri-edit-box-line"></i> Edit
                        </a>
                      </h6>
                      <Table striped>
                        <thead>
                          <tr>
                            <th>course name</th>
                            <th>Institution Name</th>
                            <th>Start date</th>
                            <th>End date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Userdata?.user_details?.qualifications?.map(
                            (item: any) => {
                              return (
                                <>
                                  <tr>
                                    <td>{item?.courseName}</td>
                                    <td>{item?.institutionName}</td>
                                    <td>{dateConvertion(item?.startDate)}</td>
                                    <td>{dateConvertion(item?.endDate)}</td>
                                    <td></td>
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                      <hr />
                      <h6 className="heading-lg d-float-center justify-content-between mb-3">
                        Safety Check Details{" "}
                        <a
                          href="#"
                          onClick={() => {
                            setStep(3);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <i className="ri-edit-box-line"></i> Edit
                        </a>
                      </h6>
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Document name</th>
                            <th>Document Id</th>
                            <th>Expiry date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {VieDocuments(documentIdArray)}
                          {VieDocuments(wwccIdArray)}
                          {VieDocuments(policeVerificationArray)}
                          {VieDocuments(firstAidCertificateArray)}
                          {VieDocuments(ndiswcarray)}
                          {VieDocuments(medicardarray)}
                          {VieDocuments(vechilecardarray)}
                          {VieDocuments(drivecardarray)}
                        </tbody>
                      </Table>
                      <hr />
                      <h6 className="heading-lg d-float-center justify-content-between mb-3">
                        Availability Details{" "}
                        <a
                          href="#"
                          onClick={() => {
                            setStep(4);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <i className="ri-edit-box-line"></i> Edit
                        </a>
                      </h6>
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Day</th>
                            <th>Time</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Userdata?.user_details?.availability_details?.map(
                            (item: any) => {
                              return (
                                <>
                                  <tr>
                                    <td>{item?.day?.name}</td>
                                    <td>
                                      {convertTo12HourFormat(item?.startTime)}{" "}
                                      to {convertTo12HourFormat(item?.endTime)}
                                    </td>
                                    <td>Available</td>
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                      <Row>
                        <Col lg={4}>
                          <Label>Service Provide Suburb </Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.suburb?.locality}(
                            {Userdata?.user_details?.suburb?.state}{" "}
                            {Userdata?.user_details?.suburb?.postcode})
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Service Radius </Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.serviceRadius} KM
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <h6 className="heading-lg d-float-center justify-content-between mb-3">
                        Payment Details{" "}
                        <a
                          href="#"
                          onClick={() => {
                            setStep(5);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <i className="ri-edit-box-line"></i> Edit
                        </a>
                      </h6>
                      <div className="col-md-12 profile-info-sub">
                        <p className="d-float-center justify-content-between mt-2 mb-2">
                          {/* <span className="main-title">Rate/Hrs</span> */}
                          {/* <i className="fa fa-pencil-square-o" aria-hidden="true"></i> */}
                        </p>
                        <hr className="mt-1 mb-3"></hr>
                        {Userdata?.user_details?.caregiver_charges?.map(
                          (item: any) => {
                            return (
                              <>
                                <div className="row email-wrap other-details mt-2 mb-0">
                                  <p className="col-md-5 project_name_0 pe-0">
                                    <i className="ri-vip-diamond-fill text-warning"></i>
                                    &nbsp; {item?.dayType?.name}
                                    <span className="float-end">:</span>
                                  </p>

                                  <h6 className="col-md-7 task_title_0 f-w-600 ps-3">
                                    $ {item?.rate}
                                    <span style={{ fontSize: "13px" }}>
                                      &nbsp;(Rate/Hrs)
                                    </span>
                                  </h6>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                      <Row className="mt-4">
                        <h6 className="heading-lg d-float-center justify-content-between mb-3">
                          Ability to Drive
                        </h6>
                        <div>
                          <hr className="mt-1 mb-3"></hr>
                        </div>

                        <Col lg={6}>
                          <Label>Do you have a valid license?</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.validLicense === 1
                              ? "Yes"
                              : Userdata?.user_details?.validLicense === 0
                              ? "No"
                              : "-"}
                          </span>
                        </Col>
                        <Col lg={6}>
                          <Label>I can drive overnight?</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.driveOvernight === 1
                              ? "Yes"
                              : Userdata?.user_details?.driveOvernight === 0
                              ? "No"
                              : "-"}
                          </span>
                        </Col>
                        <Col lg={6}>
                          <Label>
                            Do you have a comprehensive car insurance?
                          </Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <span className="out-put">
                            {" "}
                            {Userdata?.user_details
                              ?.comprehensiveCarInsurance === 1
                              ? "Yes"
                              : Userdata?.user_details
                                  ?.comprehensiveCarInsurance === 0
                              ? "No"
                              : "-"}
                          </span>
                        </Col>
                        <Col lg={6}>
                          <Label>Do you have a third party insurance?</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.thirdPartyInsurance === 1
                              ? "Yes"
                              : Userdata?.user_details?.thirdPartyInsurance ===
                                0
                              ? "No"
                              : "-"}
                          </span>
                        </Col>
                        <Col lg={6}>
                          <Label>I can cover</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.coverKmsPerDay} Kms per day
                          </span>
                        </Col>
                        <Col lg={6}>
                          <Label>Each KM will be charged at</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.chargedAtAud} AUD in
                            addition to the hourly charges
                          </span>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        {/* <Col lg={3}>
                          <Label> Extra 20% after hrs (6 PM to 8 PM)</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={3}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.extraChargeAfterHours === 1
                              ? "Yes"
                              : "No"}
                          </span>
                        </Col> */}

                        <Col lg={3}>
                          <Label>ABN</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={3}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.abn}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <Label>Company name</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={3}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.companyName}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <Label>Bank name</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={3}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.bankName}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <Label>Branch name</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={3}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.bankBranchName}
                          </span>
                        </Col>
                        <Col lg={3}>
                          <Label>Bank account number</Label>
                          <span className="float-end">:</span>
                        </Col>
                        <Col lg={3}>
                          {" "}
                          <span className="out-put">
                            {Userdata?.user_details?.bankAccountNumber}
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <FormGroup>
                        <Field
                          type="checkbox"
                          name="declare"
                          className="custom-checkbox"
                        />
                        &nbsp;
                        <Label className="px-1 text-math-auto">
                          I hereby declare that the information provided is true
                          and accurate to the best of my knowledge.
                        </Label>
                        <ErrorMessage
                          name="declare"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                      <div className="text-center d-float-center justify-content-center gap-15">
                        <Button
                          className="btn btn-brand-2"
                          type="submit"
                          onClick={() => setStep(5)}
                        >
                          <i className="ri-arrow-left-line"></i>&nbsp;BACK
                        </Button>
                        <Button
                          className="btn btn-success text-uppercase"
                          type="submit"
                          disabled={isSubmitting}
                          onClick={() => handleButtonClick("save")}
                        >
                          {isSubmitting && <Spinner size="sm"></Spinner>}
                          Save
                        </Button>
                        <Button
                          className="btn btn-brand-1 text-uppercase"
                          type="submit"
                          disabled={isSubmitting}
                          onClick={() => handleButtonClick("complete")}
                        >
                          {isSubmitting && <Spinner size="sm"></Spinner>}
                          Complete
                        </Button>
                      </div>
                    </CardBody>
                  </Card>{" "}
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default Step6;
