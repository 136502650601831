import React, { Children, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  Alert,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import {
  initializeRegisterInitialValues,
  OtpValidationSchema,
  validationSchema,
} from "../RegisterMethods/Registervalidation";
import OTPForm from "./OTP";
import {
  OTPSubmitHandlerCareSeeker,
  RegisterSubmitHandlerCareSeeker,
  ResendOTPSubmitHandlerCareSeeker,
} from "../RegisterMethods/CareSeekerMethods";
import { useQuery } from "react-query";
import { getUserData } from "../../../../../Api/WebsiteApi";
import SuccessCareSeekerModal from "../../../../../components/Modal/SucessCareSeekerModal";
import SucccessContent from "./SuccessContent";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CareSeekerRegister: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const [phone_number, setPhone] = useState<any>();
  const [otpDisply, setOtpDisplay] = useState(1);
  const [resendCount, setResendCount] = useState(0);
  const [timer, setTimer] = useState(30); // 30 seconds countdown
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [register_code, setRegisterCode] = useState();
  const [errors_backend, setErrorBackend] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (isTimerActive && timer > 0) {
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }
    return () => clearTimeout(countdown);
  }, [isTimerActive, timer]);

  const handleResendCode = () => {
    setResendCount(resendCount + 1);
    setTimer(30);
    setIsTimerActive(true);
    ResendOTPSubmitHandlerCareSeeker(register_code, setRegisterCode);
  };

  const {
    data: Userdata,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery(
    [`user`],
    () => (localStorage.getItem("AUTH_TOKEN") ? getUserData() : ""),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (
      localStorage.getItem("AUTH_TOKEN") &&
      Userdata?.userType?.name === "CareSeeker"
    ) {
      navigate("/create-profile-careseeker");
    }
    // if (localStorage.getItem("REGISTER_TOKEN")) {
    //   setOtpDisplay(2);
    // }
  }, [Userdata?.role, Userdata?.userType?.name, navigate]);
  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
    navigate("/create-profile-careseeker");
  };
  return (
    <>
      <style>
        {`
                   
        `}
      </style>
      <div className="authincation h-100">
        {/* <Button onClick={centeredToggle}>CLick Modal</Button> */}
        <Container className="h-100">
          {otpDisply === 1 && (
            <Row className="justify-content-center h-100 align-items-center">
              <Col md={6} className="login-page01">
                <Card className="authincation-content card-body py-4">
                  <Formik
                    initialValues={initializeRegisterInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                      RegisterSubmitHandlerCareSeeker(
                        values,
                        actions,
                        navigate,
                        setRegisterCode,
                        setOtpDisplay,
                        setErrorBackend
                      );

                      setPhone(values?.email);
                      // Scroll to top after form submission
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      values,
                      setFieldError,
                    }) => (
                      <>
                       
                        <Form onSubmit={handleSubmit}>
                          <div className="text-center mb-3 logo-div01">
                            <h3 className="block-subTitle text-center uss-text-info">
                              <span className="uss-text-primary">Create</span>{" "}
                              Account Care Seeker
                            </h3>
                          </div>

                          <FormGroup>
                            <Label>First Name</Label>
                            <Field
                              type="text"
                              name="first_name"
                              className={`form-control ${
                                errors.first_name ? "is-invalid" : "is-valid"
                              }`}
                            />
                            <ErrorMessage
                              name="first_name"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Last Name</Label>
                            <Field
                              type="text"
                              name="last_name"
                              className={`form-control ${
                                errors.last_name ? "is-invalid" : "is-valid"
                              }`}
                            />
                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label>Phone Number</Label>
                            {/* <Field
                  type="text"
                  name="mobile"
                  className={`form-control ${
                    errors.mobile ? "is-invalid" : "is-valid"
                  }`}
                /> */}
                            <PhoneInput
                              country={"au"}
                              value={values?.mobile}
                              onChange={(phone) => {
                                setFieldValue("mobile", "+" + phone);
                              }}
                              onlyCountries={["au"]}
                              inputClass={` ${
                                errors.mobile ? "is-invalid" : "is-valid"
                              }`}
                              disableDropdown
                              countryCodeEditable={false}
                            />
                            <ErrorMessage
                              name="mobile"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label>Email Address</Label>
                            <Field
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : "is-valid"
                              }`}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label>Password</Label>
                            <InputGroup>
                              <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                className={`form-control ${
                                  errors.password ? "is-invalid" : ""
                                }`}
                              />
                              <InputGroupText
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <i className="ri-eye-line"></i>
                                ) : (
                                  <i className="ri-eye-off-line"></i>
                                )}
                              </InputGroupText>
                            </InputGroup>
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label>Confirm Password</Label>
                            <InputGroup>
                              <Field
                                type={showPasswordC ? "text" : "password"}
                                name="confirm_password"
                                className={`form-control ${
                                  errors.confirm_password ? "is-invalid" : ""
                                }`}
                              />
                              <InputGroupText
                                onClick={() => setShowPasswordC(!showPasswordC)}
                              >
                                {showPasswordC ? (
                                  <i className="ri-eye-line"></i>
                                ) : (
                                  <i className="ri-eye-off-line"></i>
                                )}
                              </InputGroupText>
                            </InputGroup>
                            <ErrorMessage
                              name="confirm_password"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Field
                              type="checkbox"
                              name="agree_to_term_and_privacy"
                            />{" "}
                            &nbsp;
                            <Label>
                              I agree to the&nbsp;
                              <a href="/terms-conditions" target="-blank">
                                <u>Terms & Conditions</u>
                              </a>{" "}
                              and&nbsp;
                              <a href="/privacy-policy" target="-blank">
                                <u>Privacy Policy</u>
                              </a>
                            </Label>
                            <ErrorMessage
                              name="agree_to_term_and_privacy"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                          {errors_backend && (
                            <Alert color="info" className="mt-30">
                              {errors_backend}
                            </Alert>
                          )}

                          <div className="text-center">
                            <Button
                              className="btn btn-brand-1"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Register..." : "Register"}
                            </Button>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Card>
              </Col>
            </Row>
          )}
          {otpDisply === 2 && (
            <OTPForm
              phone_number={phone_number}
              OtpValidationSchema={OtpValidationSchema}
              OTPSubmitHandler={OTPSubmitHandlerCareSeeker}
              navigate={navigate}
              register_code={register_code}
              setOtpDisplay={setOtpDisplay}
              handleResendCode={handleResendCode}
              isTimerActive={isTimerActive}
              timer={timer}
              resendCount={resendCount}
              setCentered={setCentered}
            />
          )}
        </Container>
        {centred && (
          <>
            <SuccessCareSeekerModal
              toggle={centeredToggle}
              isOpen={centred}
              onClosed={onCloseModal}
              size={"xl"}
              titl="Sucess"
              modalBodyClassName={""}
              children={<SucccessContent />}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CareSeekerRegister;
