import { ErrorMessage, Field, Formik } from "formik";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { ContactSubmitHandler } from "./ContactMethods/ContactHandlers";
import { useNavigate } from "react-router-dom";
import {
  ContactValidation,
  initializeContactInitialValues,
} from "./ContactMethods/ContactValidation";
import { useState } from "react";
import TextError from "../../../../components/Errors/TextError";
import constants from "../../../../Utils/Constants/Constants";

export default function ContactForm() {
  const navigate = useNavigate();

  return (
    <>
      <style>
        {`
              .box-info-contact {
                  padding: 20px 20px 20px 20px;
              }
              .box-map {
                  margin-bottom: 0px;
              }      
                  .breacrumb-cover {
                  padding: 50px 0;
                  }            
        `}
      </style>
      <section className="section-box mt-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-0">
              <span className="font-md color-brand-2 mt-20 d-inline-block">
                Contact us
              </span>
              <h2 className="mt-5 mb-10">Get in touch</h2>
              <p className="font-md color-text-paragraph-2">
                The right move at the right time saves your investment. Live
                <br className="d-none d-lg-block" /> the dream of expanding your
                business.
              </p>
              <Formik
                validationSchema={ContactValidation}
                initialValues={initializeContactInitialValues}
                enableReinitialize
                onSubmit={(values: any, actions: any) =>
                  ContactSubmitHandler(values, actions, navigate)
                }
              >
                {({
                  values,
                  isSubmitting,
                  handleChange,
                  touched,
                  handleSubmit,
                  errors,
                }: any) => {
                  return (
                    <Form
                      onSubmit={handleSubmit}
                      className="contact-form-style mt-30"
                      //   id="contact-form"
                    >
                      <Row
                        className="row wow animate__animated animate__fadeInUp"
                        data-wow-delay=".1s"
                      >
                        <div className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <Field
                              type="text"
                              name="name"
                              className={`form-control font-sm color-text-paragraph-2 ${
                                errors.name && touched.name ? "is-invalid" : ""
                              }`}
                              placeholder="Name"
                              disabled
                            />
                            <ErrorMessage name="name" component={TextError} />
                          </div>
                        </div>
                        <Col className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <Field
                              type="text"
                              name="company"
                              className={`form-control font-sm color-text-paragraph-2 ${
                                errors.company && touched.company
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Organization (Optional)"
                              disabled
                            />
                          </div>
                        </Col>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <Field
                              type="text"
                              name="email"
                              className={`form-control font-sm color-text-paragraph-2 ${
                                errors.email && touched.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Email"
                              disabled
                            />
                            <ErrorMessage name="email" component={TextError} />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <Field
                              type="text"
                              name="mobile"
                              className={`form-control font-sm color-text-paragraph-2 ${
                                errors.mobile && touched.mobile
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Mobile"
                              disabled
                            />
                            <ErrorMessage name="mobile" component={TextError} />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="textarea-style mb-30">
                            <Field
                              as="textarea"
                              name="message"
                              className={`form-control font-sm color-text-paragraph-2 ${
                                errors.message && touched.message
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Message"
                              style={{ height: "150px" }}
                              disabled
                            />
                            <ErrorMessage
                              name="message"
                              component={TextError}
                            />
                          </div>
                          <label className="ml-20">
                            <Input
                              className="float-start mr-5 mt-6"
                              type="checkbox"
                              name="subscribe"
                              onChange={handleChange}
                              value={values?.subscribe}
                            />{" "}
                            Allow us to connect with you.
                          </label>
                          <Button
                            className="submit btn btn-send-message float-right"
                            type="submit"
                            disabled
                          >
                            Send message
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
              <p className="form-messege"></p>
            </div>
            <div className="col-lg-4 text-center d-none d-lg-block">
              <img
                src={`${constants.IMAGE_PATH.IMAG_PATH}page/contact/img.png`}
                alt="joxBox"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
