import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { BaseUrl } from "../../../../Api/BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getServiceProvides,
  getServiceProvides_,
} from "../../../../Api/WebsiteApi";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Alert, Form, Input } from "reactstrap";
import DatePickerReact from "../../../../components/DatePicker/DatePickerReact";

export default function ServicesBanner({ dataListcount }: any) {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [loaction, setLocation] = useState([]);
  const [erro, setErr] = useState<any>({
    loaction: "",
    // range: 5 || "",
    service_type: "",
    avlibility_date: "",
    suburb_id: "",
  });
  const [value, setVlues] = useState<any>();

  useEffect(() => {
    axios
      .get(`${BaseUrl}/user/master/locations?search=${value}`)
      .then((response) => {
        let new_array = response?.data?.data?.map((item: any) => ({
          value: item?.id,
          label: `${item?.locality} - ${item?.stateName} ${item?.postcode}`,
          label_only: item?.locality,
          state: item?.state,
        }));
        setLocation(new_array || []);
        // console.log(response.data?.data); // Handle the response data here
      })
      .catch((error) => {
        console.error("There was an error!", error); // Handle any errors here
      });
  }, [value]);
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none", // Remove border
      boxShadow: "none", // Remove shadow
      "&:hover": {
        border: "none", // Remove border on hover
      },
      opacity: 2.8,
      backgroundColor: "white",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999,
      opacity: 2.8, /// Ensure the dropdown is on top
      backgroundColor: state.isFocused ? "white" : "white",
    }),
  };
  const { data: dataList }: any = useQuery(
    [`services_providers_`],
    () => getServiceProvides_(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );

  return (
    <>
      <style>
        {`
          .header.sticky-bar {
          box-shadow: none;
          }
          .range-km01 {
          width: 100%;
          }
        `}
      </style>
      <section className="section-box-2 services-list-banner mt-2">
        <div className="container">
          <div className="banner-hero banner-single banner-single-bg">
            <div className="block-banner text-center">
              <h3 className="wow animate__animated animate__fadeInUp">
                <span className="color-brand-2">{dataListcount} Services</span>{" "}
                Available Now
              </h3>
              <div
                className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                With USS, you are able to choose your services and also choose
                who provides your services. <br className="d-none d-xl-block" />
                Choose a service to get started.
              </div>

              <div
                className="form-find text-start mt-40 wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
              >
                <Formik
                  initialValues={{
                    service_type: "",
                    loaction: "",
                    avlibility_date: "",
                    suburb_id: "",
                  }}
                  // validationSchema={validationSchema}
                  onSubmit={(values) => {
                    navigate(
                      `/find/caregivers?service_type_id=${
                        values.service_type ? values.service_type : ""
                      }&suburb_id=${
                        selectedLocation?.value ? selectedLocation?.value : ""
                      }&radius=${
                        values.avlibility_date ? values.avlibility_date : ""
                      }&subrub=${
                        selectedLocation?.label_only
                          ? selectedLocation?.label_only
                          : ""
                      }&state=${
                        selectedLocation?.state ? selectedLocation?.state : ""
                      }`
                    );
                  }}
                >
                  {({
                    setFieldValue,
                    handleSubmit,
                    errors,
                    handleChange,
                    values,
                  }) => {
                    return (
                      <>
                        <Form
                          className="service-list-form"
                          onSubmit={handleSubmit}
                        >
                          <div className="box-location">
                            <ReactSelect
                              name="service_type"
                              options={dataList}
                              onChange={(selectedOption: any) => {
                                setFieldValue(
                                  "service_type",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              placeholder="Service Type"
                              isClearable
                              styles={customStyles}
                            />
                          </div>
                          <div className="box-location">
                            <ReactSelect
                              name="loaction"
                              options={loaction}
                              onChange={(option: any) => {
                                setFieldValue(
                                  "loaction",
                                  option ? option.value : ""
                                );
                                setSelectedLocation(option);
                              }}
                              placeholder="Enter Location"
                              isClearable
                              styles={customStyles}
                              onInputChange={(inputValue: any) => {
                                setVlues(inputValue);
                              }}
                            />
                            {/* <ErrorMessage name="loaction" component="div" className="error-message" /> */}
                          </div>

                          {/* Range Slider */}
                          <div className="range-km01 mt-0">
                            <DatePickerReact
                              selected={
                                values?.avlibility_date
                                  ? new Date(values?.avlibility_date)
                                  : null
                              }
                              onChange={(date: any) =>
                                setFieldValue("avlibility_date", date)
                              }
                              name="avlibility_date"
                              dateFormat="dd/MM/yyyy"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              placeholderText="Choose date"
                              className={`form-control `}
                              showIcon
                              minDate={new Date()}
                            />
                          </div>
                          <button
                            className="btn btn-default btn-find font-sm mt-0"
                            type="submit"
                            onClick={() => {
                              setErr({
                                ...erro,
                                service_type: errors?.service_type,
                                loaction: errors?.loaction,
                              });
                            }}
                          >
                            Search
                          </button>
                        </Form>
                      </>
                    );
                  }}
                </Formik>

                {(erro?.service_type || erro?.loaction) && (
                  <>
                    {" "}
                    <Alert color="danger" className="mt-30">
                      <ol>
                        <li>. {erro?.service_type}</li>
                        <li>. {erro?.loaction}</li>
                      </ol>
                    </Alert>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
