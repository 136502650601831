const SUPPORT_FOR_YOU = [
  {
    id: "",
    name: "Select",
  },
  {
    id: 1,
    name: "Myself",
  },
  // {
  //   id: 2,
  //   name: "Family Member",
  // },
  // {
  //   id: 3,
  //   name: "Client",
  // },
  // {
  //   id: 4,
  //   name: "Others",
  // },
];
const PROFILE_TYPE = [
  {
    id: "",
    name: "Select",
  },
  {
    id: 1,
    name: "Care Worker",
  },
  {
    id: 2,
    name: "Allied health professional",
  },
  {
    id: 3,
    name: "Support Cordinator",
  },
  {
    id: 4,
    name: "Registerd Nurse",
  },
];
const PAYMENT_MODE = [
  {
    id: "",
    name: "Select",
  },
  {
    id: 1,
    name: "Online",
  },
  {
    id: 2,
    name: "Check",
  },
  {
    id: 3,
    name: "In hand",
  },
  {
    id: 4,
    name: "Others",
  },
];
const GENDER = [
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
  {
    id: 3,
    name: "Others",
  },
];
const YES_NO = [
  {
    id: true,
    name: "Yes",
  },
  {
    id: false,
    name: "No",
  },
];
const WWCC_TYPe = [
  {
    id:"paid",
    name: "Paid",
  },
  {
    id: "volunteer",
    name: "Voluntary",
  },
];
const RESIDANCEY_TYPE = [
  {
    id: "",
    name: "What is your ",
  },
  {
    id: 1,
    name: "Permanent Residency",
  },
  {
    id: 2,
    name: "Citizen",
  },
  {
    id: 3,
    name: "Temporary visa",
  },
  {
    id: 2,
    name: "Student visa",
  },
];
const EXPERIECES_WITH_CHILDREN = [
  {
    value: 1,
    label: "0-6 years",
  },
  {
    value: 2,
    label: "7-13 years",
  },
  {
    value: 3,
    label: "14-18 years",
  },
];
const EXPERIECES_WITH_AGED = [
  {
    value: 1,
    label: "Dementia",
  },
  {
    value: 2,
    label: "Limited mobility",
  },
];
const WILLING_WORK_WITH = [
  {
    value: 1,
    label: "Smokers",
  },
  {
    value: 2,
    label: "Drug and alcohol users",
  },
  {
    value: 3,
    label: "Complec bowel care",
  },
];
const CARE_TASKS = [
  {
    value: 1,
    label: "Personal Care",
  },
  {
    value: 2,
    label: "Cleaning",
  },
  {
    value: 3,
    label: "Cooking",
  },
  {
    value: 4,
    label: "Pet Feeding",
  },
];
const LANGAUGE = [
  {
    value: 1,
    label: "Spanish",
  },
  {
    value: 2,
    label: "Hindi",
  },
  {
    value: 3,
    label: "English",
  },
];
const INTERSTET = [
  {
    value: 1,
    label: "Driving",
  },
  {
    value: 2,
    label: "Reading",
  },
  {
    value: 3,
    label: "Driving",
  },
];
const RATES = [
  {
    value: 1,
    label: "Weekly Charges",
    field: "weekday_rate",
    max: "",
    min: "",
  },
  {
    value: 2,
    label: "Saturday Charges",
    field: "saturday_rate",
    max: "",
    min: "",
  },
  {
    value: 3,
    label: "Public Holiday Charges",
    field: "public_holiday_rate",
    max: "",
    min: "",
  },
  {
    value: 4,
    label: "Sunday Charges",
    field: "sunday_rate",
    max: "",
    min: "",
  },
];
const SERVICES_TYPES = [
  "Art Therapy",
  "Nuclear Medicine Technology",
  "Podiatry",
  "Audiology",
  "Nutrition and dietetics",
  "Psychology",
  "Child Life Therapy",
  "Occupational Therapy",
  "Radiography",
  "Counselling",
  "Medical Radiation Therapy",
  "Sexual Assault Worker",
  "Diversional Therapy",
  "Orthoptics",
  "Social Work",
  "Exercise Physiology",
  "Orthotics & Prosthetics",
  "Speech Pathology",
  "Genetic Counselling",
  "Pharmacy",
  "Welfare Work",
  "Music Therapy",
  "Physiotherapy",
  "Registered nurse",
  "Behaviour Practitioner",
  "Support Coordinator",
  "Child Care/Baby Sitter",
  "Support Worker",
  "Domestic Worker",
];
const SERVICES_TYPES_: any = [
  // { value: "", label: "Service Type" },
  { value: 1, label: "Art Therapy" },
  { value: 2, label: "Nuclear Medicine Technology" },
  { value: 3, label: "Podiatry" },
  { value: 4, label: "Audiology" },
  { value: 5, label: "Nutrition and Dietetics" },
  { value: 6, label: "Psychology" },
  { value: 7, label: "Child Life Therapy" },
  { value: 8, label: "Occupational Therapy" },
  { value: 9, label: "Radiography" },
  { value: 10, label: "Counselling" },
  { value: 11, label: "Medical Radiation Therapy" },
  { value: 12, label: "Sexual Assault Worker" },
  { value: 13, label: "Diversional Therapy" },
  { value: 14, label: "Orthoptics" },
  { value: 15, label: "Social Work" },
  { value: 16, label: "Exercise Physiology" },
  { value: 17, label: "Orthotics & Prosthetics" },
  { value: 18, label: "Speech Pathology" },
  { value: 19, label: "Genetic Counselling" },
  { value: 20, label: "Pharmacy" },
  { value: 21, label: "Welfare Work" },
  { value: 22, label: "Music Therapy" },
  { value: 23, label: "Physiotherapy" },
  { value: 24, label: "Registered Nurse" },
  { value: 25, label: "Behaviour Practitioner" },
  { value: 26, label: "Support Coordinator" },
  { value: 27, label: "Child Care/Baby Sitter" },
  { value: 28, label: "Support Worker" },
  { value: 29, label: "Domestic Worker" },
];

const daysOfWeek = [
  { value: "0", label: "Select" },
  { value: "1", label: "Sunday" },
  { value: "2", label: "Monday" },
  { value: "3", label: "Tuesday" },
  { value: "4", label: "Wednesday" },
  { value: "5", label: "Thursday" },
  { value: "6", label: "Friday" },
  { value: "7", label: "Saturday" },
];

export {
  SUPPORT_FOR_YOU,
  PAYMENT_MODE,
  SERVICES_TYPES,
  PROFILE_TYPE,
  GENDER,
  RESIDANCEY_TYPE,
  EXPERIECES_WITH_CHILDREN,
  EXPERIECES_WITH_AGED,
  WILLING_WORK_WITH,
  CARE_TASKS,
  LANGAUGE,
  INTERSTET,
  RATES,
  SERVICES_TYPES_,
  YES_NO,
  daysOfWeek,
  WWCC_TYPe
};
